import _ from 'lodash';
import {
  LOAD_SHIFT_REQUEST,
  LOAD_SHIFT_SUCCESS,
  LOAD_SHIFT_FAILED,

  DELETE_SHIFT_REQUEST,
  DELETE_SHIFT_SUCCESS,
  DELETE_SHIFT_FAILED,

  CREATE_SHIFT_REQUEST,
  CREATE_SHIFT_SUCCESS,
  CREATE_SHIFT_FAILED,

  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_FAILED,

  UPDATE_SELECTED_ASSIGNMENT_GROUP,
  UPDATE_SELECTED_PLATFORM,
  UPDATE_SHIFT_ENDTIME,
  UPDATE_SHIFT_STARTTIME,
  UPDATE_CONTACT,
  UPDATE_CHECK_FIELD_VALUE,
  UPDATE_INTERVAL,
  OPEN_CURRENT_CONFIG_WINDOW,
  CLOSE_CURRENT_CONFIG_WINDOW,
  UPDATE_OPERATION_INITIATED,
  UPDATE_OPERATION_COMPLETED,
  UPDATE_OPERATION_TERMINATED,

  UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA,

  UPDATE_WEBHOOK_URL,
  UPDATE_CONTACT_NUMBERS,
  UPDATE_PRIORITY,
  UPDATE_FORM_VALIDATION,
  UPDATE_FORM_SUBMIT_STATUS,

  SET_JIRA_PROJECTS,

  SET_SMS_FOR,
  SET_WATSAPP_FOR,
  SET_TEAMS_FOR,
  SET_SLACK_FOR,
  SET_IDESK_ASSIGNMENTGROUPS,

  OPEN_REPORT_WINDOW,
  CLOSE_REPORT_WINDOW
} from "./types";

import moment from 'moment';


const setSMSFor = (payload) => {
  return {
    type: SET_SMS_FOR,
    payload: _.uniq(payload)
  }
}

const setWhatsppFor = (payload) => {
  return {
    type: SET_WATSAPP_FOR,
    payload: payload
  }
}

const setTeamsFor = (payload) => {
  return {
    type: SET_TEAMS_FOR,
    payload: payload
  }
}

const setSlackFor = (payload) => {
  return {
    type: SET_SLACK_FOR,
    payload: payload
  }
}

const setJiraProjects = (payload) => {
  return {
    type: SET_JIRA_PROJECTS,
    payload: payload
  }
}

const setIdeskAssignmentGroups = (payload) => {
  return {
    type: SET_IDESK_ASSIGNMENTGROUPS,
    payload: payload
  }
}

const updateFormValidation = (payload) => {
  return {
    type: UPDATE_FORM_VALIDATION,
    payload: payload
  }
}

const updateFormSubmitStatus = (payload) => {
  return {
    type: UPDATE_FORM_SUBMIT_STATUS,
    payload: payload
  }
}

const updatePriority = (payload) => {
  return {
    type: UPDATE_PRIORITY,
    payload: payload
  }
}

const updateShiftStateWithDefaultShiftSchema = (payload) => {
  return {
    type: UPDATE_SHIFT_OBJECT_WITH_DEFAULT_SCHEMA,
    payload: payload
  }
}

const loadShiftRequest = () => {
  return {
    type: LOAD_SHIFT_REQUEST,
  };
};

const loadShiftSuccess = (payload) => {
  return {
    type: LOAD_SHIFT_SUCCESS,
    payload: payload,
  };
};

const loadShiftFailed = (error) => {
  return {
    type: LOAD_SHIFT_FAILED,
    payload: error,
  };
};

const deleteShiftRequest = () =>{
  return {
    type: DELETE_SHIFT_REQUEST
  }
}

const deleteShiftSuccess = () => {
  return {
    type: DELETE_SHIFT_SUCCESS
  }
}

const deleteShiftFailed = () => {
  return {
    type: DELETE_SHIFT_FAILED
  }
}


/** create document action - start */
const createShiftRequest = () =>{
  return {
    type: CREATE_SHIFT_REQUEST
  }
}

const createShiftSuccess = () => {
  return {
    type: CREATE_SHIFT_SUCCESS
  }
}

const createShiftFailed = () => {
  return {
    type: CREATE_SHIFT_FAILED
  }
}
/** create document action - end */



/** update document action - start */
const updateShiftRequest = () =>{
  return {
    type: UPDATE_SHIFT_REQUEST
  }
}

const updateShiftSuccess = () => {
  return {
    type: UPDATE_SHIFT_SUCCESS
  }
}

const updateShiftFailed = () => {
  return {
    type: UPDATE_SHIFT_FAILED
  }
}
/** update document action - end */

const updateSelectedAssignmentGroup = (payload) => {
  return {
    type: UPDATE_SELECTED_ASSIGNMENT_GROUP,
    payload: payload,
  };
};

const updateSelectedPlatform = (payload) => {
  return {
    type: UPDATE_SELECTED_PLATFORM,
    payload: payload,
  };
};

const updateShiftStartTime = (startTime) => {
  return {
    type: UPDATE_SHIFT_STARTTIME,
    payload: startTime,
  };
};

const updateShiftEndTime = (endTime) => {
  return {
    type: UPDATE_SHIFT_ENDTIME,
    payload: endTime,
  };
};

const updateContact = (name, value) => {
  return {
    type: UPDATE_CONTACT,
    payload: {
      name: name,
      value: value,
    },
  };
};

const updateFeaturesEnabled = (name, value) => {
  return {
    type: UPDATE_CHECK_FIELD_VALUE,
    payload: {
      name: name,
      value: value,
    },
  };
};

const updateInterval = (name, value) => {
  return {
    type: UPDATE_INTERVAL,
    payload: {
      name: name,
      value: value,
    },
  };
};

const openCurrentConfigWindow = () => {
  return {
    type: OPEN_CURRENT_CONFIG_WINDOW,
  };
};

const openReportWindow = () => {
  return {
    type: OPEN_REPORT_WINDOW,
  };
};

const closeReportWindow = () => {
  return {
    type: CLOSE_REPORT_WINDOW
  }
}

const closeCurrentConfigWindow = () => {
  return {
    type: CLOSE_CURRENT_CONFIG_WINDOW,
  };
};

const updateNotificationContactNumber = (name, numbers) => {
  // console.log(name, numbers)
  return {
    type: UPDATE_CONTACT_NUMBERS,
    payload: {
      name: name,
      numbers: numbers
    }
  }
}

const updateOperationInitiate = (payload) => {
  const { startTime, endTime } = payload;
  let startHours = startTime.split(":")[0];
  let startMinutes = startTime.split(":")[1];
  let endHours = endTime.split(":")[0];
  let endMinutes = endTime.split(":")[1];
  let start = moment().set({hour: startHours, minute: startMinutes, second: 0})
  let end = moment().set({hour: endHours, minute: endMinutes, second: 0})
  payload ={
    ...payload,
    startTime: start,
    endTime: end
  }
  // console.log(payload)
  return {
    type: UPDATE_OPERATION_INITIATED,
    payload: payload
  }
}

const updateWebhookUrl = (name, value) => {
  return {
    type: UPDATE_WEBHOOK_URL,
    payload : {
      name: name,
      url: value
    }
  }
}

export {
  loadShiftRequest,
  loadShiftSuccess,
  loadShiftFailed,
  deleteShiftSuccess,
  deleteShiftRequest,
  deleteShiftFailed,
  createShiftRequest,
  createShiftSuccess,
  createShiftFailed,
  updateShiftRequest,
  updateShiftSuccess,
  updateShiftFailed,

  updateSelectedAssignmentGroup,
  updateSelectedPlatform,
  updateShiftStartTime,
  updateShiftEndTime,
  updateContact,
  updateFeaturesEnabled,
  updateInterval,
  openCurrentConfigWindow,
  closeCurrentConfigWindow,
  updateOperationInitiate,
  updateWebhookUrl,
  updateNotificationContactNumber,

  updateShiftStateWithDefaultShiftSchema,
  updatePriority,
  updateFormValidation,
  updateFormSubmitStatus,
  setJiraProjects,
  setIdeskAssignmentGroups,

  setSMSFor,
  setSlackFor,
  setWhatsppFor,
  setTeamsFor,


  openReportWindow,
  closeReportWindow
};
