import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import _, { fromPairs } from 'lodash';

/** material ui components */
import Container from '@material-ui/core/Container';
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { NavigateBeforeOutlined, NavigateNextOutlined } from '@material-ui/icons';

/** import actions */
import {
  updateContact,
  updateFormValidation
} from "../../../../../../redux/shiftmanagement/actions";

/** import css */
import "./Settings.css";
import { Button, Grid } from "@material-ui/core";

export class Settings extends Component {

  onUpdateContact = (name, value) => {
    // value = value.match(/[0-9]/g);
    this.props.updateContact(name, value);
  };

  validate = () => {
    const {escalationcontactnumber} = this.props;
    const formproperty = 'isform5valid'
    const valid = (_.size(_.trim(escalationcontactnumber)) > 8)
    this.props.updateFormValidation({name: formproperty, value: valid})
  }

  componentDidMount(){
    this.validate()
  }

  render() {
    this.validate()
    return (
      <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        exit={{ opacity: 0 }}
        className="container"
      >
        <Container>
        <div className="flex-container">
          <div className="item">
            <span style={{ color: "gray" }}>
              Choose your escalation contact number <sup>*</sup>
            </span>
            <MuiPhoneNumber
              style={{ width: '100%'}}
              name="escalationcontactnumber"
              value={this.props.escalationcontactnumber}
              onChange={(value) => {
                this.onUpdateContact("escalationcontactnumber", value);
              }}
              defaultCountry="se"
              countryCodeEditable={true}
            />
          </div>
        </div>
        </Container>
      </motion.div>
      <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ padding: "0px 10px" }}
            spacing={2}
          >
            <Button
              startIcon={<NavigateBeforeOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.back();
              }}
            >
              Previous
            </Button>
            <Button
              disabled={!this.props.isform5valid}
              endIcon={<NavigateNextOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.next();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    escalationcontactnumber: state.shmgmt.escalationcontactnumber,
    isescalationenabled: state.shmgmt.isescalationenabled,
    isform5valid: state.shmgmt.isform5valid
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateContact: (name, value) => {
      dispatch(updateContact(name, value));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
