import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function ProtectedRoute(props) {
        const Component = props.component;
        return props.isAuthenticated === true ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/' }} />
        );
}


const mapStatsToProps = state => {
    return {
        isAuthenticated: state.au.authenticated
    };
}

const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStatsToProps,mapDispatchToProps)(ProtectedRoute);