import React, { Component } from "react";
import { connect } from "react-redux";
import PlatformItem from "./PlatformItem";
import { motion } from "framer-motion";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

/** */
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {} from "@material-ui/core";

/** import redux actions */
import {
  updateSelectedAssignmentGroup,
  updatePriority,
  updateFormValidation,
} from "../../../redux/shiftmanagement/actions";

/** import services */
import { listJiraProject, listIdeskAssignmentGroup } from "../../../services/shiftmanagement";

import "./Platform.css";
import { Button, Container, Grid } from "@material-ui/core";
import { NavigateNextOutlined } from "@material-ui/icons";

const useStyles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const priorities = ["Blocker", "Highest", "High"];
const issueTypes = ["Incident", "Service Request", "Problem", "Change"];

export class Platform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jiraProjects: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  onAssignmentGroupChangeHandler = (event) => {
    this.props.updateAG(event.target.value);
  };

  onPriorityChange = ({ target }) => {
    this.props.updatePriority(target);
  };

  componentDidMount = () => {
    this.props.listJiraProject(this.props.token);
    this.props.listIdeskAssignmentGroup(this.props.token);
  };

  getSelection = () => {
    return this.props.jiraProjects.find((o) => o.name === this.props.agname);
  };

  handleChange=(e,v,r)=> {
    if(v===null){
      this.props.updateAG('')
    }else{
      this.props.updateAG(v.name)
    }
  }


  render() {
    const { classes } = this.props;
    const { itemname, agname, jppriority, jissuetype } = this.props;
    const formproperty = "isform1valid";
    const valid =
      (_.trim(itemname) === "jira" &&
        _.size(jppriority) > 0 &&
        _.includes(issueTypes, _.trim(jissuetype)) &&
        _.trim(agname) !== "" && _.size(_.filter(this.props.jiraProjects, o => o.name === this.props.agname)) > 0) ||
      (_.trim(agname) !== "" &&
        _.includes(["slack"], _.trim(itemname))) || (_.includes(["idesk"], _.trim(itemname)) && _.trim(agname) !== "" && _.size(_.filter(this.props.ideskAssignmentGroups, o => o.name === this.props.agname)) > 0);
    this.props.updateFormValidation({ name: formproperty, value: valid });
    return (
      <>
        <motion.div
          style={{ minHeight: "55vh" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="platform-container-text">
            <span>Choose Platform for Notification</span>
          </div>
          {/* <div className="platform-container"> */}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ maxWidth: "100vw" }}
            spacing={5}
          >
            {this.props.items.map((item) => (
              <Grid item>
                <PlatformItem object={item} key={item.name} />
              </Grid>
            ))}
          </Grid>
          {/* </div> */}
          {this.props.itemname && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.1 }}
              className="platform-container-assignment-group"
              style={{ paddingTop: "50px" }}
            >
              <FormControl>
                {/* {["idesk"].includes(this.props.itemname) ? (
                  <InputLabel required htmlFor="input-with-icon-adornment">
                    Your {this.props.itemname}`s assignment group
                  </InputLabel>
                ) :*/ ["slack"].includes(this.props.itemname) ? ( 
                  <InputLabel required htmlFor="input-with-icon-adornment">
                    Your {this.props.itemname}`s channel id
                  </InputLabel>
                ) : null}
                {["slack"].includes(this.props.itemname) ? (
                  <Input
                    id="input-with-icon-adornment"
                    style={{ minWidth: "300px" }}
                    onChange={this.onAssignmentGroupChangeHandler}
                    required
                    value={this.props.agname}
                    startAdornment={
                      <InputAdornment position="start">
                        <GroupRoundedIcon />
                      </InputAdornment>
                    }
                  />
                ) : ["idesk"].includes(this.props.itemname) ? (<Autocomplete
                  id="idesk-autocomplete"
                  
                  inputValue={this.props.agname}
                  options={_.filter(this.props.ideskAssignmentGroups, o => o.name !== null)}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={ (option) => option.name === this.props.agname}
                  style={{ width: 300 }}
                  onChange={this.handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={({target}) =>{this.props.updateAG(target.value)}}
                      label="Your idesk's assignment group"
                      error={_.trim(agname) !== "" && _.size(_.filter(this.props.ideskAssignmentGroups, o => o.name === this.props.agname)) === 0}
                      required
                      helperText={(_.trim(agname) !== "" && _.size(_.filter(this.props.ideskAssignmentGroups, o => o.name === this.props.agname)) === 0) ? "Please select valid assignment group from dropdown." : ""}
                      // variant="outlined"
                    />
                  )}
                />) : (
                  <Autocomplete
                    id="combo-box-demo"
                    
                    inputValue={this.props.agname}
                    options={this.props.jiraProjects}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={ (option) => option.name === this.props.agname}
                    style={{ width: 300 }}
                    onChange={this.handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={({target}) =>{this.props.updateAG(target.value)}}
                        label="Your jira's project name"
                        error={_.trim(agname) !== "" && _.size(_.filter(this.props.jiraProjects, o => o.name === this.props.agname)) === 0}
                        required
                        helperText={(_.trim(agname) !== "" && _.size(_.filter(this.props.jiraProjects, o => o.name === this.props.agname)) === 0) ? "Please select valid project from dropdown." : ""}
                        // variant="outlined"
                      />
                    )}
                  />
                )}
                {/* :  <Select
                labelId="input-with-icon-adornment"
                name="jiraProject"
                required={this.props.itemname === "jira"}
                value={this.props.agname}
                onChange={this.onAssignmentGroupChangeHandler}
                style={{ minWidth: "300px" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {this.props.jiraProjects.map((project) => (
                  <MenuItem
                    key={project.key}
                    value={project.name}
                  >
                    {project.name}
                  </MenuItem>
                ))}
              </Select>} */}
              </FormControl>
            </motion.div>
          )}
          {this.props.itemname === "jira" && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.1 }}
              className="platform-container-jira-priority"
            >
              {/* <FormControl className={classes.formControl}>
              <InputLabel required id="jira-issuetype-single-chip-label">
                Choose Issue Type
              </InputLabel>
              <Select
                labelId="jira-issuetype-signal-chip-label"
                id="demo-single-chip"
                name="jiraIssueType"
                required={this.props.itemname === "jira"}
                value={this.props.jissuetype}
                onChange={this.onPriorityChange}
                style={{ minWidth: "300px" }}
                input={<Input id="select-single-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {issueTypes.map((issueType) => (
                  <MenuItem disabled={issueType === 'Problem'} key={issueType} value={issueType}>
                    {issueType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

              <FormControl className={classes.formControl}>
                <InputLabel id="jira-issuetype-signal-chip-label">
                  Choose issue type <sup>*</sup>
                </InputLabel>
                <Select
                  labelId="jira-issuetype-signal-chip-label"
                  name="jiraIssueType"
                  required={this.props.itemname === "jira"}
                  value={this.props.jissuetype}
                  onChange={this.onPriorityChange}
                  style={{ minWidth: "300px" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {issueTypes.map((issueType) => (
                    <MenuItem
                      disabled={issueType === "Problem"}
                      key={issueType}
                      value={issueType}
                    >
                      {issueType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </motion.div>
          )}

          {this.props.itemname === "jira" && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.1 }}
              className="platform-container-jira-priority"
            >
              <FormControl className={classes.formControl}>
                <InputLabel required id="jira-prority-mutiple-chip-label">
                  Choose priority
                </InputLabel>
                <Select
                  labelId="jira-prority-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  name="jiraProjectPriority"
                  multiple
                  required={this.props.itemname === "jira"}
                  value={this.props.jppriority}
                  onChange={this.onPriorityChange}
                  style={{ minWidth: "300px" }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {priorities.map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </motion.div>
          )}
        </motion.div>
        <Container>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            style={{ padding: "0px 10px" }}
            spacing={2}
          >
            <Button
              disabled={!this.props.isform1valid}
              endIcon={<NavigateNextOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.next();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStatsToProps = (state) => {
  return {
    items: state.shmgmt.platFormItems,
    itemname: state.shmgmt.selectedPlatformItem,
    agname: state.shmgmt.assignmentGroup,
    jppriority: state.shmgmt.jiraProjectPriority,
    jissuetype: state.shmgmt.jiraIssueType,
    isform1valid: state.shmgmt.isform1valid,
    jiraProjects: state.shmgmt.jiraProjects,
    ideskAssignmentGroups: state.shmgmt.ideskAssignmentGroups,
    token: state.au.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAG: (gname) => {
      dispatch(updateSelectedAssignmentGroup(gname));
    },
    updatePriority: (payload) => {
      dispatch(updatePriority(payload));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload));
    },
    listJiraProject: (token) => {
      dispatch(listJiraProject(token));
    },
    listIdeskAssignmentGroup: (token) => {
      dispatch(listIdeskAssignmentGroup(token));
    },
  };
};

export default withStyles(useStyles)(
  connect(mapStatsToProps, mapDispatchToProps)(Platform)
);
