import React from "react";
import { useClearCache } from "react-clear-cache";

function WebTrafficBoard2 () {

    const { emptyCacheStorage } = useClearCache();
    
    //reload 30 seconds once
    setInterval(() => {
        emptyCacheStorage();
    }, 1800000);

    return (
        <>
            <iframe id="webtraffic2-datastudio-iframe" title="webtrafficreport2" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/b534b6c7-4f5b-4c95-8d21-b1017d854de2/page/azjBB" frameBorder={0} allowFullScreen></iframe>
        </>
    );
}

export default WebTrafficBoard2;