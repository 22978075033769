// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import actions from "../../../../redux/developerconnectportal/ios/actions";
// import {
//   faReplyAll,
//   faReply,
//   faUndo,
//   faTimesCircle,
//   faCheckCircle,
//   faEye,
// } from "@fortawesome/free-solid-svg-icons";
// import Modal from "react-modal";
// import "./Reply.css";
// import Loaders from "../../../custom/loaders/loaders";
// import axios from "axios";
// import _ from "lodash";
// // import { ACCESS_TOKEN, REPLIED_BY } from "../../../secrets";

// Modal.setAppElement("#root");

// class ReplyModal extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       isOpen: false,
//       isInProgress: false,
//       text: "",
//       count: 0,
//       scount: 0,
//       fcount: 0,
//       sreviews: [],
//       freviews: [],
//       message: "",
//       emessage: "",
//       items: [],
//       previewLoading: false,
//       countries_and_language_code: [
//         { Country: "France", nationalLanguageCodes: ["fr"] },
//         { Country: "United Kingdom", nationalLanguageCodes: ["en"] },
//         { Country: "Canada", nationalLanguageCodes: ["fr", "en"] },
//         { Country: "Ireland", nationalLanguageCodes: ["en"] },
//         { Country: "Norway", nationalLanguageCodes: ["no"] },
//         { Country: "United States", nationalLanguageCodes: ["en"] },
//         { Country: "Spain", nationalLanguageCodes: ["es"] },
//         { Country: "Italy", nationalLanguageCodes: ["it"] },
//         { Country: "Netherlands", nationalLanguageCodes: ["nl"] },
//         { Country: "Portugal", nationalLanguageCodes: ["pt"] },
//         { Country: "Japan", nationalLanguageCodes: ["ja"] },
//         { Country: "Korea, Republic of", nationalLanguageCodes: ["ko"] },
//         { Country: "Poland", nationalLanguageCodes: ["pl"] },
//         { Country: "Australia", nationalLanguageCodes: ["en"] },
//         { Country: "Sweden", nationalLanguageCodes: ["sv"] },
//         { Country: "Finland", nationalLanguageCodes: ["fi"] },
//         { Country: "Germany", nationalLanguageCodes: ["de"] },
//       ],
//     };
//   }

//   /** toggle model open close */
//   toggleModal = () => {
//     this.setState({
//       text: "",
//       count: 0,
//       scount: 0,
//       fcount: 0,
//       sreviews: [],
//       freviews: [],
//       message: "",
//       emessage: "",
//       items: [],
//       isInProgress: false,
//     });
//     this.setState((prevState) => ({
//       isOpen: !prevState.isOpen,
//     }));
//   };

//   /** get headers for api call */
//   getHeaders = () => {
//     return {
//       "x-access-token": this.props.token,
//       "content-type": "application/json",
//     };
//   };

//   /** get selected reviews*/
//   selectedReviews = () => {
//     return this.props.selectedreviews;
//   };

//   /** post reply one to one  */
//   postOneToOneDeveloperComment = (review, message) => {
//     const headers = this.getHeaders();
//     // const url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/reply`;
//     const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
//     const reviews = [];
//     let repliedLanguage = "";
//     const languages = _.uniq(
//       [].concat.apply(
//         [],
//         _.filter(
//           this.state.countries_and_language_code,
//           (o) => o.Country === review.Country
//         )
//       )
//     );
//     if (_.size(languages) === 1) {
//       if (
//         languages[0].nationalLanguageCodes.includes(
//           _.lowerCase(review.EnduserReviewLanguage)
//         )
//       ) {
//         repliedLanguage = _.lowerCase(review.EnduserReviewLanguage);
//       } else {
//         repliedLanguage = "en";
//       }
//     } else {
//       repliedLanguage = "en";
//     }

//     this.translateDeveloperComment(repliedLanguage, message)
//       .then(({ error, comments }) => {
//         if (!error) {
//           const encodedcomment = encodeURI(comments);
//           reviews.push({
//             ...review,
//             EncodedReplyString: encodedcomment,
//             ReplyString: comments,
//             DeveloperMessage: message,
//             RepliedLanguage: repliedLanguage,
//           });
//           const body = {
//             reviews: reviews,
//             type: "one2one",
//             repliedBy: this.props.usr.email,
//           };
//           // console.log(body);
//           this.setState((prevState) => ({
//             ...prevState,
//             isInProgress: !prevState.isInProgress,
//           }));
//           const count= _.size(reviews)
//           axios
//             .post(url, body, {
//               headers:headers
//             })
//             .then((response) => {
//               const { data } = response;
//               const { repliedCount, failedCount} = data;
//               this.setState((prevState) => ({
//                 ...prevState,
//                 isInProgress: !prevState.isInProgress,
//                 count: count,
//                 scount: repliedCount,
//                 fcount: failedCount 
//               }));
//               this.props.resetPaginationPageToOne();
//               this.reloadReviews();
//               if(failedCount === 0){
//                 alert("We have posted the replies into the queue.")
//               } else {
//                 alert("We are not able to reply to review. Please re-login and try again.");
//               }
//               // console.log(response);
//               // if(response.data.msg.includes("We have posted your message into Google Playstore successfully.")){
//               //   this.setState((prevState) => ({
//               //     ...prevState,
//               //     isInProgress: !prevState.isInProgress,
//               //     count: 1,
//               //     scount: 1,
//               //     fcount: 0,
//               //     message: response.data.msg,
//               //     emessage: ''
//               //   }));
//               // } else {
//               //   this.setState((prevState) => ({
//               //     ...prevState,
//               //     isInProgress: !prevState.isInProgress,
//               //     count: 1,
//               //     scount: 0,
//               //     fcount: 1,
//               //     message: '',
//               //     emessage: response.data.msg
//               //   }));
//               // }
//             })
//             .catch((error) => {
//               this.setState((prevState) => ({
//                 ...prevState,
//                 isInProgress: !prevState.isInProgress,
//               }));
//               alert(
//                 "We are not able to reply to review. Please re-login and try again."
//               );
//             });
//           } else {
//           alert(
//             "We are not able to reply to review. Please re-login and try again."
//           );
//         }
//       })
//       .catch((error) => {
//         alert(
//           "We are not able to reply to review. Please re-login and try again."
//         );
//       });
//   };

//   /** post a response one to many */
//   postOneToManyDeveloperComment = (reviews, message) => {
//     const headers = this.getHeaders();
//     // const url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/reply`;
//     const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
//     const langCodes = _.uniq(_.flattenDeep(_.map(this.state.countries_and_language_code,o => o.nationalLanguageCodes)))
//     this.setState((prevState) => ({
//       ...prevState,
//       isInProgress: !prevState.isInProgress,
//     }));
//     this.translate(langCodes,message).then(response => {
//       const review_en = _.get(_.filter(response, o => o.lang === 'en'), '[0].treview')
//       const reviews_mapped_translated_comments = _.map(reviews, o => {
//         let developer_comment = review_en;
//         let country_and_language_code = _.filter(this.state.countries_and_language_code, cl => cl.Country === o.Country);
//         // console.log(o.Country, o.EnduserReviewLanguage, country_and_language_code);
//         country_and_language_code.forEach(calc => {
//           const { nationalLanguageCodes } = calc;
//           nationalLanguageCodes.forEach(nlc => {
//             if(nlc === o.EnduserReviewLanguage){
//               const cmt = _.trim(_.get(_.filter(response, ol => ol.lang === nlc), '[0].treview'))
//               if(_.size(cmt) > 0){
//                 developer_comment = cmt;
//               }
//             }
//           })
//         })
//         const encodedcomment = encodeURI(developer_comment);
//         return {
//           ...o,
//           ReplyString: developer_comment,
//           EncodedReplyString: encodedcomment,
//           DeveloperMessage: message
//         }
//       })

//     const body = {
//       type: "one2many",
//       reviews: reviews_mapped_translated_comments,
//       repliedBy: this.props.usr.email,
//     };
//     const count = _.size(reviews_mapped_translated_comments);
//     axios
//       .post(url, body, {
//         headers:headers
//       })
//       .then((response) => {
//         const { data } = response;
//         const { repliedCount, failedCount} = data;
//         this.setState((prevState) => ({
//           ...prevState,
//           isInProgress: !prevState.isInProgress,
//           count: count,
//           scount: repliedCount,
//           fcount: failedCount
//         }));
//         this.props.resetPaginationPageToOne();
//         this.reloadReviews();
//       })
//       .catch((error) => {
//         this.setState((prevState) => ({
//           ...prevState,
//           isInProgress: !prevState.isInProgress
//         }));

//         alert(
//           "We are not able to reply to reviews. Please re-login and try again."
//         );
//       });
//     }).catch(error => {
//       this.setState((prevState) => ({
//         ...prevState,
//         isInProgress: !prevState.isInProgress,
//       }));
//       alert(
//         "We are not able to reply to reviews. Please re-login and try again."
//       );
//     })
//   };

// translate = (langCodes, message) => {
//     const headers = {
//       apiKey: "Qg7IAIkgY5y0qeMJky2X56ZZ0I4eDx3q",
//       "Content-Type": "application/json",
//     };
//     const url = `https://syncwrapper.inten.to/ai/text/translate`;
//     const promises = langCodes.map((lang) => {
//       const body = {
//         context: { text: message, to: lang },
//         service: {
//           provider: "ai.text.translate.google.translate_api.v3beta1",
//         },
//       };
//       return axios.post(url, body, {
//         headers:headers
//       });
//     });
//     return axios.all(promises).then((responses) => {
//       const items = _.map(responses, ({config, status, data}) => {
//         const payload = JSON.parse(config.data);
//         if(status === 200) {
//           const langCode = _.get(payload, 'context.to');
//           const translatedReview = _.get(data, 'results[0]')
//           return { lang: langCode, treview: translatedReview }
//         }
//       })
//       return items;
//     })
// }

//   translateDeveloperComments = async (langCodes, message) => {
//     this.translate(langCodes, message).then(items => {
//       // console.log(items);
//     }).catch(error => {

//     })
//     // const qs = require("qs");
//     // const headers = {
//     //   apiKey: "Qg7IAIkgY5y0qeMJky2X56ZZ0I4eDx3q",
//     //   "Content-Type": "application/json",
//     // };
//     // const url = `https://syncwrapper.inten.to/ai/text/translate`;
//     // const tlang = [];
//     // const promises = langCodes.map((lang) => {
//     //   const body = {
//     //     context: { text: message, to: lang },
//     //     service: {
//     //       provider: "ai.text.translate.google.translate_api.v3beta1",
//     //     },
//     //   };
//     //   return axios.post(url, body, {
//     //     headers:headers
//     //   });
//     // });
//     // axios.all(promises).then((responses) => {
//     //   responses.forEach(({config, status, data}) => {
//     //     const payload = JSON.parse(config.data);
//     //     if(status === 200) {
//     //       const langCode = _.get(payload, 'context.to');
//     //       const translatedReview = _.get(data, 'results[0]')
//     //       console.log(langCode, translatedReview)
//     //     }
//     //   })
//     //   const items = _.maps(responses, ({config, status, data}) => {
//     //     const payload = JSON.parse(config.data);
//     //     if(status === 200) {
//     //       const langCode = _.get(payload, 'context.to');
//     //       const translatedReview = _.get(data, 'results[0]')
//     //       return { lang: langCode, treview: translatedReview }
//     //     }
//     //   })
//     //   this.setState((prevState) => {
//     //     return {
//     //       ...prevState,
//     //       items : items
//     //     }
//     //   })
//     // })
//   };

//   translateDeveloperComment = async (langCode, message) => {
//     const axios = require("axios");
//     const qs = require("qs");
//     const headers = {
//       apiKey: "Qg7IAIkgY5y0qeMJky2X56ZZ0I4eDx3q",
//       "Content-Type": "application/json",
//     };
//     const url = `https://syncwrapper.inten.to/ai/text/translate`;
//     const body = {
//       context: { text: message, to: langCode },
//       service: { provider: "ai.text.translate.google.translate_api.v3beta1" },
//     };
//     return axios
//       .post(url, body, {
//         headers:headers
//       })
//       .then((response) => {
//         return {
//           comments: response.data.results[0],
//           langCode: langCode,
//           error: false,
//         };
//       })
//       .catch((error) => {
//         return {
//           error: true,
//         };
//       });
//   };

//   reloadReviews = () => {
//     this.props.negativeReviewRequest();
//         // var url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/negative/list`
//         var url = 'http://localhost:8080/api/v1/ios/reviews/negative/list'
//         axios
//           .get(url, {
//             headers: {
//               "x-access-token": this.props.token,
//             },
//           })
//           .then((response) => {
//             // console.log(response)
//             const { records, count } = response.data;
//             count > 0
//               ? this.props.negativeReviewFetchSuccess(records)
//               : this.props.negativeReviewFetchFailed("No records found");
//           })
//           .catch((error) => {
//             this.props.negativeReviewFetchFailed(error.message);
//             alert(error.message);
//           });
//   }

//   /** preview comments */
//   previewDeveloperComment = (message) => {
//     const axios = require("axios");
//     const qs = require("qs");
//     const headers = {
//       apiKey: "Qg7IAIkgY5y0qeMJky2X56ZZ0I4eDx3q",
//       "Content-Type": "application/json",
//     };
//     const url = `https://syncwrapper.inten.to/ai/text/translate`;
//     const selectedreviews = this.selectedReviews();
    
//     const items = [];
//     if (this.props.all) {
      
//       for (const [index, value] of selectedreviews.entries()) {
//         //console.log(value.intentoLangPrediction)
//         const body = {
//           context: { text: message, to: value.EnduserReviewLanguage },
//           service: {
//             provider: "ai.text.translate.google.translate_api.v3beta1",
//           },
//         };
//         axios
//           .post(url, body, {
//             headers:headers
//           })
//           .then((response) => {
//             //const doubled = "";

//             // const options=_.filter(countryCodeList, tmp => tmp.code === value.countryCode).map(country => country.name)
//             //console.log(options);
//             items.push({
//               comments: response.data.results[0],
//               countryCode: value.EnduserReviewLanguage,
//               countryName: value.Country,
//             });

//             // this.setState({ text: response.data.results[0],
//             // items:items})
//             this.setState((prevState) => {
//               return {
//                 ...prevState,
//                items: items, isInProgress: false
//               }
//             });
//           })
//           .catch((error) => {
//             this.setState((prevState) => ({
//               ...prevState,
//               isInProgress: !prevState.isInProgress,
//             }));
//             alert(
//               "We are not able to preview comments. Please re-login and try again."
//             );
//           });
//       }
//     } else {
//       const body = {
//         context: { text: message, to: this.props.review.EnduserReviewLanguage },
//         service: { provider: "ai.text.translate.google.translate_api.v3beta1" },
//       };
//       axios
//         .post(url, body, {
//           headers:headers
//         })
//         .then((response) => {
//           //const doubled = "";

//           // const options=_.filter(countryCodeList, tmp => tmp.code === value.countryCode).map(country => country.name)
//           //console.log(options);
//           items.push({
//             comments: response.data.results[0],
//             countryCode: this.props.review.EnduserReviewLanguage,
//             countryName: this.props.review.Country,
//           });
//           this.setState({ items: items, isInProgress: false });
//         })
//         .catch((error) => {
//           this.setState((prevState) => ({
//             ...prevState,
//             isInProgress: !prevState.isInProgress,
//           }));
//           alert(
//             "We are not able to preview comments. Please re-login and try again."
//           );
//         });
//     }

//     //console.log(items);

//     this.setState((prevState) => ({
//       ...prevState,
//       isInProgress: !prevState.isInProgress,
//     }));
//   };

//   textfieldValueChangeHandler = (country, value) => {
//     let items = this.state.items;
//     items = _.map(items, (item) => {
//       if (item.country === country) {
//         item["comments"] = value;
//       }
//       return item;
//     });
//     this.setState((prevState) => {
//       return {
//         ...prevState,
//         items: items,
//       };
//     });
//   };

//   render() {

//     return (
//       <>
//         <div className="root-modal">
//           <button
//             disabled={this.props.all ? this.props.srr.length === 0 : false}
//             style={{
//               color: `${
//                 this.props.all
//                   ? this.props.srr.length > 0
//                     ? "gray"
//                     : "lightgray"
//                   : "gray"
//               }`,
//               background: "transparent",
//               borderColor: "transparent",
//             }}
//             onClick={() => {
//               this.toggleModal();
//             }}
//           >
//             <FontAwesomeIcon
//               icon={this.props.all ? faReplyAll : faReply}
//               style={{
//                 color: `${
//                   this.props.all
//                     ? this.props.srr.length > 0
//                       ? "gray"
//                       : "lightgray"
//                     : "gray"
//                 }`,
//               }}
//             />{" "}
//             {this.props.all ? "Reply all" : "Reply"}
//           </button>
//           <Modal
//             isOpen={this.state.isOpen}
//             className="modal-content"
//             overlayClassName="modal-overlay"
//           >
//             <div className="header">
//               <div className="dc-r-flex-container">
//                 <div>
//                   <div style={{ paddingLeft: "10px" }}>
//                     <sup style={{ top: "0.5em", color: "lightgreen" }}>iOS</sup>
//                     <h5>Developer Feedback Form</h5>
//                   </div>
//                 </div>
//                 <div className="dc-r-flex-container-end">
//                   <div className="item">
//                     <div
//                       className="btn"
//                       onClick={() => {
//                         this.toggleModal();
//                       }}
//                     >
//                       <FontAwesomeIcon icon={faTimesCircle} className="exit" />{" "}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="body">
//               <div className="dc-r-flex-container">
//                 <div className="item">
//                   <small>Feedback Message</small>
//                 </div>
//               </div>
//               <div className="dc-r-flex-container">
//                 <textarea
//                   disabled={this.state.isInProgress}
//                   value={this.state.text}
//                   className="form-control feedback"
//                   onChange={({ target }) =>
//                     this.setState({ text: target.value })
//                   }
//                 />
//               </div>
//               <br />
//               <div
//                 style={{
//                   display: `${
//                     this.state.items.length > 0 && this.state.text.length > 0
//                       ? "block"
//                       : "none"
//                   }`,
//                 }}
//               >
//                 <h1 style={{ fontWeight: "bold", fontSize: 13 }}>
//                   Disclaimer :{" "}
//                 </h1>
//                 <p style={{ fontSize: 13 }}>
//                   Below translated developer comment will be posted to the end
//                   users
//                 </p>
//                 <div className="row" style={{padding: '0px 10px', width: '90%'}}>
//                   <div className="col-xs-2 col-md-2">
//                     <input
//                       style={{
//                         fontWeight: "bold",
//                         borderColor: "transparent",
//                         fontSize: 13,
//                       }}
//                       disabled={true}
//                       value="Country"
//                     />
//                   </div>
//                   <div className="col-xs-10 col-md-10">
//                     <input
//                       style={{
//                         fontWeight: "bold",
//                         borderColor: "transparent",
//                         fontSize: 13,
//                       }}
//                       disabled={true}
//                       value="Preview Comments"
//                     />
//                   </div>
//                 </div>
//                 {
                
//                 _.uniqWith(this.state.items, _.isEqual).map((x, i) => {
//                   const country = x.countryName;
//                   const value = x.comments;
//                   return (
//                     <div>
//                       <div className="row" style={{padding: '0px 10px', width: '90%'}}>
//                         <div className="col-xs-2 col-md-2">
//                           <input
//                             disabled={true}
//                             style={{
//                               color: `${
//                                 this.props.all
//                                   ? this.props.srr.length > 0
//                                     ? "gray"
//                                     : "lightgray"
//                                   : "gray"
//                               }`,
//                               background: "transparent",
//                               borderColor: "transparent",
//                               fontSize: 13,
//                             }}
//                             placeholder="Country"
//                             name="country"
//                             value={x.countryName}
//                           />
//                         </div>
//                         <div className="col-xs-10 col-md-10">
//                           <input
//                             className="col-xs-12 col-md-12"
//                             style={{
//                               color: `${
//                                 this.props.all
//                                   ? this.props.srr.length > 0
//                                     ? "gray"
//                                     : "lightgray"
//                                   : "gray"
//                               }`,
//                               background: "transparent",
//                               borderColor: "transparent",
//                               fontSize: 13,
//                             }}
//                             placeholder="Comment"
//                             name="comments"
//                             value={x.comments}
//                             disabled={true}
//                             onChange={(event) => {
//                               this.textFieldValueChangeHandler(
//                                 country,
//                                 event.target.value
//                               );
//                             }}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//             <div className="footer">
//               <div className="dc-r-flex-container">
//                 <div className="status-bar">
//                   {this.state.isInProgress ? (
//                     <Loaders message="loading..." />
//                   ) : this.state.count > 0 &&
//                     this.state.text.length > 0 &&
//                     (this.state.scount > 0 || this.state.fcount > 0) ? (
//                     <>
//                       <small style={{ color: "green" }}>
//                         <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
//                         &nbsp;{this.state.scount}/{this.state.count} success
//                       </small>
//                       <br />
//                       <small style={{ color: "red" }}>
//                         <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
//                         &nbsp;{this.state.fcount}/{this.state.count} failed
//                       </small>
//                     </>
//                   ) : (
//                     <small></small>
//                   )}
//                 </div>
//                 <div className="dc-r-flex-container-end">
//                   <div className="item">
//                     <button
//                       className="btn"
//                       onClick={() => {
//                         this.setState({
//                           // isOpen: false,
//                           isInProgress: false,
//                           text: "",
//                           count: 0,
//                           scount: 0,
//                           fcount: 0,
//                           sreviews: [],
//                           freviews: [],
//                           message: "",
//                           emessage: "",
//                         });
//                       }}
//                     >
//                       <FontAwesomeIcon
//                         icon={faUndo}
//                         style={{ color: "gray" }}
//                       />{" "}
//                       Clear Message
//                     </button>
//                   </div>
//                   <div className="item">
//                     <button
//                       className="btn"
//                       disabled={
//                         this.state.text.length <= 0 || this.state.isInProgress
//                       }
//                       style={{
//                         color: `${
//                           this.props.all
//                             ? this.props.srr.length > 0
//                               ? "gray"
//                               : "lightgray"
//                             : "gray"
//                         }`,
//                         background: "transparent",
//                         borderColor: "transparent",
//                       }}
//                       onClick={() => {
//                         this.previewDeveloperComment(this.state.text);
//                       }}
//                     >
//                       <FontAwesomeIcon
//                         icon={faEye}
//                         style={{
//                           color: `${
//                             this.props.all
//                               ? this.props.srr.length > 0
//                                 ? "gray"
//                                 : "lightgray"
//                               : "gray"
//                           }`,
//                         }}
//                       />{" "}
//                       Preview Comments
//                     </button>
//                   </div>
//                   <div className="item">
//                     <button
//                       className="btn"
//                       disabled={
//                         this.state.text.length <= 20 || this.state.isInProgress
//                       }
//                       style={{
//                         color: `${
//                           this.props.all
//                             ? this.props.srr.length > 0
//                               ? "gray"
//                               : "lightgray"
//                             : "gray"
//                         }`,
//                         background: "transparent",
//                         borderColor: "transparent",
//                       }}
//                       onClick={() => {
//                         this.props.all
//                           ? this.postOneToManyDeveloperComment(
//                               this.props.srr,
//                               this.state.text
//                             )
//                           : this.postOneToOneDeveloperComment(
//                               this.props.review,
//                               this.state.text
//                             );
//                       }}
//                     >
//                       <FontAwesomeIcon
//                         icon={this.props.all ? faReplyAll : faReply}
//                         style={{
//                           color: `${
//                             this.props.all
//                               ? this.props.srr.length > 0
//                                 ? "gray"
//                                 : "lightgray"
//                               : "gray"
//                           }`,
//                         }}
//                       />{" "}
//                       {this.props.all ? "Post Message to All" : "Post Message"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Modal>
//         </div>
//       </>
//     );
//   }
// }

// const mapStatsToProps = (state) => {
//   return {
//     reviews: state.iosdcp.reviews,
//     token: state.au.token,
//     usr: state.usr.user,
//     selectedreviews: state.iosdcp.selectedReviewsForReply,
//   };
// };

// const countryCodeList = [
//   { name: "Afghanistan", code: "AF" },
//   { name: "Åland Islands", code: "AX" },
//   { name: "Albania", code: "AL" },
//   { name: "Algeria", code: "DZ" },
//   { name: "American Samoa", code: "AS" },
//   { name: "AndorrA", code: "AD" },
//   { name: "Angola", code: "AO" },
//   { name: "Anguilla", code: "AI" },
//   { name: "Antarctica", code: "AQ" },
//   { name: "Antigua and Barbuda", code: "AG" },
//   { name: "Argentina", code: "AR" },
//   { name: "Armenia", code: "AM" },
//   { name: "Aruba", code: "AW" },
//   { name: "Australia", code: "AU" },
//   { name: "Austria", code: "AT" },
//   { name: "Azerbaijan", code: "AZ" },
//   { name: "Bahamas", code: "BS" },
//   { name: "Bahrain", code: "BH" },
//   { name: "Bangladesh", code: "BD" },
//   { name: "Barbados", code: "BB" },
//   { name: "Belarus", code: "BY" },
//   { name: "Belgium", code: "BE" },
//   { name: "Belize", code: "BZ" },
//   { name: "Benin", code: "BJ" },
//   { name: "Bermuda", code: "BM" },
//   { name: "Bhutan", code: "BT" },
//   { name: "Bolivia", code: "BO" },
//   { name: "Bosnia and Herzegovina", code: "BA" },
//   { name: "Botswana", code: "BW" },
//   { name: "Bouvet Island", code: "BV" },
//   { name: "Brazil", code: "BR" },
//   { name: "British Indian Ocean Territory", code: "IO" },
//   { name: "Brunei Darussalam", code: "BN" },
//   { name: "Bulgaria", code: "BG" },
//   { name: "Burkina Faso", code: "BF" },
//   { name: "Burundi", code: "BI" },
//   { name: "Cambodia", code: "KH" },
//   { name: "Cameroon", code: "CM" },
//   { name: "Canada", code: "CA" },
//   { name: "Cape Verde", code: "CV" },
//   { name: "Cayman Islands", code: "KY" },
//   { name: "Central African Republic", code: "CF" },
//   { name: "Chad", code: "TD" },
//   { name: "Chile", code: "CL" },
//   { name: "China", code: "CN" },
//   { name: "Christmas Island", code: "CX" },
//   { name: "Cocos (Keeling) Islands", code: "CC" },
//   { name: "Colombia", code: "CO" },
//   { name: "Comoros", code: "KM" },
//   { name: "Congo", code: "CG" },
//   { name: "Congo, The Democratic Republic of the", code: "CD" },
//   { name: "Cook Islands", code: "CK" },
//   { name: "Costa Rica", code: "CR" },
//   { name: 'Cote D"Ivoire', code: "CI" },
//   { name: "Croatia", code: "HR" },
//   { name: "Cuba", code: "CU" },
//   { name: "Cyprus", code: "CY" },
//   { name: "Czech Republic", code: "CZ" },
//   { name: "Denmark", code: "DK" },
//   { name: "Djibouti", code: "DJ" },
//   { name: "Dominica", code: "DM" },
//   { name: "Dominican Republic", code: "DO" },
//   { name: "Ecuador", code: "EC" },
//   { name: "Egypt", code: "EG" },
//   { name: "El Salvador", code: "SV" },
//   { name: "Equatorial Guinea", code: "GQ" },
//   { name: "Eritrea", code: "ER" },
//   { name: "Estonia", code: "EE" },
//   { name: "Ethiopia", code: "ET" },
//   { name: "Falkland Islands (Malvinas)", code: "FK" },
//   { name: "Faroe Islands", code: "FO" },
//   { name: "Fiji", code: "FJ" },
//   { name: "Finland", code: "FI" },
//   { name: "France", code: "FR" },
//   { name: "French Guiana", code: "GF" },
//   { name: "French Polynesia", code: "PF" },
//   { name: "French Southern Territories", code: "TF" },
//   { name: "Gabon", code: "GA" },
//   { name: "Gambia", code: "GM" },
//   { name: "Georgia", code: "GE" },
//   { name: "Germany", code: "DE" },
//   { name: "Ghana", code: "GH" },
//   { name: "Gibraltar", code: "GI" },
//   { name: "Greece", code: "GR" },
//   { name: "Greenland", code: "GL" },
//   { name: "Grenada", code: "GD" },
//   { name: "Guadeloupe", code: "GP" },
//   { name: "Guam", code: "GU" },
//   { name: "Guatemala", code: "GT" },
//   { name: "Guernsey", code: "GG" },
//   { name: "Guinea", code: "GN" },
//   { name: "Guinea-Bissau", code: "GW" },
//   { name: "Guyana", code: "GY" },
//   { name: "Haiti", code: "HT" },
//   { name: "Heard Island and Mcdonald Islands", code: "HM" },
//   { name: "Holy See (Vatican City State)", code: "VA" },
//   { name: "Honduras", code: "HN" },
//   { name: "Hong Kong", code: "HK" },
//   { name: "Hungary", code: "HU" },
//   { name: "Iceland", code: "IS" },
//   { name: "India", code: "IN" },
//   { name: "Indonesia", code: "ID" },
//   { name: "Iran, Islamic Republic Of", code: "IR" },
//   { name: "Iraq", code: "IQ" },
//   { name: "Ireland", code: "IE" },
//   { name: "Isle of Man", code: "IM" },
//   { name: "Israel", code: "IL" },
//   { name: "Italy", code: "IT" },
//   { name: "Jamaica", code: "JM" },
//   { name: "Japan", code: "JP" },
//   { name: "Jersey", code: "JE" },
//   { name: "Jordan", code: "JO" },
//   { name: "Kazakhstan", code: "KZ" },
//   { name: "Kenya", code: "KE" },
//   { name: "Kiribati", code: "KI" },
//   { name: 'Korea, Democratic People"S Republic of', code: "KP" },
//   { name: "Korea, Republic of", code: "KR" },
//   { name: "Kuwait", code: "KW" },
//   { name: "Kyrgyzstan", code: "KG" },
//   { name: 'Lao People"S Democratic Republic', code: "LA" },
//   { name: "Latvia", code: "LV" },
//   { name: "Lebanon", code: "LB" },
//   { name: "Lesotho", code: "LS" },
//   { name: "Liberia", code: "LR" },
//   { name: "Libyan Arab Jamahiriya", code: "LY" },
//   { name: "Liechtenstein", code: "LI" },
//   { name: "Lithuania", code: "LT" },
//   { name: "Luxembourg", code: "LU" },
//   { name: "Macao", code: "MO" },
//   { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
//   { name: "Madagascar", code: "MG" },
//   { name: "Malawi", code: "MW" },
//   { name: "Malaysia", code: "MY" },
//   { name: "Maldives", code: "MV" },
//   { name: "Mali", code: "ML" },
//   { name: "Malta", code: "MT" },
//   { name: "Marshall Islands", code: "MH" },
//   { name: "Martinique", code: "MQ" },
//   { name: "Mauritania", code: "MR" },
//   { name: "Mauritius", code: "MU" },
//   { name: "Mayotte", code: "YT" },
//   { name: "Mexico", code: "MX" },
//   { name: "Micronesia, Federated States of", code: "FM" },
//   { name: "Moldova, Republic of", code: "MD" },
//   { name: "Monaco", code: "MC" },
//   { name: "Mongolia", code: "MN" },
//   { name: "Montserrat", code: "MS" },
//   { name: "Morocco", code: "MA" },
//   { name: "Mozambique", code: "MZ" },
//   { name: "Myanmar", code: "MM" },
//   { name: "Namibia", code: "NA" },
//   { name: "Nauru", code: "NR" },
//   { name: "Nepal", code: "NP" },
//   { name: "Netherlands", code: "NL" },
//   { name: "Netherlands Antilles", code: "AN" },
//   { name: "New Caledonia", code: "NC" },
//   { name: "New Zealand", code: "NZ" },
//   { name: "Nicaragua", code: "NI" },
//   { name: "Niger", code: "NE" },
//   { name: "Nigeria", code: "NG" },
//   { name: "Niue", code: "NU" },
//   { name: "Norfolk Island", code: "NF" },
//   { name: "Northern Mariana Islands", code: "MP" },
//   { name: "Norway", code: "NO" },
//   { name: "Oman", code: "OM" },
//   { name: "Pakistan", code: "PK" },
//   { name: "Palau", code: "PW" },
//   { name: "Palestinian Territory, Occupied", code: "PS" },
//   { name: "Panama", code: "PA" },
//   { name: "Papua New Guinea", code: "PG" },
//   { name: "Paraguay", code: "PY" },
//   { name: "Peru", code: "PE" },
//   { name: "Philippines", code: "PH" },
//   { name: "Pitcairn", code: "PN" },
//   { name: "Poland", code: "PL" },
//   { name: "Portugal", code: "PT" },
//   { name: "Puerto Rico", code: "PR" },
//   { name: "Qatar", code: "QA" },
//   { name: "Reunion", code: "RE" },
//   { name: "Romania", code: "RO" },
//   { name: "Russian Federation", code: "RU" },
//   { name: "RWANDA", code: "RW" },
//   { name: "Saint Helena", code: "SH" },
//   { name: "Saint Kitts and Nevis", code: "KN" },
//   { name: "Saint Lucia", code: "LC" },
//   { name: "Saint Pierre and Miquelon", code: "PM" },
//   { name: "Saint Vincent and the Grenadines", code: "VC" },
//   { name: "Samoa", code: "WS" },
//   { name: "San Marino", code: "SM" },
//   { name: "Sao Tome and Principe", code: "ST" },
//   { name: "Saudi Arabia", code: "SA" },
//   { name: "Senegal", code: "SN" },
//   { name: "Serbia and Montenegro", code: "CS" },
//   { name: "Seychelles", code: "SC" },
//   { name: "Sierra Leone", code: "SL" },
//   { name: "Singapore", code: "SG" },
//   { name: "Slovakia", code: "SK" },
//   { name: "Slovenia", code: "SI" },
//   { name: "Solomon Islands", code: "SB" },
//   { name: "Somalia", code: "SO" },
//   { name: "South Africa", code: "ZA" },
//   { name: "South Georgia and the South Sandwich Islands", code: "GS" },
//   { name: "Spain", code: "ES" },
//   { name: "Sri Lanka", code: "LK" },
//   { name: "Sudan", code: "SD" },
//   { name: "Suriname", code: "SR" },
//   { name: "Svalbard and Jan Mayen", code: "SJ" },
//   { name: "Swaziland", code: "SZ" },
//   { name: "Sweden", code: "SE" },
//   { name: "Switzerland", code: "CH" },
//   { name: "Syrian Arab Republic", code: "SY" },
//   { name: "Taiwan, Province of China", code: "TW" },
//   { name: "Tajikistan", code: "TJ" },
//   { name: "Tanzania, United Republic of", code: "TZ" },
//   { name: "Thailand", code: "TH" },
//   { name: "Timor-Leste", code: "TL" },
//   { name: "Togo", code: "TG" },
//   { name: "Tokelau", code: "TK" },
//   { name: "Tonga", code: "TO" },
//   { name: "Trinidad and Tobago", code: "TT" },
//   { name: "Tunisia", code: "TN" },
//   { name: "Turkey", code: "TR" },
//   { name: "Turkmenistan", code: "TM" },
//   { name: "Turks and Caicos Islands", code: "TC" },
//   { name: "Tuvalu", code: "TV" },
//   { name: "Uganda", code: "UG" },
//   { name: "Ukraine", code: "UA" },
//   { name: "United Arab Emirates", code: "AE" },
//   { name: "United Kingdom", code: "GB" },
//   { name: "United States", code: "US" },
//   { name: "United States Minor Outlying Islands", code: "UM" },
//   { name: "Uruguay", code: "UY" },
//   { name: "Uzbekistan", code: "UZ" },
//   { name: "Vanuatu", code: "VU" },
//   { name: "Venezuela", code: "VE" },
//   { name: "Viet Nam", code: "VN" },
//   { name: "Virgin Islands, British", code: "VG" },
//   { name: "Virgin Islands, U.S.", code: "VI" },
//   { name: "Wallis and Futuna", code: "WF" },
//   { name: "Western Sahara", code: "EH" },
//   { name: "Yemen", code: "YE" },
//   { name: "Zambia", code: "ZM" },
//   { name: "Zimbabwe", code: "ZW" },
// ];

// const mapDispatchToProps = dispatch => {
//   return {
//     negativeReviewRequest: () => {
//       dispatch({
//         type: "NEGATIVE_REVIEW_REQUEST",
//       });
//     },
//     negativeReviewFetchSuccess: (robjects) => {
//       dispatch(actions.negativeReviewSuccess(robjects));
//     },
//     negativeReviewFetchFailed: (eobject) => {
//       dispatch(actions.negativeReviewFailed(eobject));
//     },
//     resetPaginationPageToOne: () => {
//       dispatch(actions.setActivePage({ activePage: 1 }));
//     }
//   }
// };

// export default connect(mapStatsToProps, mapDispatchToProps)(ReplyModal);


import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import actions from "../../../../redux/developerconnectportal/ios/actions";
import {
  faReplyAll,
  faReply,
  faUndo,
  faTimesCircle,
  faCheckCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import "./Reply.css";
import Loaders from "../../../custom/loaders/loaders";
import axios from "axios";
import _ from "lodash";
import { responsiveFontSizes } from "@material-ui/core";
// import { ACCESS_TOKEN, REPLIED_BY } from "../../../secrets";

Modal.setAppElement("#root");

class ReplyModal extends Component {  
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      menuDropOpen : false,
      requiredResponse : '',
      selectedResponses: '',
      suggestions: [],
      isInProgress: false,
      text: "",
      count: 0,
      scount: 0,
      fcount: 0,
      sreviews: [],
      freviews: [],
      message: "",
      emessage: "",
      items: [],
      previewLoading: false,
      countries_and_language_code: [
        { Country: "France", nationalLanguageCodes: ["fr"] },
        { Country: "United Kingdom", nationalLanguageCodes: ["en"] },
        { Country: "Canada", nationalLanguageCodes: ["fr", "en"] },
        { Country: "Ireland", nationalLanguageCodes: ["en"] },
        { Country: "Norway", nationalLanguageCodes: ["no"] },
        { Country: "United States", nationalLanguageCodes: ["en"] },
        { Country: "Spain", nationalLanguageCodes: ["es"] },
        { Country: "Italy", nationalLanguageCodes: ["it"] },
        { Country: "Netherlands", nationalLanguageCodes: ["nl"] },
        { Country: "Portugal", nationalLanguageCodes: ["pt"] },
        { Country: "Japan", nationalLanguageCodes: ["ja"] },
        { Country: "Korea, Republic of", nationalLanguageCodes: ["ko"] },
        { Country: "Poland", nationalLanguageCodes: ["pl"] },
        { Country: "Australia", nationalLanguageCodes: ["en"] },
        { Country: "Sweden", nationalLanguageCodes: ["sv"] },
        { Country: "Finland", nationalLanguageCodes: ["fi"] },
        { Country: "Germany", nationalLanguageCodes: ["de"] },
      ],
    };
    
  }
  /** Getting tags & Language Code */
  gettingTags = () => {
    const { review } = this.props;
    // console.log(review);
    const axios = require("axios");
    const qs = require("qs");
    const headers = {
      "Content-Type": "application/json",
    };
    
    // const url = `http://localhost:8080/api/v1/staticresponses`;
    const url = `https://developerconnectproxyservice.azurewebsites.net/api/v1/developer/connect/suggestion/list`;
    var endusrlang = []
    var i=0;
    let selectedreviewe = [];
    if (this.props.all) {
      selectedreviewe = this.selectedReviews();
    } else {
      selectedreviewe.push({ ...review });
    }
    const reviewlen = selectedreviewe.length
    // console.log(selectedreviewe);
    for(i=0; i < reviewlen; i++){
      // endusrlang.push("Language:".concat(selectedreviewe[i].EnduserReviewLanguage).concat(";Tags:").concat(selectedreviewe[i].TAGS))
      _.forEach(selectedreviewe[i].TAGS, tag => {
        endusrlang.push(tag)
      })
    }  
    // console.log(endusrlang.join("-"))
    // const finalResponse=endusrlang.join("-")
    // console.log(finalResponse)
    const filters = _.uniq(endusrlang);
    const body = {
      filters: filters
    };
    axios
      .post(url, body, {
        headers:headers
      })
      .then(({data}) => {
        const en = _.map(data.suggestions, o => o.en);
        // console.log("En Reviews", en);
        this.setState({
          requiredResponse: en,
          suggestions: data.suggestions
        });
        // console.log(response.data.Responses)
      })
      .catch((error) => {
        alert(
          "Please choose your response"
        );
      })

  }
    
  /** toggle model open close */
  toggleModal = () => {
    this.setState({
      text: "",
      count: 0,
      scount: 0,
      fcount: 0,
      sreviews: [],
      freviews: [],
      message: "",
      emessage: "",
      items: [],
      isInProgress: false,
    });
    this.gettingTags()

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
    
  };


  /** open or close drop down **/
  handleDropDownButtonClick = () => {
    this.setState(state => {
      return {
        menuDropOpen: !state.menuDropOpen,
      };
    });
  };

  /** onclick select and close */


  /** get Value from selected responses */
  handleResponseChange = (val) => {
    if (this.props.all) {
      this.setState({ selectedResponses: val, menuDropOpen: false, text: val });
    } else {
      const lcode = _.keys(this.state.suggestions).includes(this.props.review.EnduserReviewLanguage) ? this.props.review.EnduserReviewLanguage : _.size(_.map(_.filter(this.state.countries_and_language_code, cl => cl.Country === this.props.review.Country), o => o.nationalLanguageCodes)) === 0 ? 'en' : _.toString(_.map(_.filter(this.state.countries_and_language_code, cl => cl.Country === this.props.review.Country), o => o.nationalLanguageCodes));
      let sval = _.map(_.filter(this.state.suggestions, o => o.en === val), o=> o[`${_.lowerCase(lcode)}`])
      sval = _.size(sval) === 0 ? val : sval[0];
      this.setState({ selectedResponses: val, menuDropOpen: false, text: sval});
    }
  }

  /** get headers for api call */
  getHeaders = () => {
    return {
      "x-access-token": this.props.token,
      "content-type": "application/json",
    };
  };

  /** get selected reviews*/
  selectedReviews = () => {
    return this.props.selectedreviews;
  };

  
  /** post reply one to one  */
  postOneToOneDeveloperComment = (review, message) => {
    const headers = this.getHeaders();
    // const url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/reply`;
    const url = "https://developerconnectproxyservice.azurewebsites.net/api/v1/ios/reviews/reply"
    // const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
    // const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
    const reviews = [];
    let repliedLanguage = "";
    const languages = _.uniq(
      [].concat.apply(
        [],
        _.filter(
          this.state.countries_and_language_code,
          (o) => o.Country === review.Country
        )
      )
    );
    if (_.size(languages) === 1) {
      if (
        languages[0].nationalLanguageCodes.includes(
          _.lowerCase(review.EnduserReviewLanguage)
        )
      ) {
        repliedLanguage = _.lowerCase(review.EnduserReviewLanguage);
      } else {
        repliedLanguage = "en";
      }
    } else {
      repliedLanguage = "en";
    }

    this.translateDeveloperComment(repliedLanguage, message)
      .then(({ error, comments }) => {
        if (!error) {
          const encodedcomment = encodeURI(comments);
          reviews.push({
            ...review,
            EncodedReplyString: encodedcomment,
            ReplyString: comments,
            DeveloperMessage: message,
            RepliedLanguage: repliedLanguage,
          });
          const body = {
            reviews: reviews,
            type: "one2one",
            repliedBy: this.props.usr.email,
          };
          // console.log(body);
          this.setState((prevState) => ({
            ...prevState,
            isInProgress: !prevState.isInProgress,
          }));
          const count= _.size(reviews)
          axios
            .post(url, body, {
              headers:headers
            })
            .then((response) => {
              const { data } = response;
              const { repliedCount, failedCount} = data;
              this.setState((prevState) => ({
                ...prevState,
                isInProgress: !prevState.isInProgress,
                count: count,
                scount: repliedCount,
                fcount: failedCount 
              }));
              this.props.resetPaginationPageToOne();
              this.reloadReviews();
              if(failedCount === 0){
                alert("We have posted the replies into the queue.")
              } else {
                alert("We are not able to reply to review. Please re-login and try again.");
              }
              // console.log(response);
              // if(response.data.msg.includes("We have posted your message into Google Playstore successfully.")){
              //   this.setState((prevState) => ({
              //     ...prevState,
              //     isInProgress: !prevState.isInProgress,
              //     count: 1,
              //     scount: 1,
              //     fcount: 0,
              //     message: response.data.msg,
              //     emessage: ''
              //   }));
              // } else {
              //   this.setState((prevState) => ({
              //     ...prevState,
              //     isInProgress: !prevState.isInProgress,
              //     count: 1,
              //     scount: 0,
              //     fcount: 1,
              //     message: '',
              //     emessage: response.data.msg
              //   }));
              // }
            })
            .catch((error) => {
              this.setState((prevState) => ({
                ...prevState,
                isInProgress: !prevState.isInProgress,
              }));
              alert(
                "We are not able to reply to review. Please re-login and try again."
              );
            });
          } else {
          alert(
            "We are not able to reply to review. Please re-login and try again."
          );
        }
      })
      .catch((error) => {
        alert(
          "We are not able to reply to review. Please re-login and try again."
        );
      });
  };

  /** post a response one to many */
  postOneToManyDeveloperComment = (reviews, message) => {
    const headers = this.getHeaders();
    // const url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/reply`;
    const url = "https://developerconnectproxyservice.azurewebsites.net/api/v1/ios/reviews/reply";
    // const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
    // const url = `http://localhost:8080/api/v1/ios/reviews/reply`;
    const langCodes = _.uniq(_.flattenDeep(_.map(this.state.countries_and_language_code,o => o.nationalLanguageCodes)))
    this.setState((prevState) => ({
      ...prevState,
      isInProgress: !prevState.isInProgress,
    }));
    this.translate(langCodes,message).then(response => {
      const review_en = _.get(_.filter(response, o => o.lang === 'en'), '[0].treview')
      const reviews_mapped_translated_comments = _.map(reviews, o => {
        let developer_comment = review_en;
        let country_and_language_code = _.filter(this.state.countries_and_language_code, cl => cl.Country === o.Country);
        // console.log(o.Country, o.EnduserReviewLanguage, country_and_language_code);
        country_and_language_code.forEach(calc => {
          const { nationalLanguageCodes } = calc;
          nationalLanguageCodes.forEach(nlc => {
            if(nlc === o.EnduserReviewLanguage){
              const cmt = _.trim(_.get(_.filter(response, ol => ol.lang === nlc), '[0].treview'))
              if(_.size(cmt) > 0){
                developer_comment = cmt;
              }
            }
          })
        })
        const encodedcomment = encodeURI(developer_comment);
        return {
          ...o,
          ReplyString: developer_comment,
          EncodedReplyString: encodedcomment,
          DeveloperMessage: message
        }
      })

    const body = {
      type: "one2many",
      reviews: reviews_mapped_translated_comments,
      repliedBy: this.props.usr.email,
    };
    const count = _.size(reviews_mapped_translated_comments);
    axios
      .post(url, body, {
        headers:headers
      })
      .then((response) => {
        const { data } = response;
        const { repliedCount, failedCount} = data;
        this.setState((prevState) => ({
          ...prevState,
          isInProgress: !prevState.isInProgress,
          count: count,
          scount: repliedCount,
          fcount: failedCount
        }));
        this.props.resetPaginationPageToOne();
        this.reloadReviews();
      })
      .catch((error) => {
        this.setState((prevState) => ({
          ...prevState,
          isInProgress: !prevState.isInProgress
        }));

        alert(
          "We are not able to reply to reviews. Please re-login and try again."
        );
      });
    }).catch(error => {
      this.setState((prevState) => ({
        ...prevState,
        isInProgress: !prevState.isInProgress,
      }));
      alert(
        "We are not able to reply to reviews. Please re-login and try again."
      );
    })
  };

  translate = (langCodes, message) => {
    const headers = {
      apiKey: "3o3NSepSa0V99FPtZhPL3Hra2p4WlxxC",
      "Content-Type": "application/json",
    };
    const url = `https://syncwrapper.inten.to/ai/text/translate`;
    const promises = langCodes.map((lang) => {
      const body = {
        context: { text: message, to: lang },
        service: {
          provider: "ai.text.translate.google.translate_api.v3beta1",
        },
      };
      return axios.post(url, body, {
        headers:headers
      });
    });
    return axios.all(promises).then((responses) => {
      const items = _.map(responses, ({config, status, data}) => {
        const payload = JSON.parse(config.data);
        if(status === 200) {
          const langCode = _.get(payload, 'context.to');
          const translatedReview = _.get(data, 'results[0]')
          return { lang: langCode, treview: translatedReview }
        }
      })
      return items;
    })
 }

  translateDeveloperComments = async (langCodes, message) => {
    this.translate(langCodes, message).then(items => {
      // console.log(items);
    }).catch(error => {

    })
    // const qs = require("qs");
    // const headers = {
    //   apiKey: "Qg7IAIkgY5y0qeMJky2X56ZZ0I4eDx3q",
    //   "Content-Type": "application/json",
    // };
    // const url = `https://syncwrapper.inten.to/ai/text/translate`;
    // const tlang = [];
    // const promises = langCodes.map((lang) => {
    //   const body = {
    //     context: { text: message, to: lang },
    //     service: {
    //       provider: "ai.text.translate.google.translate_api.v3beta1",
    //     },
    //   };
    //   return axios.post(url, body, {
    //     headers:headers
    //   });
    // });
    // axios.all(promises).then((responses) => {
    //   responses.forEach(({config, status, data}) => {
    //     const payload = JSON.parse(config.data);
    //     if(status === 200) {
    //       const langCode = _.get(payload, 'context.to');
    //       const translatedReview = _.get(data, 'results[0]')
    //       console.log(langCode, translatedReview)
    //     }
    //   })
    //   const items = _.maps(responses, ({config, status, data}) => {
    //     const payload = JSON.parse(config.data);
    //     if(status === 200) {
    //       const langCode = _.get(payload, 'context.to');
    //       const translatedReview = _.get(data, 'results[0]')
    //       return { lang: langCode, treview: translatedReview }
    //     }
    //   })
    //   this.setState((prevState) => {
    //     return {
    //       ...prevState,
    //       items : items
    //     }
    //   })
    // })
  };

  translateDeveloperComment = async (langCode, message) => {
    const axios = require("axios");
    const qs = require("qs");
    const headers = {
      apiKey: "3o3NSepSa0V99FPtZhPL3Hra2p4WlxxC",
      "Content-Type": "application/json",
    };
    const url = `https://syncwrapper.inten.to/ai/text/translate`;
    const body = {
      context: { text: message, to: langCode },
      service: { provider: "ai.text.translate.google.translate_api.v3beta1" },
    };
    return axios
      .post(url, body, {
        headers:headers
      })
      .then((response) => {
        return {
          comments: response.data.results[0],
          langCode: langCode,
          error: false,
        };
      })
      .catch((error) => {
        return {
          error: true,
        };
      });
  };

  reloadReviews = () => {
    this.props.negativeReviewRequest();
        // var url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/negative/list`
        // var url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/ios/reviews/negative/list`
        var url = `https://developerconnectproxyservice.azurewebsites.net/api/v1/ios/reviews/negative/list`
        // var url = 'http://localhost:8080/api/v1/ios/reviews/negative/list'
        // var url = 'http://localhost:8080/api/v1/ios/reviews/negative/list'
        axios
          .get(url, {
            headers: {
              "x-access-token": this.props.token,
            },
          })
          .then((response) => {
            // console.log(response)
            const { records, count } = response.data;
            count > 0
              ? this.props.negativeReviewFetchSuccess(records)
              : this.props.negativeReviewFetchFailed("No records found");
          })
          .catch((error) => {
            this.props.negativeReviewFetchFailed(error.message);
            alert(error.message);
          });
  }

  /** Extract Data */
 

  /** preview comments */
  previewDeveloperComment = (message) => {
    const axios = require("axios");
    const qs = require("qs");
    const headers = {
      apiKey: "3o3NSepSa0V99FPtZhPL3Hra2p4WlxxC",
      "Content-Type": "application/json",
    };
    const url = `https://syncwrapper.inten.to/ai/text/translate`;
    const selectedreviews = this.selectedReviews();
    
    const items = [];
    if (this.props.all) {
      
      for (const [index, value] of selectedreviews.entries()) {
        //console.log(value.intentoLangPrediction)
        const body = {
          context: { text: message, to: value.EnduserReviewLanguage },
          service: {
            provider: "ai.text.translate.google.translate_api.v3beta1",
          },
        };
        axios
          .post(url, body, {
            headers:headers
          })
          .then((response) => {
            //const doubled = "";

            // const options=_.filter(countryCodeList, tmp => tmp.code === value.countryCode).map(country => country.name)
            //console.log(options);
            items.push({
              comments: response.data.results[0],
              countryCode: value.EnduserReviewLanguage,
              countryName: value.Country,
            });

            // this.setState({ text: response.data.results[0],
            // items:items})
            this.setState((prevState) => {
              return {
                ...prevState,
               items: items, isInProgress: false
              }
            });
          })
          .catch((error) => {
            this.setState((prevState) => ({
              ...prevState,
              isInProgress: !prevState.isInProgress,
            }));
            alert(
              "We are not able to preview comments. Please re-login and try again."
            );
          });
      }
    } else {
      const body = {
        context: { text: message, to: this.props.review.EnduserReviewLanguage },
        service: { provider: "ai.text.translate.google.translate_api.v3beta1" },
      };
      axios
        .post(url, body, {
          headers:headers
        })
        .then((response) => {
          //const doubled = "";

          // const options=_.filter(countryCodeList, tmp => tmp.code === value.countryCode).map(country => country.name)
          //console.log(options);
          items.push({
            comments: response.data.results[0],
            countryCode: this.props.review.EnduserReviewLanguage,
            countryName: this.props.review.Country,
          });
          this.setState({ items: items, isInProgress: false });
        })
        .catch((error) => {
          this.setState((prevState) => ({
            ...prevState,
            isInProgress: !prevState.isInProgress,
          }));
          alert(
            "We are not able to preview comments. Please re-login and try again."
          );
        });
    }

    //console.log(items);

    this.setState((prevState) => ({
      ...prevState,
      isInProgress: !prevState.isInProgress,
    }));
  };

  textfieldValueChangeHandler = (country, value) => {
    let items = this.state.items;
    items = _.map(items, (item) => {
      if (item.country === country) {
        item["comments"] = value;
      }
      return item;
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        items: items,
      };
    });
  };
  

  render() {

    return (
      <>
        <div className="root-modal">
          <button
            disabled={this.props.all ? this.props.srr.length === 0 : false}
            style={{
              color: `${
                this.props.all
                  ? this.props.srr.length > 0
                    ? "gray"
                    : "lightgray"
                  : "gray"
              }`,
              background: "transparent",
              borderColor: "transparent",
            }}
            onClick={() => {
              this.toggleModal();
            }}
          >
            <FontAwesomeIcon
              icon={this.props.all ? faReplyAll : faReply}
              style={{
                color: `${
                  this.props.all
                    ? this.props.srr.length > 0
                      ? "gray"
                      : "lightgray"
                    : "gray"
                }`,
              }}
            />{" "}
            {this.props.all ? "Reply all" : "Reply"}
          </button>
          <Modal
            isOpen={this.state.isOpen}
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <div className="header">
              <div className="dc-r-flex-container">
                <div>
                  <div style={{ paddingLeft: "10px" }}>
                    <sup style={{ top: "0.5em", color: "lightgreen" }}>iOS</sup>
                    <h5>Developer Feedback Form</h5>
                  </div>
                </div>
              
                <div className="dc-r-flex-container-end">
                  <div className="item">
                    <div
                      className="btn"
                      onClick={() => {
                        this.toggleModal();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="exit" />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body">
            <div className="menuOpen" ref={this.container}>

                <button disabled={_.size(this.state.requiredResponse) === 0 || this.state.requiredResponse === 'No Matches'} type="button" class="responseButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.handleDropDownButtonClick}>
                  Feedback Suggestions
                </button>
                {this.state.menuDropOpen && (
                <div class="responseDropdown responseScroller" >
                  <ul>{this.state.requiredResponse.map(responses =>
                    {
                      return <li onClick={this.handleResponseChange.bind(this,responses)}>{responses}</li> 
                    }) }
                  
                  </ul>
                </div>)}
              </div>
              <br />
              <br />
              <div className="dc-r-flex-container">
                <div className="item">
                  <small>Feedback Message</small>
                </div>
              </div>
              
              <div className="dc-r-flex-container">
                <textarea
                  disabled={this.state.isInProgress}
                  value={this.state.text}
                  className="form-control feedback"
                  onChange={({ target }) =>
                    this.setState({ text: target.value })
                  }
                />
              </div>
              <br />
              <div
                style={{
                  display: `${
                    this.state.items.length > 0 && this.state.text.length > 0
                      ? "block"
                      : "none"
                  }`,
                }}
              >
                <h1 style={{ fontWeight: "bold", fontSize: 13 }}>
                  Disclaimer :{" "}
                </h1>
                <p style={{ fontSize: 13 }}>
                  Below translated developer comment will be posted to the end
                  users
                </p>
                <div className="row" style={{padding: '0px 10px', width: '90%'}}>
                  <div className="col-xs-2 col-md-2">
                    <input
                      style={{
                        fontWeight: "bold",
                        borderColor: "transparent",
                        fontSize: 13,
                      }}
                      disabled={true}
                      value="Country"
                    />
                  </div>
                  <div className="col-xs-10 col-md-10">
                    <input
                      style={{
                        fontWeight: "bold",
                        borderColor: "transparent",
                        fontSize: 13,
                      }}
                      disabled={true}
                      value="Preview Comments"
                    />
                  </div>
                </div>
                {
                
                _.uniqWith(this.state.items, _.isEqual).map((x, i) => {
                  const country = x.countryName;
                  const value = x.comments;
                  return (
                    <div>
                      <div className="row" style={{padding: '0px 10px', width: '90%'}}>
                        <div className="col-xs-2 col-md-2">
                          <input
                            disabled={true}
                            style={{
                              color: `${
                                this.props.all
                                  ? this.props.srr.length > 0
                                    ? "gray"
                                    : "lightgray"
                                  : "gray"
                              }`,
                              background: "transparent",
                              borderColor: "transparent",
                              fontSize: 13,
                            }}
                            placeholder="Country"
                            name="country"
                            value={x.countryName}
                          />
                        </div>
                        <div className="col-xs-10 col-md-10">
                          <input
                            className="col-xs-12 col-md-12"
                            style={{
                              color: `${
                                this.props.all
                                  ? this.props.srr.length > 0
                                    ? "gray"
                                    : "lightgray"
                                  : "gray"
                              }`,
                              background: "transparent",
                              borderColor: "transparent",
                              fontSize: 13,
                            }}
                            placeholder="Comment"
                            name="comments"
                            value={x.comments}
                            disabled={true}
                            onChange={(event) => {
                              this.textFieldValueChangeHandler(
                                country,
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="footer">
              <div className="dc-r-flex-container">
                <div className="status-bar">
                  {this.state.isInProgress ? (
                    <Loaders message="loading..." />
                  ) : this.state.count > 0 &&
                    this.state.text.length > 0 &&
                    (this.state.scount > 0 || this.state.fcount > 0) ? (
                    <>
                      <small style={{ color: "green" }}>
                        <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                        &nbsp;{this.state.scount}/{this.state.count} success
                      </small>
                      <br />
                      <small style={{ color: "red" }}>
                        <FontAwesomeIcon icon={faTimesCircle}></FontAwesomeIcon>
                        &nbsp;{this.state.fcount}/{this.state.count} failed
                      </small>
                    </>
                  ) : (
                    <small></small>
                  )}
                </div>
                <div className="dc-r-flex-container-end">
                  <div className="item">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          // isOpen: false,
                          isInProgress: false,
                          text: "",
                          selectedResponses: "",
                          count: 0,
                          scount: 0,
                          fcount: 0,
                          sreviews: [],
                          freviews: [],
                          message: "",
                          emessage: "",
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUndo}
                        style={{ color: "gray" }}
                      />{" "}
                      Clear Message
                    </button>
                  </div>
                  <div className="item">
                    <button
                      className="btn"
                      disabled={
                        this.state.text.length <= 0 || this.state.isInProgress
                      }
                      style={{
                        color: `${
                          this.props.all
                            ? this.props.srr.length > 0
                              ? "gray"
                              : "lightgray"
                            : "gray"
                        }`,
                        background: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        this.previewDeveloperComment(this.state.text);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          color: `${
                            this.props.all
                              ? this.props.srr.length > 0
                                ? "gray"
                                : "lightgray"
                              : "gray"
                          }`,
                        }}
                      />{" "}
                      Preview Comments
                    </button>
                  </div>
                  <div className="item">
                    <button
                      className="btn"
                      disabled={
                        this.state.text.length <= 20 || this.state.isInProgress
                      }
                      style={{
                        color: `${
                          this.props.all
                            ? this.props.srr.length > 0
                              ? "gray"
                              : "lightgray"
                            : "gray"
                        }`,
                        background: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        this.props.all
                          ? this.postOneToManyDeveloperComment(
                              this.props.srr,
                              this.state.text
                            )
                          : this.postOneToOneDeveloperComment(
                              this.props.review,
                              this.state.text
                            );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={this.props.all ? faReplyAll : faReply}
                        style={{
                          color: `${
                            this.props.all
                              ? this.props.srr.length > 0
                                ? "gray"
                                : "lightgray"
                              : "gray"
                          }`,
                        }}
                      />{" "}
                      {this.props.all ? "Post Message to All" : "Post Message"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}


const mapStatsToProps = (state) => {
  return {
    reviews: state.iosdcp.reviews,
    token: state.au.token,
    usr: state.usr.user,
    selectedreviews: state.iosdcp.selectedReviewsForReply,
  };
};

const countryCodeList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: 'Korea, Democratic People"S Republic of', code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

const mapDispatchToProps = dispatch => {
  return {
    negativeReviewRequest: () => {
      dispatch({
        type: "NEGATIVE_REVIEW_REQUEST",
      });
    },
    negativeReviewFetchSuccess: (robjects) => {
      dispatch(actions.negativeReviewSuccess(robjects));
    },
    negativeReviewFetchFailed: (eobject) => {
      dispatch(actions.negativeReviewFailed(eobject));
    },
    resetPaginationPageToOne: () => {
      dispatch(actions.setActivePage({ activePage: 1 }));
    }
  }
};

export default connect(mapStatsToProps, mapDispatchToProps)(ReplyModal);