import React from "react";

function IOSAppResponse() {

    return (
        <>
            {/* <iframe className="datastudio-iframe" title="iosappresponse" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/dc32b6b8-5bd3-47f9-abf6-148172530a3e/page/azjBB" frameBorder="0" allowFullScreen></iframe> */}
            <iframe className="datastudio-iframe" title="iosappresponse" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/fbd320be-0904-497e-93ba-29e917c2fcae/page/azjBB" frameBorder="0" allowFullScreen></iframe>
        </>
    );
}

export default IOSAppResponse;