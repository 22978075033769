import React from "react";
import { useClearCache } from "react-clear-cache";

function WebTrafficBoard2 () {

    const { emptyCacheStorage } = useClearCache();
    
    //reload 30 seconds once
    setInterval(() => {
        emptyCacheStorage();
    }, 1800000);

    return (
        <>
            <iframe id="webtrafficversion2-datastudio-iframe" title="webtrafficreportversion2" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/99516abd-4087-41b5-96d5-ed8abb9e2dd0/page/azjBB" frameBorder={0} allowFullScreen></iframe>
        </>
    );
}

export default WebTrafficBoard2;