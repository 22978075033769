import {
  NEGATIVE_REVIEW_REQUEST,
  NEGATIVE_REVIEW_SUCCESS,
  NEGATIVE_REVIEW_FAILED,
  DATE_FILTER_UPDATE_FAILED,
  DATE_FILTER_UPDATE_SUCCESS,
  DATE_FILTER_UPDATE_REQUEST,
  CLEAR_FILTER,
  SET_ACTIVE_PAGE_PAGINATOR,
  SET_REVIEWS_PER_PAGE,
  SET_SELECTED_CLASSIFIATION_TAG,
  SET_SELECTED_APP_VERSION_CODES,
  SET_SELECTED_COUNTRIES,
  SET_SELECTED_USERRATINGS,
  SET_CLEAR_APPVERSION_FLAG,
  SET_CLEAR_COUNTRIES_FLAG,
  SET_CLEAR_TAG_FLAG,
  UNSET_CLEAR_APPVERSION_FLAG,
  UNSET_CLEAR_COUNTRIES_FLAG,
  UNSET_CLEAR_TAG_FLAG,
  UPDATE_REVIEW_REPLY_LIST,
  UPDATE_CHECKALL_STATUS
} from "./types";

const negativeReviewRequest = () => {
  return {
    type: NEGATIVE_REVIEW_REQUEST,
  };
};

const negativeReviewSuccess = (negativeReviews) => {
  return {
    type: NEGATIVE_REVIEW_SUCCESS,
    payload: negativeReviews,
  };
};

const negativeReviewFailed = (error) => {
  return {
    type: NEGATIVE_REVIEW_FAILED,
    payload: error,
  };
};

const dateFilterUpdateRequest = () => {
    return {
        type: DATE_FILTER_UPDATE_REQUEST
    }
}

const dateFilterUpdateSuccess = (startDate, endDate) => {
    return {
        type: DATE_FILTER_UPDATE_SUCCESS,
        payload : {
            startDate: startDate,
            endDate: endDate
        }
    } 
}

const dateFilterUpdateFailed = () => {
    return {
        type: DATE_FILTER_UPDATE_FAILED
    }
}

const clearFilter = (dateRange) => {
    return {
        type: CLEAR_FILTER,
        payload: dateRange
    }
}

const setActivePage = (paginator) => {
  return {
    type: SET_ACTIVE_PAGE_PAGINATOR,
    payload: paginator
  }
}

const setReviewsPerPage = (number) => {
  return {
    type: SET_REVIEWS_PER_PAGE,
    payload: number
  }
}

const setSelectedClassificationTag = (selectedOptions) => {
  return {
    type: SET_SELECTED_CLASSIFIATION_TAG,
    payload: selectedOptions
  }
}

const setSelectedAppVersionCode = (selectedOptions) => {
  return {
    type: SET_SELECTED_APP_VERSION_CODES,
    payload: selectedOptions
  }
}

const setSelectedCountries = (selectedOptions) => {
  return {
    type: SET_SELECTED_COUNTRIES,
    payload: selectedOptions
  }
}

const setSelectedUserRatings = (selectedOptions) => {
  return {
    type: SET_SELECTED_USERRATINGS,
    payload: selectedOptions
  }
}

const setClearCountriesFlag = () => {
  return {
    type: SET_CLEAR_COUNTRIES_FLAG
  }
}

const setClearAppVersionFlag = () => {
  return {
    type: SET_CLEAR_APPVERSION_FLAG
  }
}

const setClearClassificationTagFlag = () => {
  return {
    type: SET_CLEAR_TAG_FLAG
  }
}


const unsetClearCountriesFlag = () => {
  return {
    type: UNSET_CLEAR_COUNTRIES_FLAG
  }
}

const unsetClearAppVersionFlag = () => {
  return {
    type: UNSET_CLEAR_APPVERSION_FLAG
  }
}

const unsetClearClassificationTagFlag = () => {
  return {
    type: UNSET_CLEAR_TAG_FLAG
  }
}

const updateReviewReplyList = (selectedReviews) => {
  return {
    type: UPDATE_REVIEW_REPLY_LIST,
    payload: selectedReviews
  }
}

const updateCheckAllStatus = (checked) => {
  return {
    type: UPDATE_CHECKALL_STATUS,
    payload:checked
  }
}





export default {
  negativeReviewRequest,
  negativeReviewSuccess,
  negativeReviewFailed,
  dateFilterUpdateRequest,
  dateFilterUpdateSuccess,
  dateFilterUpdateFailed,
  clearFilter,
  setActivePage,
  setReviewsPerPage,
  setSelectedClassificationTag,
  setSelectedAppVersionCode,
  setSelectedCountries,
  setSelectedUserRatings,
  setClearCountriesFlag,
  setClearAppVersionFlag,
  setClearClassificationTagFlag,
  unsetClearCountriesFlag,
  unsetClearAppVersionFlag,
  unsetClearClassificationTagFlag,
  updateReviewReplyList,
  updateCheckAllStatus
};
