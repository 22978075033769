import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, withFormik } from "formik";
import {
  Button,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: (props) =>
    Yup.object().shape({
      email: Yup.string()
        .matches(/(@ingka.com|@ingka.ikea.com|@inter.ikea.com)/, {
          message: "An email address should be a part of INGKA domain.",
          excludeEmptyString: true,
        })
        .email("Invalid email address")
        .required("Email is required!"),
      role: Yup.string().required("Role is required!"),
      platform: Yup.string().required("Platform is required!"),
      group: Yup.string().required("Group is required!"),
    }),
  mapPropsToValues: (props) => ({
    email: "",
  }),
  handleSubmit: (values, { setSubmitting, props }) => {
    // console.log(values);
  },
  displayName: "",
});

const handleFormReset = (handleReset) => {
  // store.dispatch(resetMessage());
  handleReset();
};

export class RBACForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRoles: [
        { value: "operator", label: "Operator" },
        { value: "administrator", label: "Administrator" },
        { value: "superuser", label: "Super User" },
      ],
    };
  }

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;
    // console.log(errors, values);

    return (
      <>
        <div style={{ padding: "1em 2em" }}>
          <span>
            <sup style={{ color: "lightgreen" }}>Automated Calling System</sup>
            <h5 style={{ position: "relative", top: "-10px" }}>
              Role-Based Access Control
            </h5>
          </span>
        </div>
        <Container>
          <Form onSubmit={handleSubmit}>
            <FormGroup row={false} style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-around', alignItems:'center' }}>
              <FormControl
                style={{minWidth: 200}}
                error={
                  Object.keys(errors).includes("platform")
                }
              >
                <InputLabel shrink id="select-role-label">
                  Platform
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  name="platform"
                  className={""}
                  value={values.platform}
                  touched={`${touched.platform}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {this.state.userRoles.map((role) => {
                    return (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.platform}</FormHelperText>
              </FormControl>
              <FormControl
                style={{minWidth: 200}}
                error={Object.keys(errors).includes("group")}
              >
                <InputLabel shrink id="select-role-label">
                  Group/Channel name
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  name="group"
                  className={""}
                  value={values.group}
                  touched={`${touched.group}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {this.state.userRoles.map((role) => {
                    return (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.group}</FormHelperText>
              </FormControl>
              <TextField
                name="email"
                label="Email address"
                type="email"
                placeholder="Enter an email address."
                style={{ minWidth: "150px" }}
                errors={errors.email}
                value={values.email}
                touched={`${touched.email}`}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email}
                error={Object.keys(errors).includes("email") && touched.email}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl
                error={Object.keys(errors).includes("role")}
                style={{minWidth: 200}}
              >
                <InputLabel shrink id="select-role-label">
                  Role
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="select-role"
                  name="role"
                  className={""}
                  style={{ minWidth: "150px" }}
                  value={values.role}
                  touched={`${touched.role}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {this.state.userRoles.map((role) => {
                    return (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.role}</FormHelperText>
              </FormControl>

              <Button type="submit" color="primary">
                {this.props.mode === "edit" ? "Update" : "Save"}
              </Button>
            </FormGroup>
          </Form>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  myroles: state.shrbac.roles,
  mode: state.shrbac.mode,
  operator: state.shrbac.operator,
  operators: state.shrbac.operators,
  issubmitted: state.shrbac.isSubmitting,
  user: state.usr.user,
  token: state.au.token,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikEnhancer(RBACForm));
