import { USER_FETCH_FAILED, USER_FETCH_REQUEST, USER_FETCH_SUCCESS} from './types';

const initialState = {
    loading: false,
    user: {
        email: '',
        familyName: '',
        givenName: '',
        googleId: '',
        imageUrl: '',
        name: ''
    },
    error: false,
    errorMessage: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FETCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_FETCH_SUCCESS: {
            return {
                ...state,
                loading:false,
                user: action.payload
            }
        }
        case USER_FETCH_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        default: return state;
    }
}

export default reducer;