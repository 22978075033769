import React from "react";
import { connect } from "react-redux";
import  actions from '../../redux/developerconnectportal/android/actions';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";
import "./DateTimeRangePicker.css";

const DateTimeRangePicker = (props) => {
    return (
      <>
        <DateRangePicker
          initialSettings={{
            startDate: moment.unix(props.startDate),
            endDate: moment.unix(props.endDate),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().endOf("month").toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}

          onCallback={props.updateDate}
        >
          <button
            style={{ background: "transparent", borderColor: "transparent", color: "gray"}}
          >
            <FontAwesomeIcon icon={faCalendarAlt} style={{ color: "gray"}}/>
            &nbsp;&nbsp;{`${moment.unix(props.startDate).format('MMM DD, YYYY')} - ${moment.unix(props.endDate).format('MMM DD, YYYY')}`}
          </button>
        </DateRangePicker>
      </>
    );
  }

const mapStatsToProps = (state) => {
  return {
    startDate: state.android.dateFilter.startDate,
    endDate: state.android.dateFilter.endDate
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateDate: (start, end, label) => {
      dispatch(actions.dateFilterUpdateSuccess(start.unix(), end.unix()))
    }
  }
}

export default connect(mapStatsToProps, mapDispatchToProps)(DateTimeRangePicker);
