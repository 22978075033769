import React, { Component, forwardRef } from "react";
import { motion } from "framer-motion";
import { moment } from "moment-timezone";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import Tooltip from "@material-ui/core/Tooltip";

/** Dependencies for delete confirmation */
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Third Party Module
import axios from "axios";

/** import actions from redux */
import { updateOperationInitiate } from "../../../../../../redux/shiftmanagement/actions";
import { enqueueSnackbar as enqueueSnackbarAction } from "../../../../../../redux/notification/actions";

/** import services to manage our actions */
import {
  deleteShiftRecord,
  listShiftRecord,
} from "../../../../../../services/shiftmanagement";
import { getMyRoles } from "../../../../../../services/shiftmanagementrbac";

// import custom styles
import "./ShiftManagementTable.css";
import { Container, Grid } from "@material-ui/core";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class ShiftManagmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // shifts: [],
      openDeleteDialog: false,
      deleteDocumentId: "",
      iseditable: false,
      isdeletable: false,
    };
  }

  componentDidMount() {
    this.props.getMyRoles(this.props.user.email, this.props.token);
    this.props.listShiftRecord(
      this.props.tool,
      this.props.agroup,
      this.props.jiraIssueType,
      this.props.token
    );
  }

  closeDeleteDialog = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        openDeleteDialog: false,
        deleteDocumentId: "",
      };
    });
  };

  deleteRecord = (id) => {
    this.props.deleteShiftRecord(
      id,
      this.props.tool,
      this.props.agroup,
      this.props.jiraIssueType,
      this.props.token
    );
  };

  mapObjectWithReduxObjectForUpdateOperation = (object) => {
    this.props.updateShiftObjectInRedux(object);
  };

  isAuthorizedToDelete = () => {
    let status = false;
    const { myroles, user, tool, agroup } = this.props;
    myroles.forEach((usr) => {
      const c1 = usr.platform === tool || [usr.platform].includes("all");
      const c2 = usr.group === agroup || [usr.group].includes("all");
      const c3 = usr.email === user.email;
      const c4 = ["superuser", "administrator"].includes(usr.role);
      if(c1 && c2 && c3 && c4){
        status = c1 && c2 && c3 && c4;
      }
      // console.log("Delete => ", status, myroles, tool, agroup, user);
    });
    // console.log("Delete => ", status, myroles, tool, agroup, user);
    return status;
  };

  isAuthorizedToEdit = () => {
    let status = false;
    const { myroles, user, tool, agroup } = this.props;
    myroles.forEach((usr) => {
      const c1 = usr.platform === tool || [usr.platform].includes("all");
      const c2 = usr.group === agroup || [usr.group].includes("all");
      const c3 = usr.email === user.email;
      const c4 = ["superuser", "administrator", "operator"].includes(usr.role);
      if(c1 && c2 && c3 && c4){
        status = c1 && c2 && c3 && c4;
      }
      // console.log("Edit => ", status, myroles, tool, agroup, user);
    });

    // console.log("Edit => ", status, myroles, tool, agroup, user);
    return status;
  };

  confirmationDialogWindow = () => {
    return (
      <Dialog
        open={this.state.openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.closeDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Do you want to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Kindly confirm to delete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDeleteDialog} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  openDeleteDialog: false,
                };
              });
              this.deleteRecord(this.state.deleteDocumentId);
            }}
            style={{ color: "red" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  render() {
    // console.log("Edit ==> ", this.isAuthorizedToEdit());
    // console.log("Delete ==> ", this.isAuthorizedToDelete())
    const isEditable = this.isAuthorizedToEdit();
    const isDelete = this.isAuthorizedToDelete();
    return (
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Typography variant="h6" component="span" style={{ color: "gray" }}>
            List of registered shift
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Shift Start Time (CET)</TableCell>
                  <TableCell>Shift End Time (CET)</TableCell>
                  <TableCell>Primary Number</TableCell>
                  <TableCell>Interval P2S</TableCell>
                  <TableCell>Secondary Number</TableCell>
                  <TableCell>Interval S2E</TableCell>
                  <TableCell>Escalation Number</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.shifts.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No shift found.
                    </TableCell>
                  </TableRow>
                ) : (
                  this.props.shifts.map((shift) => (
                    <TableRow key={shift.id}>
                      <TableCell />
                      <TableCell>{shift.startTime}</TableCell>
                      <TableCell>{shift.endTime}</TableCell>
                      <TableCell>{shift.primarycontactnumber}</TableCell>
                      <TableCell>
                        {shift.issecondaryenabled ? shift.intervalp2s : 0}
                      </TableCell>
                      <TableCell>
                        {shift.issecondaryenabled
                          ? shift.secondarycontactnumber
                          : "NA"}
                      </TableCell>
                      <TableCell>
                        {shift.isescalationenabled ? shift.intervals2e : 0}
                      </TableCell>
                      <TableCell>
                        {shift.isescalationenabled
                          ? shift.escalationcontactnumber
                          : "NA"}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            !isEditable
                              ? "You don't have permission to edit."
                              : "edit"
                          }
                        >
                          <span>
                            <IconButton
                              aria-label={
                                !isEditable
                                  ? "You are not authorized to perform edit operation."
                                  : "delete"
                              }
                              disabled={(this.props.mode === "edit" &&
                              this.props.id === shift.id) || (!isEditable)}
                              onClick={() => {
                                this.props.enqueueSnackbar({
                                  message:
                                    "Note : The edit mode will not allow you to update current shift timings of registered shift.So, Kindly delete a registered shift and recreate it.",
                                  options: {
                                    preventDuplicate: true,
                                    key: "editclicked",
                                    variant: "default",
                                    autoHideDuration: 15000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  },
                                });
                                this.mapObjectWithReduxObjectForUpdateOperation(
                                  shift
                                );
                              }}
                            >
                              <EditRoundedIcon></EditRoundedIcon>
                            </IconButton>
                            {/* <IconButton
                              disabled={
                                this.props.mode === "edit" &&
                                this.props.id === shift.id &&
                                this.state.iseditable &&
                                this.props.myroles.length === 0
                              }
                              onClick={() => {
                                this.props.enqueueSnackbar({
                                  message:
                                    "Note : The edit mode will not allow you to update current shift timings of registered shift.So, Kindly delete a registered shift and recreate it.",
                                  options: {
                                    preventDuplicate: true,
                                    key: "editclicked",
                                    variant: "default",
                                    autoHideDuration: 15000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  },
                                });
                                this.mapObjectWithReduxObjectForUpdateOperation(
                                  shift
                                );
                              }}
                            >
                              <EditRoundedIcon
                                color={
                                  this.props.mode === "edit" &&
                                  this.props.id === shift.id &&
                                  this.state.iseditable &&
                                  this.props.myroles.length === 0
                                    ? "disabled"
                                    : "primary"
                                }
                              />
                            </IconButton> */}
                          </span>
                        </Tooltip>

                        <Tooltip
                          title={
                            !isDelete
                              ? "You don't have permission to delete."
                              : "delete"
                          }
                        >
                          <span>
                            <IconButton
                              aria-label={
                                !isDelete
                                  ? "You are not authorized to perform delete operation."
                                  : "delete"
                              }
                              disabled={!isDelete}
                              onClick={() => {
                                const id = shift.id;

                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    deleteDocumentId: id,
                                    openDeleteDialog: true,
                                  };
                                });
                              }}
                            >
                              <DeleteRoundedIcon
                                style={{
                                  color: !isDelete ? "lightgray" : "red",
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {this.confirmationDialogWindow()}
      </>
    );
  }
}

const mapStatsToProps = (state) => {
  return {
    tool: state.shmgmt.selectedPlatformItem,
    agroup: state.shmgmt.assignmentGroup,
    jiraIssueType: state.shmgmt.jiraIssueType,
    shifts: state.shmgmt.shifts,
    mode: state.shmgmt.mode,
    id: state.shmgmt.id,
    token: state.au.token,
    myroles: state.shrbac.roles,
    user: state.usr.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listShiftRecord: (tool, agroup, jitype, token) => {
      dispatch(listShiftRecord(tool, agroup, jitype, token));
    },
    deleteShiftRecord: (id, tool, agroup, jitype, token, mode) => {
      dispatch(deleteShiftRecord(id, tool, agroup, jitype, token, mode));
    },
    updateShiftObjectInRedux: (object) => {
      dispatch(updateOperationInitiate(object));
    },
    enqueueSnackbar: (...args) => {
      dispatch(enqueueSnackbarAction(...args));
    },
    getMyRoles: (email, token) => {
      dispatch(getMyRoles(email, token));
    },
  };
};

export default connect(
  mapStatsToProps,
  mapDispatchToProps
)(ShiftManagmentTable);
