/** RBAC USER PERMISSIONS LOAD */
export const LOAD_REQUEST_SHMGMT_USER_PERMISSIONS = 'LOAD_REQUEST_SHMGMT_USER_PERMISSIONS';
export const LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS = 'LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS';
export const LOAD_FAILURE_SHMGMT_USER_PERMISSIONS = 'LOAD_FAILURE_SHMGMT_USER_PERMISSIONS';

/** RBAC USER PERMISSIONS UPDATE */
export const UPDATE_REQUEST_SHMGMT_USER_PERMISSIONS = 'UPDATE_REQUEST_SHMGMT_USER_PERMISSIONS';
export const UPDATE_SUCCESS_SHMGMT_USER_PERMISSIONS = 'UPDATE_SUCCESS_SHMGMT_USER_PERMISSIONS';
export const UPDATE_FAILURE_SHMGMT_USER_PERMISSIONS = 'UPDATE_FAILURE_SHMGMT_USER_PERMISSIONS';

/** RBAC USER PERMISSIONS DELETE */
export const DELETE_REQUEST_SHMGMT_USER_PERMISSIONS = 'DELETE_REQUEST_SHMGMT_USER_PERMISSIONS';
export const DELETE_SUCCESS_SHMGMT_USER_PERMISSIONS = 'DELETE_SUCCESS_SHMGMT_USER_PERMISSIONS';
export const DELETE_FAILURE_SHMGMT_USER_PERMISSIONS = 'DELETE_FAILURE_SHMGMT_USER_PERMISSIONS';

/** RBAC USER PERMISSION CREATE */
export const CREATE_REQUEST_SHMGMT_USER_PERMISSIONS = 'CREATE_REQUEST_SHMGMT_USER_PERMISSIONS';
export const CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS = 'CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS';
export const CREATE_FAILURE_SHMGMT_USER_PERMISSIONS = 'CREATE_FAILURE_SHMGMT_USER_PERMISSIONS';

/** RBAC USER PERMISSION - LOAD OPERATORS */
export const LOAD_REQUEST_SHMGMT_OPERATORS = 'LOAD_REQUEST_SHMGMT_OPERATORS';
export const LOAD_SUCCESS_SHMGMT_OPERATORS = 'LOAD_SUCCESS_SHMGMT_OPERATORS';
export const LOAD_FAILURE_SHMGMT_OPERATORS = 'LOAD_FAILURE_SHMGMT_OPERATORS';


export const CHANGE_MODE = 'CHANGE_MODE';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const RESET_EDIT_MODE = 'RESET_EDIT_MODE';