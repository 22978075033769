import React, { Component, createRef } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import Check from "@material-ui/icons/CheckCircleRounded";
import "./PlatformItem.css";

/** import redux actions */
import {
  updateSelectedAssignmentGroup
} from "../../../redux/shiftmanagement/actions";

export class PlatformItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.stateObject,
      isOpen: false,
    };
    this.platformItemRef = createRef();
  }

  render() {
    return (
      <motion.div
        onClick={() => {
          if (!this.props.object.disabled) {
            if (this.props.selectedItem === this.props.object.name) {
              this.props.updateSelectedPlatformItem("");
            } else {
              this.props.updateSelectedPlatformItem(this.props.object.name);
            }
          } else {
          }
        }}
        className="platform-item-placeholder"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        animate={
          this.props.object.disabled
            ? { opacity: 0.5, filter: "grayscale(100%)" }
            : { opacity: 1.0 }
        }
      >
        <img
          ref={this.platformItemRef}
          src={this.props.object.src}
          className={this.props.object.name === "slack" ? "slack item" : "item"}
          alt={this.props.object.name}
        />
        {this.props.selectedItem === this.props.object.name ? (
          <Check
            style={{
              width: "15px",
              height: "15px",
            }}
            className="selected"
          />
        ) : null}
      </motion.div>
    );
  }
}

const mapStatsToProps = (state) => ({
  selectedItem: state.shmgmt.selectedPlatformItem,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedPlatformItem: (name) => {
    dispatch({
      type: "UPDATE_PLATFORM_SELECTED",
      name: name,
    });
  },
  updateAG: (gname) => {
    dispatch(updateSelectedAssignmentGroup(gname));
  }
});

export default connect(mapStatsToProps, mapDispatchToProps)(PlatformItem);
