import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faUndoAlt,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPen,
  faMinusCircle,
  faCheckCircle,
  faTimesCircle,
  faUserPlus,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

/** import redux actions */
import {
  fetchRequestUserRoles,
  fetchRequestUserRoleSuccess,
  fetchRequestUserRoleFailed,
} from "../../redux/settings/actions";

/** import css */
import "./IOSConnectPortalSettings.css";

class IOSConnectPortalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorAtLoading: false,
      isSubmitted: false,
      mode: "view",
      create: {
        email: "",
        role: "",
      },
      editor: {
        email: "",
        role: "",
        docId: "",
      },
      searchFilter: {
        searchText: "",
      },
      reviewFilter: {
        noOfReviewsPerPage: 5,
        currentDefaultPage: 1,
        activePage: 1,
      },
      pageName: "ios_developer_connect",
      roleOptions: [
        { value: "admin", label: "Admin" },
        { value: "developer", label: "Developer" },
        { value: "user", label: "User" },
      ],
      url: "https://predictions.oi.ingka.com/api/v1/roles/list",
      addRoleUrl: "https://predictions.oi.ingka.com/api/v1/roles",
      updateRoleUrl: "https://predictions.oi.ingka.com/api/v1/roles"
    };
    this.seachRef = createRef(null);
    this.submitRef = createRef();
    this.roleRef = createRef();
    this.searchHandler = this.searchHandler.bind(this);
    this.deleteUserHandler = this.deleteUserHandler.bind(this);
    this.formHandler = this.formHandler.bind(this);
  }

  componentDidMount() {
    this.loadUser();
  }

  clearStateForForm() {
    this.setState(prevState => ({
      ...prevState,
      mode: 'view',
      editor: {
        ...prevState.editor,
        email: '',
        role: '',
        docId: ''
      },
      create: {
        ...prevState.create,
        role: '',
        email: ''
      }
    }))
    this.roleRef.current.select.setState({
      ...this.roleRef.current.select.state,
      selectValue:[]
    })
  }

  addUser() {
    axios
      .post(this.state.addRoleUrl,{
        email: this.state.create.email,
        page: this.state.pageName,
        role: this.state.create.role,
        lastModifiedBy: this.props.usr.email
      }, {
        headers: {
          "x-access-token": this.props.token,
        }
      })
      .then((response) => {
        alert(response.data.message);
        this.clearStateForForm();
        this.loadUser();
      })
      .catch((error) => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          // console.log(error.response);
        } else if (error.request) {
          // client never received a response, or request never left
          // console.log(error.request);
        } else {
          // anything else
        }
        alert("The user is not granted with required permission. Please try again later.");
      });
  }

  updateUserRole() {
    const updateUrl = `${this.state.addRoleUrl}\\${this.state.editor.docId}`;
    axios
      .put(updateUrl,{
        email: this.state.editor.email,
        page: this.state.pageName,
        role: this.state.editor.role,
        lastModifiedBy: this.props.usr.email
      }, {
        headers: {
          "x-access-token": this.props.token,
        }
      })
      .then((response) => {
        if(response.data.updated) {
          alert("The user is updated with required permission.");
          if(this.props.usr.email === this.state.editor.email){
            this.clearStateForForm();
            this.props.history.push('/pages/nreviews/ios/connectportal');
          }
          this.clearStateForForm();
          this.loadUser();
        } else {
          alert("The user is not updated with required permission. Please try again later.");
          this.clearStateForForm();
          this.loadUser();
        }   
      })
      .catch((error) => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          // console.log(error.response);
        } else if (error.request) {
          // client never received a response, or request never left
          // console.log(error.request);
        } else {
          // anything else
        }
        // console.log(error);
        alert("The user is not updated with required permission. Please try again later.");
      });
  }


  loadUser() {
    this.props.fetchUsersRoleRequest();
    
    axios
      .get(this.state.url, {
        headers: {
          "x-access-token": this.props.token,
        },
        params: {
          iam: this.props.usr.email || "",
          page: this.state.pageName,
        },
      })
      .then((response) => {
        const { roles } = response.data;
        this.props.fetchUsersRoleSuccess(roles);
        this.setState(prevState => ({
          ...prevState,
          errorAtLoading: false
        }))
      })
      .catch((error) => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          // console.log(error.response);
        } else if (error.request) {
          // client never received a response, or request never left
          // console.log(error.request);
        } else {
          // anything else
        }
        this.setState(prevState => ({
          ...prevState,
          errorAtLoading: true
        }))
        // alert("We are not able to populate a users. Please try again later.");
      });
  }

  formHandler(e) {
    e.preventDefault();
    const { email, role } = e.target;
    const isEmailValid = (email.value.includes('@ingka.com') || email.value.includes('@ingka.ikea.com'))
    const isRoleValid = (['admin','developer','user'].includes(role.value))
    if(isEmailValid && isRoleValid) {
      this.changeACL();
    } else {
      if(!isEmailValid) {
        alert("Kindly check email address which you are trying to a grant permission.")
      }
      if(!isRoleValid) {
        alert("Kindly check role which is associated with requester email's address.")
      }
    }
  }

  changeACL() {
    this.setState((prevState) => ({
      ...prevState,
      isSubmitted: true,
    }));
    if (this.state.mode === "edit") {
      // console.log(this.state.editor);
      this.updateUserRole();
    } else {
      this.addUser();
    }
    this.setState((prevState) => ({
      ...prevState,
      isSubmitted: false,
    }));
  }

  createUser = (object) => {
    // alert("create", object);
  };

  updateUser = (docId, object) => {
    this.setState((prevState) => ({
      ...prevState,
      editor: {
        ...prevState.editor,
        docId: docId,
        email: object.email,
      },
      mode: "edit",
    }));
  };

  searchHandler(event) {
    let val = event.target.value;
    this.setState({
      searchFilter: { searchText: val },
    });
  }

  deleteUserHandler(id) {
    axios
      .delete(`https://predictions.oi.ingka.com/api/v1/roles/${id}`, {
        headers: {
          "x-access-token": this.props.token,
        },
        params: {
          iam: this.props.usr.email || "",
          page: this.state.pageName,
        },
      })
      .then((response) => {
        if(response.data.includes("success")){
          alert("The user is deleted successfully.")
        } else {
          alert("The user is not deleted successfully.")
        }
        this.loadUser();
      })
      .catch((error) => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          // console.log(error.response);
        } else if (error.request) {
          // client never received a response, or request never left
          // console.log(error.request);
        } else {
          // anything else
        }
        alert("The user is not deleted successfully.")
      });
  }

  render() {
    let userRoles = this.props.users_and_role;
    userRoles = userRoles.filter((user) => user.page === this.state.pageName)
    userRoles = userRoles.filter(
      (user) =>
        user.email.includes(this.state.searchFilter.searchText) ||
        user.role.includes(this.state.searchFilter.searchText)
    );
    let reviewsCount = userRoles.length;
    var start =
      (this.state.reviewFilter.activePage - 1) *
      this.state.reviewFilter.noOfReviewsPerPage;
    var end =
      userRoles.length > this.state.reviewFilter.noOfReviewsPerPage
        ? this.state.reviewFilter.activePage *
          this.state.reviewFilter.noOfReviewsPerPage
        : userRoles.length;
    // console.log(
    //   start,
    //   `${this.state.reviewFilter.activePage - 1} * ${
    //     this.state.reviewFilter.noOfReviewsPerPage
    //   }`,
    //   end
    // );

    // split data based on selection of pagination
    userRoles = _.slice(userRoles, start, end);
    return (
      <div>
        <header>
          <div className="__flex_header_container">
            <div className="__flex_item __index">
              <div style={{ paddingLeft: "10px" }}>
                <sup style={{ top: "0.5em", color: "lightgreen" }}>iOS</sup>
                <h5>Developer Connect Portal</h5>
                <sub style={{ top: "-1.2em", right: "-13.2em" }}>
                  Settings Page
                </sub>
              </div>
            </div>
            <div className="__flex_item __index">
              <button
                style={{
                  background: "transparent",
                  borderColor: "transparent",
                  fontSize: "small",
                }}
                onClick={() => {
                  this.props.history.push('/pages/nreviews/ios/connectportal');
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  color="blue"
                ></FontAwesomeIcon>{" "}
                <span className="desktop">
                  Back to Developer Connect Portal
                </span>
                <span className="mobile">Back to Portal</span>
              </button>
            </div>
          </div>
        </header>
        <form onSubmit={this.formHandler}>
          <div className="__flex_settings_body_container">
            <div className="__flex_item __index">
              <small>
                <span className="mobile search-bar-label">To whom?</span>
                <input
                  type="email"
                  name="email"
                  placeholder="To whom's email address?"
                  className="form-control __user_email_box"
                  required={true}
                  value={
                    this.state.mode === "edit"
                      ? this.state.editor.email
                      : this.state.create.email
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    // console.log(value);
                    if (this.state.mode === "edit") {
                      this.setState((prevState) => ({
                        editor: {
                          ...prevState.editor,
                          email: value,
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        create: {
                          ...prevState.create,
                          email: value,
                        },
                      }));
                    }
                  }}
                ></input>
              </small>
            </div>
            &nbsp;
            <div className="__flex_item __index">
              <small>
                <Select
                ref={this.roleRef}
                  required={true}
                  placeholder="Roles"
                  options={this.state.roleOptions}
                  className="basic-multi-select search-bar"
                  classNamePrefix="select"
                  name="role"
                  
                  onChange={(event) => {
                    const { value } = event;
                    if (this.state.mode === "edit") {
                      this.setState((prevState) => ({
                        editor: {
                          ...prevState.editor,
                          role: value,
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        create: {
                          ...prevState.create,
                          role: value,
                        },
                      }));
                    }
                  }}
                />
              </small>
            </div>
            <div className="__flex_item __add_role __index">
              <button
                type="submit"
                disabled={this.state.isSubmitted}
                ref={this.submitRef}
                title={
                  this.state.mode === "edit"
                    ? "update user role"
                    : "add user role"
                }
                style={{
                  background: "transparent",
                  borderColor: "transparent",
                  fontSize: "small",
                }}
              >
                {this.state.mode === "edit" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faUserEdit}
                      color={this.state.isSubmitted ? "gray" : "orange"}
                    ></FontAwesomeIcon>
                    &nbsp; &nbsp; Update User &amp; Role
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      color={this.state.isSubmitted ? "gray" : "green"}
                    ></FontAwesomeIcon>
                    &nbsp; &nbsp; Add User &amp; Role
                  </>
                )}
              </button>
            </div>
            <div className="__flex_item __add_role __index">
              <button 
                type="button"
                disabled={this.state.isSubmitted}
                style={{
                  background: "transparent",
                  borderColor: "transparent",
                  fontSize: "small",
                }}
                onClick={()=>{
                this.clearStateForForm();
              }}>
                <FontAwesomeIcon icon={faUndoAlt}> </FontAwesomeIcon>&nbsp; Reset
              </button>
            </div>
          </div>
        </form>
        <div>
          <div className="__flex_settings_body_filter_container">
            <div className="__flex_item">
              <small>
                <span className="desktop">search user and role: </span>
                <span className="mobile search-bar-label">
                  search user and role
                </span>
                <input
                  type="text"
                  className="form-control search-bar"
                  name="search"
                  ref={this.searchRef}
                  onChange={this.searchHandler}
                />
              </small>
            </div>
            <div className="__flex_item __paginator_flex_item">
              {/* {(userRoles.length/this.state.reviewFilter.noOfReviewsPerPage)} */}
              {reviewsCount / this.state.reviewFilter.noOfReviewsPerPage >=
              1 ? (
                <Pagination
                  itemsCountPerPage={5}
                  itemClass="__paginator_item"
                  firstPageText={
                    <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>
                  }
                  prevPageText={
                    <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                  }
                  activePage={this.state.reviewFilter.activePage}
                  totalItemsCount={reviewsCount}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={this.state.reviewFilter.noOfReviewsPerPage}
                  onChange={(pageNumber) => {
                    this.setState((prevState) => ({
                      reviewFilter: {
                        ...prevState.reviewFilter,
                        activePage: pageNumber,
                      },
                    }));
                  }}
                  nextPageText={
                    <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                  }
                  lastPageText={
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                    ></FontAwesomeIcon>
                  }
                  activeLinkClass="__active_page_link"
                />
              ) : null}
            </div>
          </div>
        </div>
        <br />
        <div className="">
          <table className="table" style={{ fontSize: "small" }}>
            <thead>
              <tr>
                <td rowSpan="2">Email Address</td>
                <td colSpan="3" align="center">
                  Roles
                </td>
                <td rowSpan="2" align="center">
                  Actions
                </td>
              </tr>
              <tr>
                <td align="center">Admin</td>
                <td align="center">Developer</td>
                <td align="center">User</td>
              </tr>
            </thead>
            <tbody>
              {userRoles.length === 0 ? this.state.errorAtLoading ? (<tr>
                  <td colSpan="5" style={{ textAlign: "center", color: "red" }}>
                    {" "}
                    We are not able to load users and their roles.<br/>
                    Please try again after sometime.<br/><br/>

                    <span>if issue persist, kindly reach us over email.</span>
                  </td>
                </tr>) :(
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    {" "}
                    No user found.
                  </td>
                </tr>
              ) : null}
              {userRoles.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td align="center">
                      {user.role.includes("admin") ? (
                        <FontAwesomeIcon color="green" icon={faCheckCircle} />
                      ) : (
                        <FontAwesomeIcon color="red" icon={faTimesCircle} />
                      )}
                    </td>
                    <td align="center">
                      {user.role.includes("developer") ? (
                        <FontAwesomeIcon color="green" icon={faCheckCircle} />
                      ) : (
                        <FontAwesomeIcon color="red" icon={faTimesCircle} />
                      )}
                    </td>
                    <td align="center">
                      {user.role.includes("user") ? (
                        <FontAwesomeIcon color="green" icon={faCheckCircle} />
                      ) : (
                        <FontAwesomeIcon color="red" icon={faTimesCircle} />
                      )}
                    </td>
                    <td align="center">
                      <button
                        title="edit"
                        style={{
                          color: "gray",
                          background: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => {
                          let uid = user.id;
                          this.updateUser(uid, user);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                      </button>
                      <button
                        title="delete"
                        style={{
                          color: "red",
                          background: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => {
                          const id = user.id;
                          this.deleteUserHandler(id);
                        }}
                      >
                        <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="5">
                  Reviews :{" "}
                  {this.state.reviewFilter.activePage === 1
                    ? 0
                    : (this.state.reviewFilter.activePage - 1) *
                      this.state.reviewFilter.noOfReviewsPerPage}{" "}
                  -{" "}
                  {this.state.reviewFilter.activePage *
                    this.state.reviewFilter.noOfReviewsPerPage >
                  reviewsCount
                    ? reviewsCount
                    : this.state.reviewFilter.activePage *
                      this.state.reviewFilter.noOfReviewsPerPage}{" "}
                  /{reviewsCount} (5 per Page)
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.au.token,
    usr: state.usr.user,
    roles: state.at.roles,
    users_and_role: state.dcpsettings.users_and_role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersRoleRequest: () => {
      dispatch({ type: 'REQUEST_USERS_AND_ROLES'});
    },
    fetchUsersRoleSuccess: (success) => {
      dispatch(fetchRequestUserRoleSuccess(success));
    },
    fetchUserRoleFailed: (message) => {
      dispatch(fetchRequestUserRoleFailed(message));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IOSConnectPortalSettings));






// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
// import moment from "moment";
// import axios from "axios";
// import _ from "lodash";

// class IOSConnectPortalSettings extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       pageName: "ios_developer_connect_settings",
//     };
//   }

//   componentDidMount() {

//   }
  

//   render() {
//       return (
//           <>
//             Settings
//           </>
//       )
//   }
// }

// const mapStateToProps = (state) => ({
// });

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withWidth()(withRouter(IOSConnectPortalSettings)));