import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

import _ from "lodash";

import { Card, CardContent, Typography, Grid } from "@material-ui/core";

const CriticalincidentChart = ({ data, meantimeresolve, meantimeassign }) => {
  // console.log("props", data);
  // console.log("props", typeof data);
  // console.log("meantimeresolve",meantimeresolve.groupname)
  // console.log("meantimeassign",meantimeassign.groupname)

  if (
    Object.keys(data).length === 0 ||
    Object.keys(meantimeresolve).length === 0 ||
    Object.keys(meantimeassign).length === 0
  ) {
    return <div>Loading</div>;
  }
  const { CriticalIncidents } = data;
  const count = CriticalIncidents.reduce((a, b) => ({
    IncidentCount: a.IncidentCount + b.IncidentCount,
  }));
  const sortedIncidents = _.orderBy(CriticalIncidents, "Duration");
  // let t_meantimeresolve = '';
  // let t_meantimeassign = '';
  // let t_meantimeassign_days = '';
  // let t_meantimeassign_hours = '';
  // let t_meantimeassign_mins = '';
  // let t_meantimeassign_secs = '';

  // let t_meantimeresolve_days = '';
  // let t_meantimeresolve_hours = '';
  // let t_meantimeresolve_mins = '';
  // let t_meantimeresolve_secs = '';
  if (count.IncidentCount == 0) {
    meantimeresolve.groupname = "NA";
    meantimeassign.groupname = "NA";
    // t_meantimeassign='NA';
    // t_meantimeresolve='NA';
  }
  // console.log("Critical Incident Count :: ", )
  //   const {GroupName, callscount} = Groupwisecallscount;
  return (
    <>
      <Grid
        item
        xs
        style={{
          margin: "10px",
        }}
      >
        <Grid
          item
          component={Card}
          xs
          style={{
            borderBottom: `10px solid #E00751`,
            margin: "10px",
          }}
        >
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Total number of Critical Incidents
            </Typography>
            <Typography variant="h5">{count.IncidentCount}</Typography>
          </CardContent>
        </Grid>
        <Grid
          item
          component={Card}
          xs
          style={{
            borderBottom: `10px solid #bfbfbf`,
            margin: "10px",
          }}
        >
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              MTTA (Mean time to assign)
            </Typography>
            <Typography variant="h5">{_.replace(_.replace(_.replace(_.replace(meantimeassign.groupname,' H','h'),' D','d'),' M','m'),' S','s')}</Typography>
          </CardContent>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              MTTR (Mean time to resolve)
            </Typography>
            <Typography variant="h5">{_.replace(_.replace(_.replace(_.replace(meantimeresolve.groupname,' H','h'),' D','d'),' M','m'),' S','s')}</Typography>
          </CardContent>
        </Grid>
      </Grid>
      <Grid
        item
        xs
        style={{
          margin: "10px",
        }}
      >
        <BarChart
          width={700}
          height={350}
          data={sortedIncidents}
          style={{
            marginTop: "50px",
          }}
          barCategoryGap={"15%"}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Duration">
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle" }}
            />
          </XAxis>
          <YAxis />
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Tooltip />
          <Legend verticalAlign="top" height={40} />
          <Bar dataKey="IncidentCount" stackId="a" fill="#E00751" />
        </BarChart>
      </Grid>
    </>
  );
};

export default CriticalincidentChart;
