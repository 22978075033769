import React, { Component, forwardRef, Fragment } from "react";
import { connect } from "react-redux";

/** import material ui component */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

/** Timeline Component */
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
// import FastfoodIcon from "@material-ui/icons/Fastfood";
// import LaptopMacIcon from "@material-ui/icons/LaptopMac";
// import HotelIcon from "@material-ui/icons/Hotel";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RepeatIcon from "@material-ui/icons/Repeat";
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import Paper from "@material-ui/core/Paper";

/** import actions from redux */
import { closeCurrentConfigWindow } from "../../../../../../redux/shiftmanagement/actions";
import {
  Card,
  Container,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Label } from "@material-ui/icons";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide style={{zIndex: 101}} direction="up" ref={ref} {...props} />;
});

export class CurrentConfigWindow extends Component {
  closeWindow = () => {
    this.props.closeCurrentConfigWindow();
  };

  toCapitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    const classes = {
      appBar: {
        position: "relative",
      },
      title: {
        marginLeft: "2px",
        flex: 1,
      },
      paper: {
        padding: '6px 16px',
      }
    };
    return (
      <div>
        <Dialog
        // this.props.isopened ? {position:'fixed', zIndex: 9999} : { zIndex: 0}
          style={{zIndex:100}}
          fullScreen
          open={this.props.isopened}
          onClose={this.closeWindow}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeWindow}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h8" className={classes.title}>
                  <small>Calling Window Configuration</small>
                </Typography>
              </Toolbar>
            </AppBar>
          </DialogTitle>
          <DialogContent>
            <Grid container style={{ paddingTop: "5px", marginTop: '30px' }}>
              <Grid item xs={12}>
                <span style={{ color: "gray" }}>
                  The lifecylcle of selected calling window
                </span>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>Platform</span>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>
                      {" "}
                      :{" "}
                      {this.props.platforms
                        .filter(
                          (platform) => platform.name === this.props.platform
                        )
                        .map((o) => {
                          return <label key={o.name}>{o.fullName}</label>;
                        })}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>
                      {this.props.platform === "slack"
                        ? "Slack Channel Name"
                        : `${this.toCapitalize(
                            this.props.platform
                          )}\`s Assignment Group`}
                    </span>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>
                      : {this.props.platformSpecificGroupORChannel}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>Calling Window</span>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <span style={{ color: "gray" }}>
                      : {this.props.start.format("HH:mm")} -{" "}
                      {this.props.end.format("HH:mm")}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <h5 style={{ color: "gray", textAlign: "center" }}>
                  LifeCycle
                </h5>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Fragment>
                  {/* Timeline Start */}
                  {/* Primary Call Timeline - Start */}
                  <Timeline align="alternate">
                    {this.props.primarycontactnumber.length > 5 && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          0 min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot>
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Primary Call
                          </Typography>
                        <Typography>{this.props.primarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {this.props.isrpnenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {this.props.intervalp2rp} min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Primary Call (Retry)
                          </Typography>
                        <Typography>{this.props.primarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {this.props.isapnenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {this.props.intervalp2ap} min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h6">
                            Alternate Primary Call
                          </Typography>
                        <Typography>{this.props.alternateprimarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {/* Primary Call Timeline - End */}

                    {/* Secondary Call Timeline - Start */}
                    
                    {this.props.secondarycontactnumber.length > 5 &&  this.props.issecondaryenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                        {this.props.intervalp2s } min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot>
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Secondary Call
                          </Typography>
                        <Typography>{this.props.secondarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {this.props.isrsnenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                        {this.props.intervalp2s + this.props.intervals2rs} min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Secondary Call (Retry)
                          </Typography>
                        <Typography>{this.props.secondarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {this.props.isasnenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {this.props.intervalp2s + this.props.intervals2as} min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Alternate Secondary Call
                          </Typography>
                        <Typography>{this.props.alternatesecondarycontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }

                    {/* Secondary Call Timeline - End */}

                    {/* Escalation Call Timeline - Start */}
                    {this.props.escalationcontactnumber.length > 5 && this.props.isescalationenabled && <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                        {this.props.intervalp2s + this.props.intervals2e } min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot>
                          <PhoneInTalkIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            Escalation Call
                          </Typography>
                        <Typography>{this.props.escalationcontactnumber}</Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> }
                    {/* Escalation Call Timeline - End */}
                    { this.props.isopsbridgeenabled ? <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                        {(this.props.issecondaryenabled ? this.props.intervalp2s : 0) + (this.props.isescalationenabled ? this.props.intervals2e : 0) + 5 } min
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <NotificationsActiveIcon />
                        </TimelineDot>
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} style={{padding: '6px 16px'}}>
                          <Typography variant="h6" component="h1">
                            OpsBridge Notification
                          </Typography>
                          <Typography>
                            The opsbridge team will be notified on microsoft teams communication.
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem> : null
                  }

                  </Timeline>
                  {/* Timeline End */}
                </Fragment>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isopened: state.shmgmt.isenabledview,
  platform: state.shmgmt.selectedPlatformItem,
  platformSpecificGroupORChannel: state.shmgmt.assignmentGroup,
  platforms: state.shmgmt.platFormItems,
  start: state.shmgmt.startTime,
  end: state.shmgmt.endTime,
  isapnenabled: state.shmgmt.isapnenabled,
  isrpnenabled: state.shmgmt.isrpnenabled,
  isasnenabled: state.shmgmt.isasnenabled,
  isrsnenabled: state.shmgmt.isrsnenabled,
  issecondaryenabled: state.shmgmt.issecondaryenabled,
  isescalationenabled: state.shmgmt.isescalationenabled,
  primarycontactnumber: state.shmgmt.primarycontactnumber,
  alternateprimarycontactnumber: state.shmgmt.alternateprimarycontactnumber,
  secondarycontactnumber: state.shmgmt.secondarycontactnumber,
  alternatesecondarycontactnumber: state.shmgmt.alternatesecondarycontactnumber,
  escalationcontactnumber: state.shmgmt.escalationcontactnumber,
  intervalp2s: state.shmgmt.intervalp2s,
  intervals2e: state.shmgmt.intervals2e,
  intervalp2ap: state.shmgmt.intervalp2ap,
  intervals2as: state.shmgmt.intervals2as,
  intervalp2rp: state.shmgmt.intervalp2rp,
  intervals2rs: state.shmgmt.intervals2rs,
  isopsbridgeenabled: state.shmgmt.isopsbridgeenabled
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeCurrentConfigWindow: () => {
      dispatch(closeCurrentConfigWindow());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentConfigWindow);
