import {
    CREATE_FAILURE_SHMGMT_USER_PERMISSIONS,
    CREATE_REQUEST_SHMGMT_USER_PERMISSIONS,
    CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS,
    DELETE_FAILURE_SHMGMT_USER_PERMISSIONS,
    DELETE_REQUEST_SHMGMT_USER_PERMISSIONS,
    DELETE_SUCCESS_SHMGMT_USER_PERMISSIONS,
    LOAD_FAILURE_SHMGMT_USER_PERMISSIONS,
    LOAD_REQUEST_SHMGMT_USER_PERMISSIONS,
    LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS,
    UPDATE_FAILURE_SHMGMT_USER_PERMISSIONS,
    UPDATE_REQUEST_SHMGMT_USER_PERMISSIONS,
    UPDATE_SUCCESS_SHMGMT_USER_PERMISSIONS,

    LOAD_REQUEST_SHMGMT_OPERATORS,
    LOAD_SUCCESS_SHMGMT_OPERATORS,
    LOAD_FAILURE_SHMGMT_OPERATORS,

    CHANGE_MODE,
    SET_EDIT_MODE,
    RESET_EDIT_MODE
  } from "./types";

const initialState = {
    isSubmitting: false,
    loading: false,
    status: '',
    operators: [],
    mode: 'add',
    operator: {},
    roles: [],
    error: '',
    platform: '',
    group: '',
    role: '',
    email: '',
    id:''
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case SET_EDIT_MODE: {
            return {
                ...state,
                id: action.id,
                mode: 'edit',
                email: action.email,
                role: action.role
            }
        }
        case RESET_EDIT_MODE: {
            return {
                ...state,
                id: '',
                mode: 'add',
                email: '',
                role: ''
            }
        }
        case CHANGE_MODE: {
            return {
                ...state,
                mode: action.payload
            }
        }
        case LOAD_REQUEST_SHMGMT_OPERATORS:
            return {
                ...state,
                loading: true
            }
        case LOAD_SUCCESS_SHMGMT_OPERATORS:
            return {
                ...state,
                loading: false,
                operators: action.payload
            }
        case LOAD_FAILURE_SHMGMT_OPERATORS:
            return {
                ...state,
                loading: false
            }
        case CREATE_REQUEST_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                isSubmitting: true,
            }
        case CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                isSubmitting: false,
            }
        case CREATE_FAILURE_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                isSubmitting: false
            }
        case LOAD_REQUEST_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                status: action.status,
                error: ''
            }
        case LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                status: '',
                roles: action.roles,
                error: ''
            }
        case LOAD_FAILURE_SHMGMT_USER_PERMISSIONS:
            return {
                ...state,
                status: '',
                error: action.error
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;