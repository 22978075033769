import React, { Component } from "react";
import { connect } from "react-redux";

import { IconButton, TextField } from "@material-ui/core";
import { SaveAltRounded } from "@material-ui/icons";

import { updateWebhookUrl } from "../../../../../../redux/shiftmanagement/actions";

export class NotificationIncomingWebhook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: "",
      valid: false,
    };
  }

  urlValidation = (url) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  };

  componentDidMount() {
    if (this.props.webhookurl.toLowerCase().startsWith("http")) {
      if (this.urlValidation(this.props.webhookurl)) {
        this.setState((prevState) => {
          return {
            ...prevState,
            status: "success",
            valid: true,
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            ...prevState,
            status: "error",
            valid: false,
          };
        });
      }
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          status: "",
          valid: false,
        };
      });
    }
  }

  submitOnChange = (event) => {
    const { name, value } = event.target;
    // console.log("Notification Channel Webhook :: ", name, value);
    this.props.updateWebhookUrl(name, value);
  };

  render() {
    return (
      <div>
        <TextField
        //   error={this.state.status === "error"}
          id={`${this.props.name}-id`}
          label={`Enter the url of incoming webhook (${this.props.notification_platform_name})`}
          name={this.props.name}
          defaultValue={this.props.webhookurl}
          onChange={this.submitOnChange}
          size="small"
          fullWidth
          style={{
              minWidth: '500px'
          }}
        />
        {/* <IconButton color={this.state.valid ? "success" : "disabled"} disabled={this.state.valid === false} onClick={() => {
            console.log('save');
        }}>
            <SaveAltRounded />
        </IconButton> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    webhookurl: state.shmgmt[ownProps.name],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateWebhookUrl: (name, url) => {
      dispatch(updateWebhookUrl(name, url));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationIncomingWebhook);
