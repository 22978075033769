import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Badge from "react-bootstrap/Badge";
import CustomPaginator from "../../custom/CustomPaginator";
import actions from "../../../redux/developerconnectportal/android/actions";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import "./DeveloperConnectBody.css";
import Reply from "../../custom/modal/Reply";
import ToggleSwitch from "../../custom/ToggleSwitch";

class DeveloperConnectBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localRepliedState: false,
      pageName: 'android_developer_connect'
    };
    this.checkAll = createRef(null);
    this.toggleSwitchAction = this.toggleSwitchAction.bind(this);
  }

  toggleSwitchAction(event) {
    this.setState({
      localRepliedState: event.on,
    });
    this.props.resetPaginationPageToOne();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.localRepliedState !== this.state.localRepliedState) {
      if (this.state.localRepliedState) {
          const url = `https://predictions.oi.ingka.com/api/v1/reviews/replied/success/list`;
        this.props.negativeReviewRequest();
        axios
          .get(url, {
            headers: {
              "x-access-token": this.props.token,
            },
          })
          .then((response) => {
            const { records, count } = response.data;
            count > 0
              ? this.props.negativeReviewFetchSuccess(records)
              : this.props.negativeReviewFetchFailed("No records found");
          })
          .catch((error) => {
            this.props.negativeReviewFetchFailed(error.message);
          });

      } else {
        this.props.negativeReviewRequest();
        axios
          .get(`https://predictions.oi.ingka.com/api/v1/reviews/negative/list`, {
            headers: {
              "x-access-token": this.props.token,
            },
          })
          .then((response) => {
            const { records, count } = response.data;
            count > 0
              ? this.props.negativeReviewFetchSuccess(records)
              : this.props.negativeReviewFetchFailed("No records found");
          })
          .catch((error) => {
            this.props.negativeReviewFetchFailed(error.message);
            alert(error.message);
          });
      }
    }
  }

  componentDidMount() {
    this.props.negativeReviewRequest();
    axios
      .get(`https://predictions.oi.ingka.com/api/v1/reviews/negative/list`, {
        headers: {
          "x-access-token": this.props.token,
        },
      })
      .then((response) => {
        const { records, count } = response.data;
        count > 0
          ? this.props.negativeReviewFetchSuccess(records)
          : this.props.negativeReviewFetchFailed("No records found");
      })
      .catch((error) => {
        this.props.negativeReviewFetchFailed(error.message);
      });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: "1px dotted pink",
        color: state.isSelected ? "white" : "blue",
        fontSize: 12,
      }),
      placeholder: () => ({
        fontSize: "70%",
        innerText: "",
      }),
      singleValue: () => ({
        fontSize: "70%",
      }),
    };

    let reviews = this.props.reviews;
    let dateFilter = this.props.dateFilter;
    let classificationTags = this.props.classificationTags;
    let appVersionCodes = this.props.appVersionCodes;
    let countries = this.props.countries;
    reviews = _.filter(reviews, function (review) {
      return (
        moment(review.processedAt).unix() >=
          moment(dateFilter.startDate).unix() * 1000 &&
        moment(review.processedAt).unix() <=
          moment(dateFilter.endDate).unix() * 1000
      );
    });
    reviews = _.filter(reviews, function (review) {
      return classificationTags.length === 0
        ? true
        : _.intersection(
            _.map(classificationTags, "value"),
            review.classificationTag
          ).length > 0
        ? true
        : false;
    });
    reviews = _.filter(reviews, function (review) {
      return appVersionCodes.length === 0
        ? true
        : _.includes(_.map(appVersionCodes, "value"), _.trim(review.appVersionName,'IKEA-'))
        ? true
        : false;
    });
    reviews = _.filter(reviews, function (review) {
      return countries.length === 0
        ? true
        : _.includes(_.map(countries, "value"), review.countryCode)
        ? true
        : false;
    });

    // const uroles = _.map(this.props.roles, 'role');
    const uroles = _.map(
      _.filter(this.props.roles, (o) => o.page === this.state.pageName),
      "role"
    );

    return (
      <div>
        <div className="__flex_container_dcb">
          <div className="__flex_item_dcb">
            <div className="__developer_connect_body_header">
              <div style={{ paddingLeft: "10px" }}>
                <sup style={{ top: "0.5em", color: "lightgreen" }}>Reviews</sup>
                <h6>Classified</h6>
              </div>
            </div>
          </div>
          <div className="__flex_item_dcb" style={{ marginTop: 10 }}>
            <ToggleSwitch
              onText="&nbsp;responded"
              action={this.toggleSwitchAction}
              offText="non-responded&nbsp;"
            />
          </div>
          <div className="__flex_item_dcb paginator" style={{ marginTop: 10 }}>
            {reviews.length > this.props.reviewsPerPage.value ? (
              <CustomPaginator />
            ) : null}
          </div>
        </div>
        
        <table className="table review small screen">
          <thead>
            <tr>
              <td>
                <input
                  type="checkbox"
                  ref={this.checkAll}
                  checked={
                    this.props.cbStatus ||
                    this.props.srr.length === reviews.length
                  }
                  onChange={(event) => {
                    this.props.updateCbStatus(event.target.checked);
                    if (event.target.checked) {
                      this.props.updateSrr(reviews);
                    } else {
                      this.props.updateSrr([]);
                    }
                  }}
                ></input>{" "}
                &nbsp;
              </td>
              <td>
                <small>Review</small>
              </td>
              { (uroles.includes('admin') || uroles.includes('developer'))  ? <td>
                <small>Action</small>
              </td> : null }
            </tr>
          </thead>
          <tbody>
            
            {reviews.length > 0 ? (
              _.orderBy(reviews, (o) => moment(this.state.localRepliedState ? o.developerRepliedAt : o.processedAt).unix(), "desc")
                .slice(
                  (this.props.activePageNumber - 1) *
                    this.props.reviewsPerPage.value,
                  this.props.reviews.length > this.props.reviewsPerPage.value
                    ? this.props.activePageNumber *
                        this.props.reviewsPerPage.value
                    : this.props.reviews.length
                )
                .map((review, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            _.filter(this.props.srr, function (sr) {
                              return sr.reviewId === review.reviewId;
                            }).length > 0
                              ? true
                              : false
                          }
                          onChange={(event) => {
                            const temp = [].concat(this.props.srr);
                            if (event.target.checked) {
                              temp.push(review);
                              this.props.updateSrr(temp);
                            } else {
                              this.props.updateCbStatus(event.target.checked);
                              this.props.updateSrr(
                                _.filter(
                                  temp,
                                  (i) => i.reviewId !== review.reviewId
                                )
                              );
                            }
                          }}
                        ></input>{" "}
                        &nbsp;
                      </td>
                      <td>
                        {/* Review Body */}
                        <div>
                          <table className="table-review-content">
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>Review Date</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {moment(review.processedAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>App Name</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {review.appVersionName
                                      ? review.appVersionName
                                      : "None"}
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>Tags</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  {_.map(
                                    review.classificationTag,
                                    (tag, idx) => (
                                      <Badge
                                        key={`${review.reviewId}-index${idx}`}
                                        pill
                                        variant="primary"
                                      >
                                        {tag}
                                      </Badge>
                                    )
                                  ).map((element) => element)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>Country</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {review.countryCode
                                      ? review.countryCode
                                      : "None"}
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>User Rating</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {review.starRating
                                      ? review.starRating
                                      : "None"}
                                  </small>
                                </td>
                              </tr>
                              {/* <tr>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>Sentiment Rating</b>
                                  </small>
                                </td>
                                <td
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {review.sentimentScore
                                      ? review.sentimentScore
                                      : "None"}
                                  </small>
                                </td>
                              </tr>  <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>User Review</b>
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small style={{ textIndent: "4px" }}>
                                    {review.userFeedback
                                      ? review.userFeedback
                                      : "None"}
                                  </small>
                                </td>
                              </tr> */}
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    <b>User Review (EN)</b>
                                  </small>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    padding: "0 10px",
                                    margin: 0,
                                    textAlign: "left",
                                  }}
                                >
                                  <small>
                                    {review.translatedUserFeedback
                                      ? review.translatedUserFeedback
                                      : "None"}
                                  </small>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      { (uroles.includes('admin') || uroles.includes('developer'))  ? <td>
                        {/* Reply button */}
                        <small>
                          <Reply review={review} />
                        </small>
                      </td> : null }
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="3" style={{textAlign: 'center'}}>{this.props.loading ? <small>fetching reviews...</small> : <small>No reviews found</small> }</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">
                <div
                  className="__flex __footer"
                  style={{ alighItems: "center" }}
                >
                  <div className="__item">
                    <div style={{ display: "inline-block" }}>
                      <small style={{ display: "flex", alignItems: "center" }}>
                        Reviews :{" "}
                        {(this.props.activePageNumber - 1) *
                          this.props.reviewsPerPage.value}
                        -
                        {reviews.length > this.props.reviewsPerPage.value
                          ? this.props.activePageNumber *
                              this.props.reviewsPerPage.value >
                            reviews.length
                            ? reviews.length
                            : this.props.activePageNumber *
                              this.props.reviewsPerPage.value
                          : reviews.length}
                        /{reviews.length}&nbsp;&nbsp;&nbsp;{" "}
                        <Select
                          styles={customStyles}
                          placeholder={this.props.reviewsPerPage.value}
                          defaultValue={this.props.reviewsPerPage.value}
                          options={[
                            { value: 5, label: "5" },
                            { value: 10, label: "10" },
                            { value: 25, label: "25" },
                            { value: 50, label: "50" },
                            { value: 100, label: "100" },
                            { value: 250, label: "250" },
                          ]}
                          onChange={this.props.setReviewsPerPage}
                        ></Select>{" "}
                        &nbsp; per Page
                      </small>
                    </div>
                  </div>
                  <div
                    className="__item"
                    style={{ padding: 0, marginTop: "20px" }}
                  >
                    {reviews.length > this.props.reviewsPerPage.value ? (
                      <CustomPaginator />
                    ) : null}
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="__flex_container_dcb">
          <div className="__flex_item_dcb">
            <div className="__developer_connect_body">
              <table className="table review large screen">
                <thead>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        ref={this.checkAll}
                        checked={
                          this.props.cbStatus ||
                          this.props.srr.length === reviews.length
                        }
                        onChange={(event) => {
                          this.props.updateCbStatus(event.target.checked);
                          if (event.target.checked) {
                            this.props.updateSrr(reviews);
                          } else {
                            this.props.updateSrr([]);
                          }
                        }}
                      ></input>{" "}
                      &nbsp;
                    </td>
                    <td>
                      <small>Review Date</small>
                    </td>
                    {
                      this.state.localRepliedState ? <td><small>Responded On</small></td> : null
                    }
                    <td>
                      <small>App Version Name</small>
                    </td>
                    <td>
                      <small>Classification Tag</small>
                    </td>
                    <td>
                      <small>Country</small>
                    </td>
                    <td>
                      <small>User Rating</small>
                    </td>
                    {/* <td>
                      <small>Sentiment Score</small>
                    </td>
                    <td>
                      <small>User Review</small>
                    </td> */}
                    <td>
                      <small>User Review (EN)</small>
                    </td>
                    {
                      this.state.localRepliedState ? <td><small>Developer Comment</small></td> : null
                    }
                    { (uroles.includes('admin') || uroles.includes('developer'))  ? <td>
                      <small>Actions</small>
                  </td> : null }
                  </tr>
                </thead>
                <tbody>
                  {reviews.length > 0 ? (
                    _.orderBy(
                      reviews,
                      (o) => moment(this.state.localRepliedState ? o.developerRepliedAt : o.processedAt).unix(),
                      "desc"
                    )
                      .slice(
                        (this.props.activePageNumber - 1) *
                          this.props.reviewsPerPage.value,
                        this.props.reviews.length >
                          this.props.reviewsPerPage.value
                          ? this.props.activePageNumber *
                              this.props.reviewsPerPage.value
                          : this.props.reviews.length
                      )
                      .map((review, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  this.props.srr.filter((sr) => {
                                    return sr.reviewId === review.reviewId;
                                  }).length > 0
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  const temp = [].concat(this.props.srr);
                                  if (event.target.checked) {
                                    temp.push(review);
                                    this.props.updateSrr(temp);
                                  } else {
                                    this.props.updateCbStatus(
                                      event.target.checked
                                    );
                                    this.props.updateSrr(
                                      _.filter(
                                        temp,
                                        (i) => i.reviewId !== review.reviewId
                                      )
                                    );
                                  }
                                }}
                              ></input>{" "}
                              &nbsp;
                            </td>
                            <td>
                              <small>
                                {moment(review.processedAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </small>
                            </td>
                            {this.state.localRepliedState ? <td>
                              <small>
                                {moment.unix(review.developerRepliedAt).format(
                                  "MMM DD, YYYY"
                                )}
                              </small>
                                </td> : null}
                            <td>
                              <small>
                                {review.appVersionName
                                  ? review.appVersionName
                                  : "None"}
                              </small>
                            </td>
                            <td>
                              {_.map(review.classificationTag, (tag, idx) => (
                                <Badge
                                  key={`${review.reviewId}-index${idx}`}
                                  pill
                                  variant="primary"
                                >
                                  {tag}
                                </Badge>
                              )).map((element) => element)}
                            </td>
                            <td>
                              <small>{review.countryCode}</small>
                            </td>
                            <td>
                              <small>{review.starRating}</small>
                            </td>
                            {/* <td>
                              <small>{review.sentimentScore}</small>
                            </td>

                            <td>
                              <small>{review.userFeedback}</small>
                            </td> */}
                            <td>
                              <small>{review.translatedUserFeedback}</small>
                            </td>
                            {
                      this.state.localRepliedState ? <td><small>{review.comment}</small></td> : null
                    }
                            { (uroles.includes('admin') || uroles.includes('developer'))  ? <td>
                              <small>
                                <Reply review={review} />
                              </small>
                            </td> : null }
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={this.state.localRepliedState ? 12 : 10} style={{ textAlign: "center" }}>
                        {" "}
                        {this.props.loading ? <small>fetching reviews...</small> : <small>No reviews found</small> }
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={this.state.localRepliedState ? 12 : 10}>
                      <div className="__flex __footer">
                        <div className="__item">
                          <div style={{ display: "inline-block" }}>
                            <small
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Reviews :{" "}
                              {(this.props.activePageNumber - 1) *
                                this.props.reviewsPerPage.value}
                              -
                              {reviews.length > this.props.reviewsPerPage.value
                                ? this.props.activePageNumber *
                                    this.props.reviewsPerPage.value >
                                  reviews.length
                                  ? reviews.length
                                  : this.props.activePageNumber *
                                    this.props.reviewsPerPage.value
                                : reviews.length}
                              /{reviews.length}&nbsp;&nbsp;&nbsp;{" "}
                              <Select
                                styles={customStyles}
                                placeholder={this.props.reviewsPerPage.value}
                                defaultValue={this.props.reviewsPerPage.value}
                                options={[
                                  { value: 5, label: "5" },
                                  { value: 10, label: "10" },
                                  { value: 25, label: "25" },
                                  { value: 50, label: "50" },
                                  { value: 100, label: "100" },
                                  { value: 250, label: "250" },
                                ]}
                                onChange={this.props.setReviewsPerPage}
                              ></Select>{" "}
                              &nbsp; per Page
                            </small>
                          </div>
                        </div>
                        <div className="__item">
                          {reviews.length > this.props.reviewsPerPage.value ? (
                            <CustomPaginator />
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingStatus: state.android.loading,
    reviewsPerPage: state.android.reviewsPerPage,
    reviews: state.android.reviews,
    activePageNumber: state.android.paginator.activePage,
    dateFilter: state.android.dateFilter,
    classificationTags: state.android.classificationTags,
    appVersionCodes: state.android.selectedVersionCodes,
    countries: state.android.selectedCountries,
    srr: state.android.selectedReviewsForReply,
    cbStatus: state.android.checkedAll,
    token: state.au.token,
    roles: state.at.roles
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReviewsPerPage: (number) => {
      dispatch(actions.setReviewsPerPage(number));
    },
    negativeReviewRequest: () => {
      dispatch({
        type: 'NEGATIVE_REVIEW_REQUEST'
      });
    },
    negativeReviewFetchSuccess: (robjects) => {
      dispatch(actions.negativeReviewSuccess(robjects));
    },
    negativeReviewFetchFailed: (eobject) => {
      dispatch(actions.negativeReviewFailed(eobject));
    },
    updateSrr: (reviews) => {
      dispatch(actions.updateReviewReplyList(reviews));
    },
    updateCbStatus: (checked) => {
      dispatch(actions.updateCheckAllStatus(checked));
    },
    resetPaginationPageToOne: () => {
      dispatch(actions.setActivePage({activePage:1}))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeveloperConnectBody);
