import {
  ADD_USERS_AND_ROLES_FAILED,
  ADD_USERS_AND_ROLES_SUCCESS,
  FETCH_USERS_AND_ROLE_FAILED,
  FETCH_USERS_AND_ROLE_SUCCESS,
  REQUEST_ADD_USERS_AND_ROLES,
  REQUEST_UPDATE_USERS_AND_ROLES,
  REQUEST_USERS_AND_ROLES,
  UPDATE_USERS_AND_ROLES_FAILED,
  UPDATE_USERS_AND_ROLES_SUCCESS,
} from "./types.js";


const fetchRequestUserRoles = () => {
    return {
        type: REQUEST_USERS_AND_ROLES,
        payload: ''
    };
}

const fetchRequestUserRoleSuccess = (success) =>{

    return {
        type: FETCH_USERS_AND_ROLE_SUCCESS,
        payload: success
    }
}

const fetchRequestUserRoleFailed = (failed) =>{

    return {
        type: FETCH_USERS_AND_ROLE_FAILED,
        payload: failed
    }
}

export {
    fetchRequestUserRoles,
    fetchRequestUserRoleSuccess,
    fetchRequestUserRoleFailed
 }