import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Frame } from "framer";
import { positions } from "@material-ui/system";

/** material ui components */
import VisibilityRounded from "@material-ui/icons/VisibilityRounded";
import { DashboardRounded } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import CurrentConfigWindow from "./CurrentConfigWindow";

/** import redux components */
import { openCurrentConfigWindow, openReportWindow } from "../../../../../../redux/shiftmanagement/actions";
import { DashboardSharp } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import ReportWindow from "./ReportWindow";

export class ViewIconButton extends Component {
  openConfigWindow = () => {
    this.props.openCurrentConfigWindow();
  };

  openReportWindow = () => {
    // alert("Report Window");
    this.props.openReportWindow();
  }

  render() {
    return (
      <div>
        <Frame
          style={{
            zIndex: 150,
            background: "transparent",
            position: "fixed",
            right: 0,
            top: "30%",
            // padding: '5px',
            width: 40,
            height: 40,
          }}
        >
          
          <Tooltip title="LifeCycle" placement="left">
          <IconButton onClick={this.openConfigWindow} size="small">
            <VisibilityRounded />
          </IconButton>
          </Tooltip>
          <Tooltip title="Dashboard" placement="left">
            <IconButton size="small" onClick={this.openReportWindow}>
              <DashboardRounded />
            </IconButton>
          </Tooltip>
          <ReportWindow />
          <CurrentConfigWindow />
        </Frame>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    openCurrentConfigWindow: () => {
      dispatch(openCurrentConfigWindow());
    },
    openReportWindow: () => {
      dispatch(openReportWindow())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewIconButton);
