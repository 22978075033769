import axios from "axios";

const url = "https://acnreport.azurewebsites.net/api";
export const fetch_meantimeassign = async (period, group) => {
  try {
  let periodParam='';
  if(period==="24 Hour")
  {
    periodParam="hour"
  }
  else if(period==="day=7")
  {
    periodParam="days"
  }
  else if(period==="day=30")
  {
    periodParam="month"
  }
  else
  {
    periodParam="Quarter"
  }
  // console.log(periodParam);
	const url ="http://localhost:8080/meantimetoassign";
	let body = {"group": group,"duration":periodParam}
    const { data } = await axios.post(url,body);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetch_meantimeresolve = async (period, group) => {
  try {
  let periodParam='';
  if(period==="24 Hour")
  {
    periodParam="hour"
  }
  else if(period==="day=7")
  {
    periodParam="days"
  }
  else if(period==="day=30")
  {
    periodParam="month"
  }
  else
  {
    periodParam="Quarter"
  }
	// console.log(periodParam);
	const url ="http://localhost:8080/meantimetoresolve";
	let body = {"group": group,"duration":periodParam}
    const { data } = await axios.post(url,body);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetch_criticalincident = async (period, group) => {
  try {
    let periodParam = period;
    const { data } = await axios.get(
      `${url}/totalcriticalincidents?code=JdtXiUOx7Ok52AzVr5wuDIOaDDH3aj8CiRqARw8gFRtsjM3GR13Crg==&${periodParam}&group='${group}'`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetch_psecallscount = async (period, group) => {
  try {
    let periodParam = period;
    const { data } = await axios.get(
      `${url}/totalpsecallscount?code=FV8jB2zB0xXUQNGA1x3w3NXTB3wtQPC9JfKoZg/Fciqk3MluY4RnIg==&${periodParam}&group='${group}'`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetch_psecallstatus = async (period, group) => {
  try {
    let periodParam = period;
    const { data } = await axios.get(
      `${url}/totalpsecallstatus?code=m2NM5Zg8S4XraBYrmhSl36ItFwvpUqjL4zaWjJbBqmntsCNlxWSq2w==&${periodParam}&group='${group}'`
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetch_reports = async (period, group) => {
  let periodParam = period;
  const totalpsecallstatus_url = axios.get(
    `${url}/totalpsecallstatus?code=m2NM5Zg8S4XraBYrmhSl36ItFwvpUqjL4zaWjJbBqmntsCNlxWSq2w==&${periodParam}&group='${group}'`
  );
  const totalpsecallscount_url = axios.get(
    `${url}/totalpsecallscount?code=FV8jB2zB0xXUQNGA1x3w3NXTB3wtQPC9JfKoZg/Fciqk3MluY4RnIg==&${periodParam}&group='${group}'`
  );
  const totalcriticalincident_url = axios.get(
    `${url}/totalcriticalincidents?code=JdtXiUOx7Ok52AzVr5wuDIOaDDH3aj8CiRqARw8gFRtsjM3GR13Crg==&${periodParam}&group='${group}'`
  );
  const response = {
    success: false,
    datas: {
      data: {},
      data2: {},
      data3: {},
    },
  };
  return axios
    .all([
      totalpsecallscount_url,
      totalpsecallstatus_url,
      totalcriticalincident_url,
    ])
    .then(
      axios.spread((...responses) => {
        const datas = { 
            data: responses[0].data,
            data2: responses[1].data,
            data3: responses[2].data,
          }
        return {
          success: true,
          datas: {...datas}
        };
      })
    )
    .catch((errors) => {
      console.log(errors, "it didnt work.");
      return response;
    });
};
