import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import _ from 'lodash';

const Cards = ({ data }) => {
  if (Object.keys(data).length === 0) {
    return <div>Loading</div>;
  }

  const { Totalcalls, Durationwisecallscount } = data;

 

  const totalprimarycalls = _.sum(_.map(Durationwisecallscount, 'PrimarySPOC')); 
  const totalsecondarycalls = _.sum(_.map(Durationwisecallscount, 'SecondarySPOC'));
  const totalescalationcalls = _.sum(_.map(Durationwisecallscount, 'EscalationSPOC'));


  const fetchedData = [
    {
      text: "Total number of calls",
      color: "#bfbfbf",
      count: Totalcalls.EscalationSPOC +
        Totalcalls.PrimarySPOC +
        Totalcalls.SecondarySPOC
    },
    {
      text: "Primary calls",
      color: "#0058A3",
      count: Totalcalls.PrimarySPOC,
    },
    {
      text: "Secondary calls",
      color: "#CA5008",
      count: Totalcalls.SecondarySPOC,
    },
    {
      text: "Escalation calls",
      color: "#FFA524",
      count: Totalcalls.EscalationSPOC,
    },
  ];

  return (
     <>
      {fetchedData.map(({ text, count, color }, i) => (
        <Grid
          key={i}
          item
          component={Card}
          xs
          style={{
            borderBottom: `10px solid ${color}`,
            margin: "10px",
            minHeight: "160px"
          }}
        >
          <CardContent>
            <Typography color='textSecondary' gutterBottom>
              {text}
            </Typography>
            <Typography variant='h5'>{count}</Typography>
          </CardContent>
        </Grid>
      ))}
    </>
  );
};

export default Cards;
