import {
  NEGATIVE_REVIEW_REQUEST,
  NEGATIVE_REVIEW_SUCCESS,
  NEGATIVE_REVIEW_FAILED,
  DATE_FILTER_UPDATE_FAILED,
  DATE_FILTER_UPDATE_REQUEST,
  DATE_FILTER_UPDATE_SUCCESS,
  CLEAR_FILTER,
  SET_ACTIVE_PAGE_PAGINATOR,
  SET_REVIEWS_PER_PAGE,
  SET_SELECTED_CLASSIFIATION_TAG,
  SET_SELECTED_APP_VERSION_CODES,
  SET_SELECTED_COUNTRIES,
  SET_SELECTED_USERRATINGS,
  SET_CLEAR_APPVERSION_FLAG,
  SET_CLEAR_COUNTRIES_FLAG,
  SET_CLEAR_TAG_FLAG,
  UNSET_CLEAR_APPVERSION_FLAG,
  UNSET_CLEAR_COUNTRIES_FLAG,
  UNSET_CLEAR_TAG_FLAG,
  UPDATE_REVIEW_REPLY_LIST,
  UPDATE_CHECKALL_STATUS
} from "./types";
import moment from "moment";

const initialState = {
  loading: false,
  tableDataLoading: false,
  reviews: [{}, {}],
  selectedReviewsForReply: [],
  selectedUserRatings: [],
  selectedCountries: [],
  classificationTags: [],
  selectedVersionCodes: [],
  checkedAll: false,
  clearCountries: false,
  clearTags: false,
  clearAppVersions: false,
  dateFilter: {
    startDate: moment().subtract(3, "months").unix(),
    endDate: moment().unix(),
  },
  paginator: {
    activePage: 1,
  },
  reviewsPerPage: { value: 5, label: "5" },
  error: false,
  errorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEGATIVE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        tableDataLoading: true,
        reviews: [],
        selectedReviewsForReply: [],
        checkedAll:false,
        error: false,
        errorMessage: ""
      };
    case NEGATIVE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
        selectedReviewsForReply: [],
        checkedAll:false,
        loading: false,
        tableDataLoading: false,
        error: false,
        errorMessage: ""
      };
    case NEGATIVE_REVIEW_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        tableDataLoading: false,
        error: true,
      };
    case DATE_FILTER_UPDATE_FAILED:
      return {
        ...state,
      };
    case DATE_FILTER_UPDATE_SUCCESS:
      return {
        ...state,
        dateFilter: action.payload,
        selectedReviewsForReply: [],
        checkedAll:false
      };
    case DATE_FILTER_UPDATE_REQUEST:
      return {
        ...state,
      };
    case CLEAR_FILTER:
      return {
        ...state,
        dateFilter: action.payload,
        selectedUserRatings: [],
        selectedCountries: [],
        classificationTags: [],
        selectedVersionCodes: [],
        selectedReviewsForReply: [],
        checkedAll:false,
        clearCountries: true,
        clearTags: true,
        clearAppVersions: true
      };
    case SET_ACTIVE_PAGE_PAGINATOR:
      return {
        ...state,
        paginator: action.payload,
      };
    case SET_REVIEWS_PER_PAGE:
      return {
        ...state,
        reviewsPerPage: action.payload,
        paginator: {
          activePage: 1,
        }
      };
    case SET_SELECTED_CLASSIFIATION_TAG:
      return {
        ...state,
        classificationTags: action.payload,
        selectedReviewsForReply: [],
        paginator: {
          activePage: 1
        },
        checkedAll:false
      };
    case SET_SELECTED_APP_VERSION_CODES:
      return {
        ...state,
        selectedVersionCodes: action.payload,
        paginator: {
          activePage: 1
        },
        selectedReviewsForReply: [],
        checkedAll:false
      };
    case SET_SELECTED_COUNTRIES:
      return {
        ...state,
        selectedCountries: action.payload,
        selectedReviewsForReply: [],
        paginator: {
          activePage: 1
        },
        checkedAll:false
      };
    case SET_SELECTED_USERRATINGS:
      return {
        ...state,
        selectedUserReviews: action.payload,
        checkedAll: false
      };
    case SET_CLEAR_APPVERSION_FLAG:
      return {
        ...state,
        clearAppVersions: true
      };
    case SET_CLEAR_COUNTRIES_FLAG:
      return {
        ...state,
        clearCountries: true
      };
    case SET_CLEAR_TAG_FLAG:
      return {
        ...state,
        clearTags: true
      };
    case UNSET_CLEAR_APPVERSION_FLAG:
      return {
        ...state,
        clearAppVersions: false
      };
    case UNSET_CLEAR_COUNTRIES_FLAG:
      return {
        ...state,
        clearCountries: false
      };
    case UNSET_CLEAR_TAG_FLAG:
      return {
        ...state,
        clearTags: false
      };
    case UPDATE_REVIEW_REPLY_LIST:
      return {
        ...state,
        selectedReviewsForReply: action.payload
      };
    case UPDATE_CHECKALL_STATUS:
      return {
        ...state,
        checkedAll: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
