import React from "react";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import actions from '../../redux/developerconnectportal/android/actions'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight,faAngleLeft,faAngleRight } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.css";
import './CustomPaginator.css';

 
const CustomPaginator = (props) => {
  let totalItems = props.totalItems;
  totalItems = _.filter(totalItems, function(review) { return (moment(review.processedAt).unix() >= (moment(props.dateFilter.startDate).unix()* 1000) && moment(review.processedAt).unix() <= (moment(props.dateFilter.endDate).unix() * 1000)) })
  totalItems = _.filter(totalItems, function(review) { return (props.classificationTags.length === 0 ? true : (_.intersection(_.map(props.classificationTags,'value'), review.classificationTag)).length > 0 ? true : false) })
  totalItems = _.filter(totalItems, function(review) { return (props.appVersionCodes.length === 0 ? true : ((_.includes(_.map(props.appVersionCodes,'value'), review.appVersionCode)) ? true : false))})
  totalItems = _.filter(totalItems, function(review) { return (props.countries.length === 0 ? true : ((_.includes(_.map(props.countries,'value'), review.countryCode)) ? true : false))})
    return (
      <div>
        <Pagination
          itemClass='__paginator_item'
          firstPageText={(<FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>)}
          prevPageText={(<FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>)}
          activePage={props.activePage}
          itemsCountPerPage={props.itemsPerPage}
          totalItemsCount={totalItems.length}
          pageRangeDisplayed={5}
          onChange={(pageNumber) => { 
            props.setActivePage({activePage: pageNumber});
          }}
          nextPageText={(<FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>)}
          lastPageText={(<FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon>)}
          activeLinkClass='__active_page_link'
        />
      </div>
    );
}

const mapStatsToProps = (state) => {
    return {
        activePage: state.android.paginator.activePage,
        itemsPerPage: state.android.reviewsPerPage.value,
        totalItems: state.android.reviews,
        dateFilter: state.android.dateFilter,
        classificationTags: state.android.classificationTags,
        appVersionCodes: state.android.selectedVersionCodes,
        countries : state.android.selectedCountries
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActivePage: (paginatorPageSettings) => {
            // console.log(paginatorPageSettings);
            dispatch(actions.setActivePage(paginatorPageSettings))
        }
    }
}
 
export default connect(mapStatsToProps, mapDispatchToProps)(CustomPaginator);