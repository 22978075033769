import React from "react";
import "./loader.css";

const Loaders = (props) => {
  return (
    <>
    <div style={{ display: "inline-flex", float: 'right'}}>
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
      <small>&nbsp;{props.message ? ' '  + props.message : ''}</small>
      </div>
    </>
  );
};

export default Loaders;
