import React, { Component } from "react";
import "./toggle-switch.css";

export class ToggleSwitch extends Component {

  constructor(props) {
      super(props);
      this.state = {
            on: false
      };
      
  }

  render() {
    return (
      <>
        <div style={{ padding: '10px', display: 'flex', alignItems: "center", zIndex:1}}>
        <small>{this.props.offText ? this.props.offText : 'Actual'}&nbsp;</small> 
        <button
          type="button"
          className={`btn btn-xs btn-secondary btn-toggle ${this.state.on ? 'active' : null}`}
          onClick={() => {

              this.props.action({on:!this.state.on})
              this.setState({
                on:!this.state.on
              });
          }}
        >
          <div className="handle"></div>
        </button>
        <small>&nbsp;{this.props.onText ? this.props.onText : 'English'}</small>
        </div>
      </>
    );
  }
}

export default ToggleSwitch;
