import React, { Component } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

/** material ui components */
import Container from "@material-ui/core/Container";
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

/** import actions */
import {
  updateContact,
  updateFeaturesEnabled,
  updateInterval,
  updateFormValidation,
} from "../../../../../../redux/shiftmanagement/actions";

/** import css */
import "./Settings.css";
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";

export class Settings extends Component {
  validate = () => {
    const formproperty = "isform4valid";
    const {
      secondarycontactnumber,
      alternatesecondarycontactnumber,
      isasnenabled,
      isrsnenabled,
      isescalationenabled,
      intervals2e,
      intervals2rs,
      intervals2as,
    } = this.props;

    const pc1 = _.size(_.trim(secondarycontactnumber)) > 8;
    const pc2 = _.size(_.trim(alternatesecondarycontactnumber)) > 8;
    // only secondary
    const condition1 =
      !isasnenabled && !isrsnenabled && !isescalationenabled && pc1;
    // only secondary and retry
    const condition2 =
      !isescalationenabled &&
      !isasnenabled &&
      isrsnenabled &&
      intervals2rs > 1 &&
      pc1;
    // only secondary and alternate
    const condition3 =
      isasnenabled &&
      intervals2as > 1 &&
      pc2 &&
      !isrsnenabled &&
      !isescalationenabled &&
      pc1;
    // only secondary, retry and alternate
    const condition4 =
      !isescalationenabled &&
      isasnenabled &&
      intervals2as > intervals2rs &&
      pc2 &&
      isrsnenabled &&
      intervals2rs > 1 &&
      pc1;
    // all enabled secondary, retry, alternate and escalation
    const condition5 =
      isescalationenabled &&
      intervals2e > intervals2as &&
      isasnenabled &&
      intervals2as > intervals2rs &&
      pc2 &&
      isrsnenabled &&
      intervals2rs > 1 &&
      pc1;
    // only secondary and escalation
    const condition6 =
      isescalationenabled &&
      intervals2e > 1 &&
      !isasnenabled &&
      !isrsnenabled &&
      pc1;
    // only secondary, alternate and escalation
    const condition7 =
      pc1 &&
      !isrsnenabled &&
      isasnenabled &&
      intervals2as > 1 &&
      pc2 &&
      isescalationenabled &&
      intervals2e > intervals2as;
    // only secondary, retry and escalation
    const condition8 =
      pc1 &&
      isrsnenabled &&
      intervals2rs > 1 &&
      !isasnenabled &&
      isescalationenabled &&
      intervals2e > intervals2rs;
    const valid =
      condition1 ||
      condition2 ||
      condition3 ||
      condition4 ||
      condition5 ||
      condition6 ||
      condition7 ||
      condition8;
    this.props.updateFormValidation({ name: formproperty, value: valid });
    if (!isescalationenabled) {
      this.props.updateFormValidation({ name: "isform5valid", value: true });
    } else {
      this.props.updateFormValidation({ name: "isform5valid", value: false });
    }
  };

  onChangeCheckState = (event) => {
    const { name, checked } = event.target;
    if (name === "isescalationenabled") {
      if (!checked) {
        this.props.updateFormValidation({ name: "isform5valid", value: true });
      } else {
        this.props.updateFormValidation({ name: "isform5valid", value: false });
      }
    }
    this.props.updateFeaturesEnabled(name, checked);
  };

  onUpdateContact = (name, value) => {
    // value = value.match(/[0-9]/g);
    this.props.updateContact(name, value);
  };

  onUpdateInterval = (event) => {
    const { name, value } = event.target;
    this.props.updateInterval(name, parseInt(value));
  };

  componentDidMount() {
    this.validate();
  }

  render() {
    const variants = {
      show: { opacity: 1 },
      hide: { opacity: 0 },
    };
    this.validate();
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container className="container" maxWidth={false}>
            <div className="flex-container">
              <div className="item">
                <span style={{ color: "gray" }}>
                  Secondary contact number <sup>*</sup>
                </span>
                <MuiPhoneNumber
                  style={{
                    width: "300px",
                  }}
                  name="secondarycontactnumber"
                  value={this.props.secondarycontactnumber}
                  onChange={(value) => {
                    this.onUpdateContact("secondarycontactnumber", value);
                  }}
                  defaultCountry="se"
                  countryCodeEditable={true}
                />
              </div>
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isrsnenabled}
                      name="isrsnenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable retry option"
                  labelPlacement="end"
                />
              </div>
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isasnenabled}
                      name="isasnenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable alternate number"
                  labelPlacement="end"
                />
              </div>
            </div>
            <AnimatePresence>
              {this.props.isrsnenabled && (
                <motion.div
                  className="flex-container"
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "tween",
                    duration: 1,
                    delay: 0.2,
                  }}
                  exit={{
                    x: 300,
                    opacity: 0,
                  }}
                >
                  <FormControl>
                    <InputLabel
                      required={this.props.isrsnenabled}
                      htmlFor="intervals2rs-id"
                    >
                      Choose retry interval:
                    </InputLabel>
                    <Select
                      native
                      style={{
                        width: "300px",
                      }}
                      value={this.props.intervals2rs}
                      onChange={this.onUpdateInterval}
                      inputProps={{
                        name: "intervals2rs",
                        id: "intervals2rs-id",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {_.range(
                        2,
                        this.props.isasnenabled
                          ? this.props.intervals2as
                          : this.props.isescalationenabled
                          ? this.props.intervals2e
                          : 30
                      ).map((value) => {
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {this.props.isasnenabled && (
                <motion.div
                  className="flex-container"
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "tween",
                    duration: 1,
                    delay: 0.2,
                  }}
                  exit={{
                    x: 300,
                    opacity: 0,
                  }}
                >
                  <div className="item">
                    <span style={{ color: "gray" }}>
                      Alternate secondary contact number <sup>*</sup>
                    </span>
                    <MuiPhoneNumber
                      style={{
                        width: "300px",
                      }}
                      name="alternatesecondarycontactnumber"
                      value={this.props.alternatesecondarycontactnumber}
                      onChange={(value) => {
                        this.onUpdateContact(
                          "alternatesecondarycontactnumber",
                          value
                        );
                      }}
                      defaultCountry="se"
                      countryCodeEditable={true}
                    />
                  </div>
                  <div className="item">
                    <FormControl>
                      <InputLabel
                        required={this.props.isasnenabled}
                        htmlFor="intervals2as-id"
                      >
                        Choose interval for alternate number:
                      </InputLabel>
                      <Select
                        native
                        style={{
                          width: "300px",
                        }}
                        value={this.props.intervals2as}
                        onChange={this.onUpdateInterval}
                        inputProps={{
                          name: "intervals2as",
                          id: "intervals2as-id",
                        }}
                      >
                        <option aria-label="None" value="" />
                        {_.range(
                          this.props.isrsnenabled
                            ? this.props.intervals2rs + 1
                            : 0,
                          this.props.isescalationenabled
                            ? this.props.intervals2e
                            : 30
                        ).map((value) => {
                          return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="flex-container">
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isescalationenabled}
                      name="isescalationenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable escalation contact"
                  labelPlacement="end"
                />
              </div>
              <motion.div
                className="item"
                variants={variants}
                animate={this.props.isescalationenabled ? "show" : "hide"}
                initial={{ opacity: 0 }}
              >
                <FormControl>
                  <InputLabel
                    required={this.props.isescalationenabled}
                    htmlFor="intervals2e-id"
                  >
                    Choose interval for escalation number:
                  </InputLabel>
                  <Select
                    native
                    style={{
                      width: "300px",
                    }}
                    value={this.props.intervals2e}
                    onChange={this.onUpdateInterval}
                    inputProps={{
                      name: "intervals2e",
                      id: "intervals2e-id",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {_.range(
                      this.props.isasnenabled
                        ? this.props.intervals2as + 1
                        : this.props.isrsnenabled
                        ? this.props.intervals2rs + 1
                        : 0,
                      30
                    ).map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </motion.div>
            </div>
          </Container>
        </motion.div>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ padding: "0px 10px" }}
            spacing={2}
          >
            <Button
              startIcon={<NavigateBeforeOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.back();
              }}
            >
              Previous
            </Button>
            <Button
              disabled={!this.props.isform4valid}
              endIcon={<NavigateNextOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.next();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    secondarycontactnumber: state.shmgmt.secondarycontactnumber,
    alternatesecondarycontactnumber:
      state.shmgmt.alternatesecondarycontactnumber,
    isasnenabled: state.shmgmt.isasnenabled,
    isrsnenabled: state.shmgmt.isrsnenabled,
    intervals2rs: state.shmgmt.intervals2rs,
    intervals2as: state.shmgmt.intervals2as,
    intervals2e: state.shmgmt.intervals2e,
    issecondaryenabled: state.shmgmt.issecondaryenabled,
    isescalationenabled: state.shmgmt.isescalationenabled,
    isform4valid: state.shmgmt.isform4valid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateContact: (name, value) => {
      dispatch(updateContact(name, value));
    },
    updateFeaturesEnabled: (name, value) => {
      dispatch(updateFeaturesEnabled(name, value));
    },
    updateInterval: (name, value) => {
      dispatch(updateInterval(name, value));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
