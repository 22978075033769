import React, { Component } from "react";
// import Header from "../../components/acsselfonboarding/Header";
// import ACSOnBoardingForm from "../../components/acsselfonboarding/UserForm";
import "./ACSOnBoardingPage.css";

export class ACSOnBoardingPage extends Component {
  render() {
    return (
      <div>
        {/* <Header /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5px 0px",
            minHeight: "80vh",
          }}
        >
          {/* <ACSOnBoardingForm /> */}
        </div>
      </div>
    );
  }
}

export default ACSOnBoardingPage;
