import React from "react";

function OKRReport () {
    return (
        <>
            <iframe className="datastudio-iframe" title="okrreport" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/c98c0c7e-90d6-4fa9-92c1-6eb6ece0b659/page/QZS7B" frameBorder="0" allowFullScreen></iframe>
        </>
    );
}

export default OKRReport;