import React from 'react'

function DatabaseSecurityReport() {
    return (
        <div>
            {/* <iframe className="datastudio-iframe-databasesecurityreport" title="databasesecurityreport" style={{ height: '98vh', width: '98vw', border: '0' }} src="https://datastudio.google.com/embed/reporting/38ab57e4-2d08-4184-8fa8-5ae8c01da10e/page/H3quB" frameBorder={0} allowFullScreen></iframe> */}
            {/* <iframe className="datastudio-iframe-databasesecurityreport" title="databasesecurityreport" style={{ height: '98vh', width: '98vw', border: '0' }} src="https://datastudio.google.com/embed/u/0/reporting/a4e5e937-e4af-4b4d-bdbd-93fcaf766d53/page/H3quB" frameBorder={0} allowFullScreen></iframe> */}
            <iframe className="datastudio-iframe-databasesecurityreport" title="databasesecurityreport" style={{ height: '98vh', width: '98vw', border: '0' }} src="https://datastudio.google.com/embed/reporting/38ab57e4-2d08-4184-8fa8-5ae8c01da10e/page/H3quB" frameBorder={0} allowFullScreen></iframe>
        </div>
    )
}

export default DatabaseSecurityReport;
