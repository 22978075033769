import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

/** import components from list of components */
import NavBar from "./components/custom/navbar/CustomNavBar";
import ProtectedRoute from "./components/route/ProtectedRoute";

/** import css styles */
import "./App.css";

/** import pages from common pages for section */
import LandingPage from "./pages/common/LandingPage";
import Footer from "./pages/common/BasicFooter";

/** import pages from pages section */
/** APP Connect Portal - Android */
import AndroidConnectPortal from "./pages/developer_connect/AndroidConnectPortal";
import AndroidConnectPortalSettings from "./pages/developer_connect/AndroidConnectPortalSettings";
/** Mobile Application Review Reports - Android & iOS */
import AndroidAppResponse from "./pages/reports/AndroidAppResponse";
import AndroidClassificationReport from "./pages/reports/AndroidClassificationReport";
import IOSAppResponse from "./pages/reports/IOSAppResponse";
import IOSAppClassification from "./pages/reports/IOSAppClassification";
/** Webtraffic report */
import WebTrafficBoard2 from "./pages/reports/WebTrafficBoard2";
import WebTrafficBoard24h from "./pages/reports/WebTrafficBoardVersion2";
/** iSell & iSOM DB Outage Report */
import DatabaseOutageReport from "./pages/reports/DatabaseOutageReport";
/** OKR Report */
import OKRReport from './pages/reports/OKRReport';

/** acn section - start */
import AutomatedCallingReport from "./pages/reports/AutomatedCallingReport";
import ACSSelfOnBoardingPage from "./pages/acsselfonboarding/ACSOnBoardingPage";
import Contact from "./components/Contact";
import StepperFormV2 from "./pages/acsselfonboarding/v2/StepperFormV2";
import Notifier from "./components/acsselfservice/v2/Notifier";
import ACSRBACPage from "./components/acsrbacservice/v2/Settings";
import ACNIDESKStatusReport from "./components/acnstatusreport/idesk/v3/Acnstatusreport";
/** acn section - end */
import DatabaseSecurityReport from "./pages/reports/DatabaseSecurityReport";
import IOSConnectPortal from "./pages/developer_connect/IOSConnectPortal";
import IOSConnectPortalSettings from "./pages/developer_connect/IOSConnectPortalSettings";

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <ProtectedRoute
            exact
            path="/report/android/response"
            component={AndroidAppResponse}
          />
          <ProtectedRoute
            exact
            path="/report/android/classification"
            component={AndroidClassificationReport}
          />
          <ProtectedRoute
            exact
            path="/report/ios/response"
            component={IOSAppResponse}
          />
          <ProtectedRoute
            exact
            path="/report/ios/classification"
            component={IOSAppClassification}
          />
          <ProtectedRoute
            exact
            path="/report/web/traffic2"
            component={WebTrafficBoard2}
          />
          <ProtectedRoute
            exact
            path="/report/web/traffic/24h"
            component={WebTrafficBoard24h}
          />

          <ProtectedRoute
            exact
            path="/report/web/databaseoutage"
            component={DatabaseOutageReport}
          />
          <ProtectedRoute
            exact
            path="/report/okr"
            component={OKRReport}
          />
          <ProtectedRoute
            exact
            path="/report/web/databaseloginanomaly"
            component={DatabaseSecurityReport}
          />
          <ProtectedRoute
            exact
            path="/report/acs/audit"
            component={AutomatedCallingReport}
          />
          <ProtectedRoute
            exact
            path="/report/acn/status/idesk"
            component={ACNIDESKStatusReport}
          />
          <ProtectedRoute
            exact
            path="/pages/acs/selfservice"
            component={Contact}
          />
          <ProtectedRoute
            exact
            path="/pages/acs/selfservice/onboarding"
            component={ACSSelfOnBoardingPage}
          />
          <ProtectedRoute
            exact
            path="/pages/acs/selfservice/v2/rbac"
            component={ACSRBACPage}
          />
          <ProtectedRoute
            exact
            path="/pages/acs/selfservice/v4.1/onboarding"
            component={StepperFormV2}
          />
          <ProtectedRoute
            exact
            path="/pages/nreviews/android/connectportal"
            component={AndroidConnectPortal}
          />
          <ProtectedRoute
            exact
            path="/pages/nreviews/android/connectportal/settings/rbac"
            component={AndroidConnectPortalSettings}
          />
          <ProtectedRoute
            exact
            path="/pages/nreviews/ios/connectportal"
            component={IOSConnectPortal}
          />
          <ProtectedRoute
            exact
            path="/pages/nreviews/ios/connectportal/settings/rbac"
            component={IOSConnectPortalSettings}
          />
          <ProtectedRoute>
            <LandingPage />
          </ProtectedRoute>
        </Switch>
        <Footer />
        <Notifier />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
