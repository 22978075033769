import * as firebase from 'firebase';

//const {Firestore} = require('@google-cloud/firestore');
var firebaseConfig = {
    apiKey: " AIzaSyBRvP-fLz8p7RpybX4L30QMw3bcDP48v6w ",
    authDomain: "ikea-itsd-ml.firebaseapp.com",
    databaseURL: "https://ikea-itsd-ml.firebaseio.com",
    projectId: "ikea-itsd-ml",
    storageBucket: "ikea-itsd-ml.appspot .com ",
    messagingSenderId: " 431715193621 ",
    appId: " 1: 431715193621: web: 4d70694b72eb1152dd0f1c ",
    measurementId: " G-X8H7MS6LC7 "
};
// Initialize Firebase   
firebase.initializeApp(firebaseConfig);
export default firebase;