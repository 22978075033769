import React from "react";

function AndroidClassificationReport () {
    return (
        <>
            <iframe className="datastudio-iframe" title="androidclassificationreport" style={{ height: '98vh', width: '98vw', border: '0' }} src="https://datastudio.google.com/embed/reporting/07ab0829-2b4c-42fb-a494-77269d1c779a/page/azjBB" frameBorder={0} allowFullScreen></iframe>
        </>
    );
}

export default AndroidClassificationReport;
