import React, { Component } from "react";
import "./LandingPage.css";
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";

class LandingPage extends Component {

  render() {
    return (
      <div className="landing__page">
        {this.props.isAuthenticated === true ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Image src={this.props.usr.imageUrl} roundedCircle />
              <br />
              <h3 className="greeting__user__label">
                Hi, { this.props.usr.name}
              </h3>
            </div>
          </>
        ) : (
          <>
            <h2>Operation Intelligence</h2>
            <br />
            <span>(Predictions Dashboard)</span>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.au.authenticated,
    usr: state.usr.user,
    token: state.au.token
  };
}


export default connect(mapStateToProps)(LandingPage);
