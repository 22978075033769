import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { motion } from "framer-motion";
import moment from "moment";
import _ from "lodash";

import "./Settings.css";

/** material ui component */
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Slide,
  Typography,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  DeleteRounded,
  Label,
  NavigateBeforeOutlined,
  NavigateBeforeRounded,
} from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { SaveAltRounded } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import SaveIcon from "@material-ui/icons/Save";
import MuiPhoneNumber from "material-ui-phone-number";
import Tooltip from "@material-ui/core/Tooltip";

import OperatorTable from "../operatormanager/OperatorTable";

/** actions required for manage our shifts */
import {
  updateFeaturesEnabled,
  updateNotificationContactNumber,
  updateFormValidation,
  updateShiftStateWithDefaultShiftSchema,
  setSMSFor,
} from "../../../../../../redux/shiftmanagement/actions";

import NotificationIncomingWebhook from "./NotificationIncomingWebhook";
/** import services to manage our actions */
import {
  createShiftRecord,
  updateShiftRecord,
} from "../../../../../../services/shiftmanagement";
import {
  getMyRoles,
  getUsersAndRoles,
} from "../../../../../../services/shiftmanagementrbac";
import OperatorSettings from "../operatormanager/Settings";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class Settings extends Component {
  constructor(props) {
    super(props);
    const defaultShiftStateObject = {
      isaismsenabled: false,
      isaiaudioenabled: false,
      isform2valid: false,
      isform3valid: false,
      isform4valid: false,
      isform5valid: false,
      isform6valid: false,
      mode: "add",
      id: "",
      startTime: moment().hours(0).minutes(0).seconds(0),
      endTime: moment().hours(23).minutes(59).seconds(0),
      isenabledview: false,
      issecondaryenabled: false,
      isescalationenabled: false,
      isapnenabled: false,
      isrpnenabled: false,
      isasnenabled: false,
      isrsnenabled: false,
      primarycontactnumber: "",
      alternateprimarycontactnumber: "",
      secondarycontactnumber: "",
      alternatesecondarycontactnumber: "",
      escalationcontactnumber: "",
      intervalp2s: 0,
      intervals2e: 0,
      intervalp2ap: 0,
      intervals2as: 0,
      intervalp2rp: 0,
      intervals2rs: 0,
      issmsenabled: false,
      sms_numbers: [],
      isteamsenabled: false,
      teamsWebhookUrl: "",
      isslackenabled: false,
      slackWebhookUrl: "",
      iswatsappenabled: false,
      whatsapp_numbers: [],
    };
    this.state = {
      dobject: defaultShiftStateObject,
      columns: [
        { id: "mobile_number", label: "Mobile Number", minWidth: 170 },
        { id: "action", label: "Action", minWidth: 100 },
      ],
      page: 0,
      rowsPerPage: 5,
      page1: 0,
      rowsPerPage1: 5,
      number: "",
      valid: false,
      number1: "",
      valid1: false,
      validated: false,
      submitted: false,
      operatorConsole: false,
    };
  }

  isAuthorized = () => {
    let status = false;
    const { myroles, user, stateObject } = this.props;
    const { assignmentGroup, selectedPlatformItem } = stateObject;
    myroles.forEach((usr) => {
      const c1 =
        usr.platform === selectedPlatformItem || [usr.platform].includes("all");
      const c2 = usr.group === assignmentGroup || [usr.group].includes("all");
      const c3 = usr.email === user.email;
      const c4 = ["superuser", "administrator", "operator"].includes(usr.role);
      if (c1 && c2 && c3 && c4) {
        status = c1 && c2 && c3 && c4;
      }
    });
    return status;
  };

  openOperatorConsole = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        operatorConsole: true,
      };
    });
  };

  closeOperatorConsole = () => {
    this.props.getMyRoles(this.props.user.email, this.props.token);
    this.setState((prevState) => {
      return {
        ...prevState,
        operatorConsole: false,
      };
    });
  };

  inputsValidation = () => {
    this.validate();
    const {
      isform1valid,
      isform2valid,
      isform3valid,
      isform4valid,
      isform5valid,
      isform6valid,
    } = this.props;
    // console.log(isform1valid, isform2valid, isform3valid, isform4valid, isform5valid, isform6valid)
    let flag =
      isform1valid &&
      isform2valid &&
      isform3valid &&
      isform4valid &&
      isform5valid &&
      isform6valid;
    let obj = {
      isrsnenabled: true,
      jiraPlatformPriority: "",
      issecondaryenabled: true,
      isslackenabled: false,
      issmsenabled: false,
      isteamsenabled: false,
      iswatsappenabled: false,
      platform: "",
      primarycontactnumber: "",
      secondarycontactnumber: "",
      slackWebhookUrl: "",
      sms_numbers: [],
      startTime: "",
      teamsWebhookUrl: "",
      whatsapp_numbers: [],
      lastmodifiedby: this.props.user.email,
    };

    /** First Page Validation */
    const {
      selectedPlatformItem,
      assignmentGroup,
      jiraProjectPriority,
      jiraIssueType,
    } = this.props.stateObject;

    obj = {
      ...obj,
      platform: selectedPlatformItem,
      assignmentGroup: assignmentGroup,
      jiraProjectPriority: jiraProjectPriority,
      jiraIssueType: jiraIssueType,
    };

    /** Second Page Validation */
    const { startTime, endTime, shifts } = this.props.stateObject;
    obj = {
      ...obj,
      startTime: `${startTime.format("HH:mm")}`,
      endTime: `${endTime.format("HH:mm")}`,
    };

    /** Third Page Validation */
    const {
      primarycontactnumber,
      isapnenabled,
      isrpnenabled,
      intervalp2s,
      intervalp2rp,
      intervalp2ap,
      issecondaryenabled,
      isprimaryenabled,
    } = this.props.stateObject;

    obj = {
      ...obj,
      primarycontactnumber: primarycontactnumber,
      isapnenabled: isapnenabled,
      isrpnenabled: isrpnenabled,
      intervalp2s: intervalp2s,
      intervalp2rp: intervalp2rp,
      intervalp2ap: intervalp2ap,
      isprimaryenabled: isprimaryenabled,
      issecondaryenabled: issecondaryenabled,
    };

    /** Fourth Page Validation */
    const {
      secondarycontactnumber,
      isasnenabled,
      isrsnenabled,
      intervals2rs,
      intervals2e,
      intervals2as,
      isescalationenabled,
    } = this.props.stateObject;

    obj = {
      ...obj,
      secondarycontactnumber: secondarycontactnumber,
      isasnenabled: isasnenabled,
      isrsnenabled: isrsnenabled,
      intervals2rs: intervals2rs,
      intervals2e: intervals2e,
      intervals2as: intervals2as,
      isescalationenabled: isescalationenabled,
    };

    /** Fifth Page Valiation */
    const { escalationcontactnumber } = this.props.stateObject;

    obj = {
      ...obj,
      escalationcontactnumber: escalationcontactnumber,
    };

    /** Sixth Page Validation */
    const {
      issmsenabled,
      isteamsenabled,
      isslackenabled,
      iswatsappenabled,
      whatsapp_numbers,
      sms_numbers,
      teamsWebhookUrl,
      slackWebhookUrl,
    } = this.props.stateObject;

    obj = {
      ...obj,
      issmsenabled: issmsenabled,
      isteamsenabled: isteamsenabled,
      isslackenabled: isslackenabled,
      iswatsappenabled: iswatsappenabled,
      whatsapp_numbers: whatsapp_numbers,
      teamsWebhookUrl: teamsWebhookUrl,
      slackWebhookUrl: slackWebhookUrl,
      sms_numbers: sms_numbers,
    };

    let returnObj = {};
    returnObj["status"] = flag;
    returnObj["returnObject"] = obj;
    return returnObj;
  };

  objectToSaveOrUpdate = () => {
    let obj = {
      isrsnenabled: true,
      jiraPlatformPriority: "",
      issecondaryenabled: true,
      isprimaryenabled: false,
      isslackenabled: false,
      issmsenabled: false,
      isteamsenabled: false,
      iswatsappenabled: false,
      platform: "",
      primarycontactnumber: "",
      secondarycontactnumber: "",

      slackWebhookUrl: "",
      slackfor: [],

      sms_numbers: [],
      smsfor: [],
      startTime: "",
      teamsWebhookUrl: "",
      teamsfor: [],
      whatsapp_numbers: [],
      whatsappfor: [],
      lastmodifiedby: this.props.user.email,
    };

    /** First Page Validation */
    const {
      selectedPlatformItem,
      assignmentGroup,
      jiraProjectPriority,
      jiraIssueType,
    } = this.props.stateObject;

    obj = {
      ...obj,
      platform: selectedPlatformItem,
      assignmentGroup: assignmentGroup,
      jiraProjectPriority: jiraProjectPriority,
      jiraIssueType: jiraIssueType,
    };

    /** Second Page Validation */
    const { startTime, endTime, shifts } = this.props.stateObject;
    obj = {
      ...obj,
      startTime: `${startTime.format("HH:mm")}`,
      endTime: `${endTime.format("HH:mm")}`,
    };

    /** Third Page Validation */
    const {
      primarycontactnumber,
      alternateprimarycontactnumber,
      isprimaryenabled,
      isapnenabled,
      isrpnenabled,
      intervalp2s,
      intervalp2rp,
      intervalp2ap,
      issecondaryenabled,
    } = this.props.stateObject;

    obj = {
      ...obj,
      primarycontactnumber: primarycontactnumber,
      alternateprimarycontactnumber: alternateprimarycontactnumber,
      isapnenabled: isapnenabled,
      isprimaryenabled: isprimaryenabled,
      isrpnenabled: isrpnenabled,
      intervalp2s: intervalp2s,
      intervalp2rp: intervalp2rp,
      intervalp2ap: intervalp2ap,
      issecondaryenabled: issecondaryenabled,
    };

    /** Fourth Page Validation */
    const {
      secondarycontactnumber,
      alternatesecondarycontactnumber,
      isasnenabled,
      isrsnenabled,
      intervals2rs,
      intervals2e,
      intervals2as,
      isescalationenabled,
    } = this.props.stateObject;

    obj = {
      ...obj,
      secondarycontactnumber: secondarycontactnumber,
      alternatesecondarycontactnumber: alternatesecondarycontactnumber,
      isasnenabled: isasnenabled,
      isrsnenabled: isrsnenabled,
      intervals2rs: intervals2rs,
      intervals2e: intervals2e,
      intervals2as: intervals2as,
      isescalationenabled: isescalationenabled,
    };

    /** Fifth Page Valiation */
    const { escalationcontactnumber } = this.props.stateObject;

    obj = {
      ...obj,
      escalationcontactnumber: escalationcontactnumber,
    };

    /** Sixth Page Validation */
    const {
      issmsenabled,
      isteamsenabled,
      isslackenabled,
      iswatsappenabled,
      whatsapp_numbers,
      whatsappfor,
      sms_numbers,
      smsfor,
      teamsWebhookUrl,
      teamsfor,
      slackWebhookUrl,
      slackfor,
      isopsbridgeenabled,
      isaismsenabled,
      isaiaudioenabled
    } = this.props.stateObject;

    obj = {
      ...obj,
      isaiaudioenabled: isaiaudioenabled,
      isaismsenabled: isaismsenabled,
      issmsenabled: issmsenabled,
      isteamsenabled: isteamsenabled,
      isslackenabled: isslackenabled,
      iswatsappenabled: iswatsappenabled,
      whatsapp_numbers: whatsapp_numbers,
      whatsappfor: _.uniq(whatsappfor),
      teamsWebhookUrl: teamsWebhookUrl,
      teamsfor: _.uniq(teamsfor),
      slackWebhookUrl: slackWebhookUrl,
      slackfor: _.uniq(slackfor),
      isopsbridgeenabled: isopsbridgeenabled,
      sms_numbers: sms_numbers,
      smsfor: _.uniq(smsfor),
    };
    // console.log("The object is going to update it.", obj)
    return obj;
  };

  onSubmit = () => {
    const { status, returnObject } = this.inputsValidation();
    // console.log(returnObject)
    const {
      mode,
      selectedPlatformItem,
      assignmentGroup,
      id,
    } = this.props.stateObject;
    if (status) {
      if (mode === "add") {
        this.props.createShiftRecord(
          selectedPlatformItem,
          assignmentGroup,
          this.props.jiraIssueType,
          this.props.token,
          returnObject
        );
      } else if (mode === "edit") {
        this.props.updateShiftRecord(
          selectedPlatformItem,
          assignmentGroup,
          this.props.jiraIssueType,
          this.props.token,
          id,
          returnObject
        );
      }
    }
  };

  onChangeCheckState = (event) => {
    const { name, checked } = event.target;
    this.props.updateFeaturesEnabled(name, checked);
  };

  onDeleteSMSContactNumber = (name, number) => {
    const numbers = this.props.snumbers.filter((tnumber) => tnumber !== number);
    this.props.updateNotificationContactNumber(name, numbers);
  };
  onDeleteWhatsappContactNumber = (name, number) => {
    const numbers = this.props.wnumbers.filter((tnumber) => tnumber !== number);
    this.props.updateNotificationContactNumber(name, numbers);
  };

  handleChangePage = (event, newPage) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: newPage,
      };
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        rowsPerPage: event.target.value,
        page: 0,
      };
    });
  };

  handleChangePage1 = (event, newPage) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page1: newPage,
      };
    });
  };

  handleChangeRowsPerPage1 = (event) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        rowsPerPage1: event.target.value,
        page1: 0,
      };
    });
  };

  validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  validate = () => {
    const formproperty = "isform6valid";
    const {
      issmsenabled,
      isteamsenabled,
      isslackenabled,
      iswatsappenabled,
      snumbers,
      wnumbers,
      teamsWebhookUrl,
      slackWebhookUrl,
    } = this.props;
    const smsvalidation =
      !issmsenabled || (issmsenabled && _.size(snumbers) > 0);
    const teamsurlvalidation =
      !isteamsenabled || (isteamsenabled && this.validateURL(teamsWebhookUrl));
    const slackurlvalidation =
      !isslackenabled || (isslackenabled && this.validateURL(slackWebhookUrl));
    const watsappvalidation =
      !iswatsappenabled || (iswatsappenabled && _.size(wnumbers) > 0);
    const valid =
      smsvalidation &&
      watsappvalidation &&
      teamsurlvalidation &&
      slackurlvalidation;
    this.setState((prevState) => {
      return {
        ...prevState,
        validated: valid,
      };
    });
    this.props.updateFormValidation({ name: formproperty, value: valid });
  };

  componentDidMount() {
    const { user, stateObject } = this.props;
    const { assignmentGroup, selectedPlatformItem } = stateObject;
    this.props.getMyRoles(user.email, this.props.token);
    this.props.getUsersAndRoles(
      selectedPlatformItem,
      assignmentGroup,
      user.email,
      this.props.token
    );
    this.validate();
  }

  render() {
    const isAdminOrSuperUser = _.filter(this.props.myroles, (role) => {
      // console.log(this.props.stateObject.assignmentGroup);
      return (
        ["superuser", "administrator"].includes(role.role) &&
        (role.group === this.props.stateObject.assignmentGroup ||
          role.group === "all")
      );
    }).length;
    const isOperator = _.filter(this.props.myroles, (role) => {
      // console.log(this.props.stateObject.assignmentGroup);
      return (
        ["operator"].includes(role.role) &&
        (role.group === this.props.stateObject.assignmentGroup ||
          role.group === "all")
      );
    }).length;
    return (
      <>
        {isAdminOrSuperUser > 0 ||
        (isAdminOrSuperUser === 0 &&
          isOperator === 0 &&
          this.props.shifts.length === 0) ? (
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              color="primary"
              startIcon={<SettingsIcon />}
              onClick={() => {
                this.openOperatorConsole();
              }}
            >
              Manage Users
            </Button>
          </div>
        ) : null}
        <Formik
          enableReinitialize={true}
          initialValues={{
            isaismsenabled: this.props.isaismsenabled,
            isaiaudioenabled: this.props.isaiaudioenabled,
            issmsenabled: this.props.issmsenabled,
            iswatsappenabled: this.props.iswatsappenabled,
            isteamsenabled: this.props.isteamsenabled,
            isslackenabled: this.props.isslackenabled,
            isopsbridgeenabled: this.props.isopsbridgeenabled,
            smsforall: _.sum(_.uniq(this.props.smsfor)) === 7,
            smsforprimary: this.props.smsfor.includes(1),
            smsforsecondary: this.props.smsfor.includes(2),
            smsforescalation: this.props.smsfor.includes(4),
          }}
          validate={(values) => {
            let errors = {};
            let smsoptions = [];
            if (values.smsforprimary) {
              smsoptions.push(1);
            }
            if (values.smsforsecondary) {
              smsoptions.push(2);
            }
            if (values.smsforescalation) {
              smsoptions.push(4);
            }
            this.props.setSMSFor(_.uniq(smsoptions));

            this.props.updateFeaturesEnabled(
              "issmsenabled",
              values.issmsenabled
            );
            this.props.updateFeaturesEnabled(
              "isaismsenabled",
              values.isaismsenabled
            );
            this.props.updateFeaturesEnabled(
              "isaiaudioenabled",
              values.isaiaudioenabled
            );
            if (this.props.stateObject.selectedPlatformItem === "idesk") {
              this.props.updateFeaturesEnabled(
                "isopsbridgeenabled",
                values.isopsbridgeenabled
              );
            }

            this.props.updateFeaturesEnabled(
              "iswatsappenabled",
              values.iswatsappenabled
            );
            this.props.updateFeaturesEnabled(
              "isteamsenabled",
              values.isteamsenabled
            );
            this.props.updateFeaturesEnabled(
              "isslackenabled",
              values.isslackenabled
            );
            const {
              snumbers,
              wnumbers,
              teamsWebhookUrl,
              slackWebhookUrl,
            } = this.props;
            // sms values are enabled or not
            if (values.issmsenabled && _.size(snumbers) === 0) {
              errors.issmsenabled =
                "Please add atleast a mobile number for sms notification";
            } else if (
              values.issmsenabled &&
              _.size(snumbers) > 0 &&
              _.size(this.props.smsfor) === 0
            ) {
              errors.issmsenabled =
                "Please choose circumtance for sms notification";
            }

            // watsapp values are enabled or not
            if (values.iswatsappenabled && _.size(wnumbers) === 0) {
              errors.iswatsappenabled =
                "Please add atleast a mobile number for whatsapp notification";
            }

            // slack values are enabled or not
            if (
              values.isslackenabled &&
              !this.validateURL(this.props.slackWebhookUrl)
            ) {
              errors.isslackenabled =
                "Please share the incoming webhook url for slack notification";
            }

            // teams values are enabled or not
            if (
              values.isteamsenabled &&
              !this.validateURL(this.props.teamsWebhookUrl)
            ) {
              errors.isteamsenabled =
                "Please share the incoming webhook url for microsoft teams notification";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const {
              isform1valid,
              isform2valid,
              isform3valid,
              isform4valid,
              isform5valid,
            } = this.props;
            const {
              mode,
              selectedPlatformItem,
              assignmentGroup,
              id,
            } = this.props.stateObject;
            var soruobject = this.objectToSaveOrUpdate();
            soruobject = {
              ...soruobject,
              active: true,
            };
            // console.log("Object which is need to be stored :: ", soruobject);
            const status =
              isform1valid &&
              isform2valid &&
              isform3valid &&
              isform4valid &&
              isform5valid;
            if (mode === "add" && status) {
              this.props.createShiftRecord(
                selectedPlatformItem,
                assignmentGroup,
                this.props.jiraIssueType,
                this.props.token,
                soruobject
              );
              values.isslackenabled = false;
              values.issmsenabled = false;
              values.iswatsappenabled = false;
              values.isteamsenabled = false;
              if (this.props.stateObject.selectedPlatformItem === "idesk") {
                values.isopsbridgeenabled = false;
              }
              values.teamsWebhookUrl = "";
              values.slackWebhookUrl = "";
            } else if (mode === "edit" && status) {
              this.props.updateShiftRecord(
                selectedPlatformItem,
                assignmentGroup,
                this.props.jiraIssueType,
                this.props.token,
                id,
                soruobject
              );
              values.isslackenabled = false;
              values.issmsenabled = false;
              values.iswatsappenabled = false;
              values.isteamsenabled = false;
              if (this.props.stateObject.selectedPlatformItem === "idesk") {
                values.isopsbridgeenabled = false;
              }

              values.teamsWebhookUrl = "";
              values.slackWebhookUrl = "";
            } else {
              alert(
                "Few pages are not valid. Please check your previous pages."
              );
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            submitForm,
            validateForm,
          }) => (
            <Form>
              <motion.div
                className="container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0 }}
              >
                <Container>
                  <Typography
                    component="span"
                    variant="h6"
                    style={{ color: "#9e9e9e" }}
                  >
                    Also get notified!
                  </Typography>
                  {this.props.stateObject.selectedPlatformItem === "idesk" ? (
                    <FormGroup>
                      <Field
                        name="isopsbridgeenabled"
                        component={CheckboxWithLabel}
                        type="checkbox"
                        Label={{ label: "Enable opsbridge notification" }}
                      />
                      <ErrorMessage
                        name="isopsbridgeenabled"
                        render={(msg) => (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.2 }}
                            style={{ color: "red" }}
                          >
                            {msg}
                          </motion.div>
                        )}
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Field
                      name="isaiaudioenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable AI Based Call Notification" }}
                    />
                    <ErrorMessage
                      name="isaiaudioenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="isaismsenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable AI Based SMS Notification" }}
                    />
                    <ErrorMessage
                      name="isaismsenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="issmsenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable sms notification" }}
                    />
                    <ErrorMessage
                      name="issmsenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                    {values.issmsenabled && (
                      <div className="item">
                        {/* Notification Mobile Number - Start */}
                        <div>
                          <FormGroup row={true}>
                            <Field
                              name="smsforall"
                              component={CheckboxWithLabel}
                              type="checkbox"
                              Label={{ label: "All" }}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  this.props.setSMSFor([1, 2, 4]);
                                } else {
                                  this.props.setSMSFor([]);
                                }
                              }}
                            />
                            <Field
                              name="smsforprimary"
                              component={CheckboxWithLabel}
                              type="checkbox"
                              Label={{ label: "Primary" }}
                            />
                            <Field
                              name="smsforsecondary"
                              component={CheckboxWithLabel}
                              type="checkbox"
                              Label={{ label: "Secondary" }}
                            />
                            <Field
                              name="smsforescalation"
                              component={CheckboxWithLabel}
                              type="checkbox"
                              Label={{ label: "Escalation" }}
                            />
                          </FormGroup>
                        </div>
                        <div>
                          <MuiPhoneNumber
                            style={{
                              width: "300px",
                            }}
                            name="snumber"
                            value={this.state.number}
                            onChange={(value) => {
                              if (value.length > 8) {
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    number: value,
                                    valid: true,
                                  };
                                });
                              } else {
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    number: value,
                                    valid: false,
                                  };
                                });
                              }
                            }}
                            defaultCountry="se"
                            countryCodeEditable={true}
                          />
                          <IconButton
                            disabled={!this.state.valid}
                            color={this.state.valid ? "primary" : "default"}
                            onClick={() => {
                              let tnumbers =
                                this.props.snumbers === undefined
                                  ? []
                                  : this.props.snumbers;
                              tnumbers.push(this.state.number);
                              tnumbers = _.union(tnumbers);
                              this.props.updateNotificationContactNumber(
                                "sms_numbers",
                                tnumbers
                              );
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  valid: false,
                                  number: "",
                                };
                              });
                              validateForm().then(() => {});
                            }}
                          >
                            <SaveRoundedIcon />
                          </IconButton>
                        </div>
                        {/* Notification Mobile Number - End */}
                        <div></div>
                      </div>
                    )}

                    {values.issmsenabled && (
                      <Paper style={{ width: "100%" }}>
                        <TableContainer style={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {this.state.columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {_.size(this.props.snumbers) === 0 ? (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell align="center" colSpan={2}>
                                    No contact found.
                                  </TableCell>
                                </TableRow>
                              ) : null}
                              {_.size(this.props.snumbers) > 0
                                ? this.props.snumbers
                                    .slice(
                                      this.state.page * this.state.rowsPerPage,
                                      this.state.page * this.state.rowsPerPage +
                                        this.state.rowsPerPage
                                    )
                                    .map((row, index) => (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                      >
                                        <TableCell
                                          key={`smsnumber-${index}`}
                                          align="left"
                                        >
                                          {row}
                                        </TableCell>
                                        <TableCell
                                          key={`smsnumber-action-${index}`}
                                          align="left"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              validateForm();
                                              this.onDeleteSMSContactNumber(
                                                "sms_numbers",
                                                row
                                              );
                                            }}
                                          >
                                            <DeleteRounded />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[1, 5, 10, 25, 100]}
                          component="div"
                          count={this.props.snumbers.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </Paper>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="iswatsappenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable whatsapp notification" }}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="iswatsappenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                    {values.iswatsappenabled === true && (
                      <div className="item">
                        {/* Notification Whatsapp Number - Start */}
                        <div>
                          <MuiPhoneNumber
                            style={{
                              width: "300px",
                            }}
                            name={this.props.name}
                            value={this.state.number1}
                            onChange={(value) => {
                              if (value.length > 8) {
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    number1: value,
                                    valid1: true,
                                  };
                                });
                              } else {
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    number1: value,
                                    valid1: false,
                                  };
                                });
                              }
                            }}
                            defaultCountry="se"
                            countryCodeEditable={true}
                          />
                          <IconButton
                            disabled={!this.state.valid1}
                            color={this.state.valid1 ? "primary" : "default"}
                            onClick={() => {
                              let tnumbers =
                                this.props.wnumbers === undefined
                                  ? []
                                  : this.props.wnumbers;
                              tnumbers.push(this.state.number1);
                              tnumbers = _.union(tnumbers);
                              this.props.updateNotificationContactNumber(
                                "whatsapp_numbers",
                                tnumbers
                              );
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  valid1: false,
                                  number1: "",
                                };
                              });
                              validateForm().then(() => {});
                            }}
                          >
                            <SaveRoundedIcon />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    {values.iswatsappenabled === true && (
                      <Paper style={{ width: "100%" }}>
                        <TableContainer style={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {this.state.columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {_.size(this.props.wnumbers) === 0 ? (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell align="center" colSpan={2}>
                                    No contact found.
                                  </TableCell>
                                </TableRow>
                              ) : null}
                              {_.size(this.props.wnumbers) > 0
                                ? this.props.wnumbers
                                    .slice(
                                      this.state.page * this.state.rowsPerPage,
                                      this.state.page * this.state.rowsPerPage +
                                        this.state.rowsPerPage
                                    )
                                    .map((row, index) => (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                      >
                                        <TableCell
                                          key={`smsnumber-${index}`}
                                          align="left"
                                        >
                                          {row}
                                        </TableCell>
                                        <TableCell
                                          key={`smsnumber-action-${index}`}
                                          align="left"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              validateForm();
                                              this.onDeleteWhatsappContactNumber(
                                                "whatsapp_numbers",
                                                row
                                              );
                                            }}
                                          >
                                            <DeleteRounded />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[1, 5, 10, 25, 100]}
                          component="div"
                          count={this.props.wnumbers.length}
                          rowsPerPage={this.state.rowsPerPage1}
                          page={this.state.page1}
                          onChangePage={this.handleChangePage1}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage1}
                        />
                      </Paper>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="isteamsenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable microsoft teams notification" }}
                    />
                    <ErrorMessage
                      name="isteamsenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                    {values.isteamsenabled && (
                      <NotificationIncomingWebhook
                        name="teamsWebhookUrl"
                        notification_platform_name="Microsoft Teams"
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Field
                      name="isslackenabled"
                      component={CheckboxWithLabel}
                      type="checkbox"
                      Label={{ label: "Enable slack notification" }}
                    />
                    <ErrorMessage
                      name="isslackenabled"
                      render={(msg) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          style={{ color: "red" }}
                        >
                          {msg}
                        </motion.div>
                      )}
                    />
                    {values.isslackenabled && (
                      <NotificationIncomingWebhook
                        name="slackWebhookUrl"
                        notification_platform_name="Slack"
                      />
                    )}
                  </FormGroup>
                  {this.props.isformsubmitted && <LinearProgress />}
                </Container>
              </motion.div>
              <div style={{ margin: "10px" }}>
                <Container>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={2}
                    style={{ padding: "10px" }}
                    xs
                    lg
                    sm
                    md
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          this.props.back();
                        }}
                      >
                        <NavigateBeforeOutlined /> Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          this.props.isformsubmitted ? "outlined" : "contained"
                        }
                        disabled={this.props.isformsubmitted}
                        onClick={() => {
                          this.props.updateShiftStateWithDefaultShiftSchema(
                            this.state.dobject
                          );
                          this.props.reset();
                        }}
                      >
                        Start from beginning
                      </Button>
                    </Grid>
                    <Grid item>
                      {/* <Tooltip title={(this.props.myroles.length === 0 && this.props.shifts.length === 0 && this.props.shifts.length === 0) ? `You dont have permission. Click manager users -> save your email address as administrator.` : !this.isAuthorized() ? `You don't have permission to ${this.props.mode === "edit" ? "update" : "save"}.` : `${this.props.mode === "edit" ? "update" : "save"}`}> */}
                      <Tooltip
                        title={
                          isAdminOrSuperUser === 0 &&
                          isOperator === 0 &&
                          this.props.shifts.length === 0
                            ? `You dont have permission. Click manage users -> save your email address as administrator.`
                            : !this.isAuthorized()
                            ? `You don't have permission to ${
                                this.props.mode === "edit" ? "update" : "save"
                              }.`
                            : `${
                                this.props.mode === "edit" ? "update" : "save"
                              }`
                        }
                      >
                        <span>
                          <Button
                            variant={
                              this.props.isformsubmitted
                                ? "outlined"
                                : "contained"
                            }
                            startIcon={
                              this.props.isformsubmitted ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            color={
                              this.props.isformsubmitted ||
                              !this.isAuthorized() ||
                              (this.props.myroles.length === 0 &&
                                this.props.shifts.length === 0)
                                ? "default"
                                : "primary"
                            }
                            disabled={
                              this.props.isformsubmitted ||
                              !this.isAuthorized() ||
                              (isAdminOrSuperUser === 0 &&
                                isOperator === 0 &&
                                this.props.shifts.length === 0)
                            }
                            onClick={submitForm}
                          >
                            {this.props.isformsubmitted
                              ? this.props.mode === "edit"
                                ? "Updating..."
                                : "Saving..."
                              : this.props.mode === "edit"
                              ? "Update"
                              : "Save"}
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Form>
          )}
        </Formik>
        <div>
          <Dialog
            fullScreen
            open={this.state.operatorConsole}
            onClose={() => {
              this.closeOperatorConsole();
            }}
            TransitionComponent={Transition}
          >
            <AppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.closeOperatorConsole();
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">
                  User &amp; Role Management Console
                </Typography>
                {/* <Button
                  autoFocus
                  color="inherit"
                  onClick={() => {
                    this.closeOperatorConsole();
                  }}
                >
                  save
                </Button> */}
              </Toolbar>
            </AppBar>
            <List>
              <ListItem button>
                <ListItemText primary="Phone ringtone" secondary="Titania" />
              </ListItem>
              {/* <Divider /> */}
              <ListItem>
                <OperatorSettings />
              </ListItem>
              <ListItem>
                <OperatorTable />
              </ListItem>
            </List>
          </Dialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myroles: state.shrbac.roles,
    isopsbridgeenabled: state.shmgmt.isopsbridgeenabled,
    issmsenabled: state.shmgmt.issmsenabled,
    isteamsenabled: state.shmgmt.isteamsenabled,
    isslackenabled: state.shmgmt.isslackenabled,
    iswatsappenabled: state.shmgmt.iswatsappenabled,
    isaismsenabled: state.shmgmt.isaismsenabled,
    isaiaudioenabled: state.shmgmt.isaiaudioenabled,
    mode: state.shmgmt.mode,
    snumbers: state.shmgmt.sms_numbers,
    wnumbers: state.shmgmt.whatsapp_numbers,
    teamsWebhookUrl: state.shmgmt.teamsWebhookUrl,
    slackWebhookUrl: state.shmgmt.slackWebhookUrl,
    stateObject: state.shmgmt,
    jiraIssueType: state.shmgmt.jiraIssueType,
    token: state.au.token,
    user: state.usr.user,
    isform1valid: state.shmgmt.isform1valid,
    isform2valid: state.shmgmt.isform2valid,
    isform3valid: state.shmgmt.isform3valid,
    isform4valid: state.shmgmt.isform4valid,
    isform5valid: state.shmgmt.isform5valid,
    isform6valid: state.shmgmt.isform6valid,
    isformsubmitted: state.shmgmt.isformsubmitted,
    operators: state.shrbac.operators,
    shifts: state.shmgmt.shifts,
    smsfor: _.uniq(state.shmgmt.smsfor),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFeaturesEnabled: (name, value) => {
      dispatch(updateFeaturesEnabled(name, value));
    },
    updateNotificationContactNumber: (name, numbers) => {
      dispatch(updateNotificationContactNumber(name, numbers));
    },
    createShiftRecord: (tool, agroup, jitype, token, object) => {
      dispatch(createShiftRecord(tool, agroup, jitype, token, object));
    },
    updateShiftRecord: (tool, agroup, jitype, token, id, object) => {
      dispatch(updateShiftRecord(tool, agroup, jitype, token, id, object));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload));
    },
    updateShiftStateWithDefaultShiftSchema: (o) => {
      dispatch(updateShiftStateWithDefaultShiftSchema(o));
    },
    getMyRoles: (email, token) => {
      dispatch(getMyRoles(email, token));
    },
    getUsersAndRoles: (platform, group, email, token) => {
      dispatch(getUsersAndRoles(platform, group, email, token));
    },
    setSMSFor: (options) => {
      dispatch(setSMSFor(options));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
