import React, { Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { closeReportWindow } from "../../../../../../redux/shiftmanagement/actions";
import Acnstatusreport from "../../../../../acnstatusreport/idesk/v3/Acnstatusreport";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ReportWindow = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullScreen
        open={props.isopened}
        onClose={props.closeReportWindow}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeReportWindow}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              IKEA ACN Insight
            </Typography>
          </Toolbar>
        </AppBar>
        <Acnstatusreport group={props.assignmentGroup}/>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isopened: state.shmgmt.isenabledreportview,
  assignmentGroup: state.shmgmt.assignmentGroup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeReportWindow: () => {
      dispatch(closeReportWindow());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportWindow);
