import { combineReducers } from 'redux';

/** Reducers */
import authenticationReducer from './authentication/reducers';
import authorizationReducer from './authorization/reducers';
import developerConnectReducer from './developerconnectportal/android/reducers';
import iosDeveloperConnectReducer from './developerconnectportal/ios/reducers';
import userReducer from './user/reducers';
import developerConnectFeedbackReducer from './developerfeedbackprocess/reducers';
import dcpSettingsReducer from './settings/reducers';
import acsSelfServicePortal from './acsselfserviceportal/reducers';
import shiftmanagementreducer from './shiftmanagement/reducers';
import shiftmanagementrbacreducer from './shiftmanagementrbac/reducers';
import notifierreducer from './notification/reducers';

const reducers = combineReducers({
    au: authenticationReducer,
    android: developerConnectReducer,
    iosdcp: iosDeveloperConnectReducer,
    at: authorizationReducer,
    usr: userReducer,
    dcpfp: developerConnectFeedbackReducer,
    dcpsettings: dcpSettingsReducer,
    acsssp: acsSelfServicePortal,
    shmgmt: shiftmanagementreducer,
    shrbac: shiftmanagementrbacreducer,
    notifier: notifierreducer
})

export default reducers;