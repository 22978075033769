import React, { useState, useEffect } from "react";
// import {
//   BarChart,
//   Bar,
//   Cell,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

import { ResponsiveSunburst } from "@nivo/sunburst";

const PseCallStatusChart = ({ data }) => {
  // console.log("props", data);
  // console.log("props", typeof data);

  if (data === "undefined" || data === null) {
    return <div>Error</div>;
  }

  if (Object.keys(data).length === 0) {
    return <div>Loading</div>;
  }
  const { Groupwisecallscount, Totalcalls } = data;
  //   const {GroupName, callscount} = Groupwisecallscount;

  const fetchedData = {
    name: "chart",
    children: [
      {
        name: "Primary",
        color: '#0058A3',
        children: [
          {
            name: "Answered",
            color: '#0A8A00	',
            count: Totalcalls.PrimarySPOCSuccessCalls,
          },
          {
            name: "Unanswered",
            color: '#E00751',
            count: Totalcalls.PrimarySPOCFailureCalls,
          },
        ],
      },
      {
        name: "Secondary",
        color: '#CA5008',
        children: [
          {
            name: "Answered",
            color: '#0A8A00	',
            count: Totalcalls.SecondarySPOCSuccessCalls,
          },
          {
            name: "Unanswered",
            color: '#E00751',
            count: Totalcalls.SecondarySPOCFailureCalls,
          },
        ],
      },
      {
        name: "Escalation",
        color: '#FFA524',
        children: [
          {
            name: "Answered",
            color: '#0A8A00	',
            count: Totalcalls.EscalationSPOCSuccessCalls,
          },
          {
            name: "Unanswered",
            color: '#E00751',
            count: Totalcalls.EscalationSPOCFailureCalls,
          },
        ],
      },
    ],
  };

  return (
    <div style={{ height: 400 }}>
      <ResponsiveSunburst
        data={fetchedData}
        margin={{ top: 40, right: 20, bottom: 20, left: 20 }}
        id='name'
        value='count'
        cornerRadius={2}
        borderWidth={5}
        borderColor='white'
        colors={d => d.color}
        // childColor={{ from: 'color', modifiers: [] }}
        childColor="noinherit"
        animate={true}
        motionConfig='gentle'
        isInteractive={true}
      />
      {/* <ResponsiveContainer  aspect={3.0 / 2.0} width='100%' >
        <BarChart
          width={1200}
          height={400}
          data={Groupwisecallscount}
          style={{
            marginTop: "50px",
          }}
          barCategoryGap={"15%"}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis hide dataKey='ASSIGNMENT_GROUP' />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign='top' height={40} />
          <Bar dataKey='PrimarySPOCSuccessCalls' stackId="a" fill='#00FF00' />
          <Bar dataKey='PrimarySPOCFailureCalls' stackId="a" fill='#FF0000' />
          <Bar dataKey='SecondarySPOCSuccessCalls' stackId="b" fill='#136F63' />
          <Bar dataKey='SecondarySPOCFailureCalls' stackId="b" fill='#F34213' />
          <Bar dataKey='EscalationSPOCSuccessCalls' stackId="c" fill='#2a9d8f' />
          <Bar dataKey='EscalationSPOCFailureCalls' stackId="c" fill='#e76f51' />
        </BarChart>
      </ResponsiveContainer> */}
    </div>
  );
};

export default PseCallStatusChart;
