import React, { useRef, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import actions from '../../../redux/developerconnectportal/ios/actions';
import { UNSET_CLEAR_COUNTRIES_FLAG } from '../../../redux/developerconnectportal/ios/types';
import Select from "react-select";
import _ from 'lodash';


const CountriesSelector = (props) => {
  
  const observed = useRef(null);
    const flag = useSelector(state => state.iosdcp.clearTags);
    const dispatcher = useDispatch();
    const countries = _.map(props.reviews, review => review.Country);
    const countryCodes = _.sortBy(_.remove(_.uniq([].concat.apply([],countries)), null)).reverse();
    const options = countryCodes.map((country) => { 
      return  { value: country, label: country }
    })

    useEffect(() => {
      if(flag) {
          observed.current.select.clearValue()
          dispatcher({type: UNSET_CLEAR_COUNTRIES_FLAG })
      }
    }, [observed, flag]);

    return (
      <div>
        <Select
          ref={observed}
          isMulti
          placeholder="Countries"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.setSelectedCountries}
        />
      </div>
    );
}

const mapStateToProps = (state) => ({
  reviewsPerPage: state.iosdcp.reviewsPerPage,
  reviews: state.iosdcp.reviews,
  activePageNumber: state.iosdcp.paginator.activePage,
  clear: state.iosdcp.clearCountries
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCountries: (selectedCountries) => {
      
      dispatch(actions.setSelectedCountries(selectedCountries===null ? [] : selectedCountries))
    },
    unsetClear: () => {
      dispatch(actions.unsetClearCountriesFlag)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CountriesSelector);
