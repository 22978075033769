import React from "react";
import { useClearCache } from "react-clear-cache";

function AutomatedCallingReport () {

    const { emptyCacheStorage } = useClearCache();
    
    //reload 30 seconds once
    setInterval(() => {
        emptyCacheStorage();
    }, 1800000);

    return (
        <>     
            <iframe id="automatedcallingsystem-datastudio-iframe" title="automatedcallingsystemauditreport" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/f1af5b53-9d0b-495e-90e0-447259c832b6/page/9LehB" frameBorder="0" allowFullScreen></iframe>
        </>
    );
}

export default AutomatedCallingReport;