import {
  CREATE_FAILURE_SHMGMT_USER_PERMISSIONS,
  CREATE_REQUEST_SHMGMT_USER_PERMISSIONS,
  CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS,
  DELETE_FAILURE_SHMGMT_USER_PERMISSIONS,
  DELETE_REQUEST_SHMGMT_USER_PERMISSIONS,
  DELETE_SUCCESS_SHMGMT_USER_PERMISSIONS,
  LOAD_FAILURE_SHMGMT_USER_PERMISSIONS,
  LOAD_REQUEST_SHMGMT_USER_PERMISSIONS,
  LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS,
  UPDATE_FAILURE_SHMGMT_USER_PERMISSIONS,
  UPDATE_REQUEST_SHMGMT_USER_PERMISSIONS,
  UPDATE_SUCCESS_SHMGMT_USER_PERMISSIONS,

  /** load operators types */
  LOAD_FAILURE_SHMGMT_OPERATORS,
  LOAD_REQUEST_SHMGMT_OPERATORS,
  LOAD_SUCCESS_SHMGMT_OPERATORS,

  /** MODE */
  CHANGE_MODE,
  SET_EDIT_MODE,
  RESET_EDIT_MODE
} from "./types";

const setEditMode = (id, email, role) => {
  return {
    type: SET_EDIT_MODE,
    id: id,
    email: email,
    role: role
  }
}

const resetEditMode = () => {
  return {
    type: RESET_EDIT_MODE,
    id: '',
    email: '',
    role: ''
  }
}

const changeMode = (mode) => {
  return {
    type: CHANGE_MODE,
    payload: mode
  }
}

const loadRequestOperators = () => {
  return {
    type: LOAD_REQUEST_SHMGMT_OPERATORS
  }
};

const loadSuccessOperators = (payload) => {
  return {
    type: LOAD_SUCCESS_SHMGMT_OPERATORS,
    payload: payload
  }
};

const loadFailedOperators = () => {
  return {
    type: LOAD_FAILURE_SHMGMT_OPERATORS
  }
};

const loadRequestUserPermissions = () => {
  return {
    type: LOAD_REQUEST_SHMGMT_USER_PERMISSIONS,
    status: "loading",
  };
};

const loadSuccessUserPermissions = (payload) => {
  return {
    type: LOAD_SUCCESS_SHMGMT_USER_PERMISSIONS,
    roles: payload,
  };
};

const loadFailedUserPermissions = (error) => {
  return {
    type: LOAD_FAILURE_SHMGMT_USER_PERMISSIONS,
    error: error,
  };
};

const createRequestUserPermission = () => {
  return {
    type: CREATE_REQUEST_SHMGMT_USER_PERMISSIONS,
  };
};

const createSuccessUserPermission = () => {
  return {
    type: CREATE_SUCCESS_SHMGMT_USER_PERMISSIONS
    // payload: payload,
  };
};

const createFailedUserPermission = () => {
  return {
    type: CREATE_FAILURE_SHMGMT_USER_PERMISSIONS
    // error: error,
  };
};

export {
  loadRequestUserPermissions,
  loadSuccessUserPermissions,
  loadFailedUserPermissions,
  createRequestUserPermission,
  createSuccessUserPermission,
  createFailedUserPermission,
  loadRequestOperators,
  loadSuccessOperators,
  loadFailedOperators,
  changeMode,
  setEditMode,
  resetEditMode
};
