import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import { Hidden, StepContent, withWidth, isWidthUp } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SkipNextRoundedIcon from "@material-ui/icons/SkipNextRounded";
import _ from "lodash";
// import { connect } from "react-redux";
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  DoneRounded,
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";

/** import components */
import Platform from "../../../components/acsselfservice/v2/Platform";
import CallingWindowPicker from "../../../components/acsselfservice/v2/CallingWindowPicker";
import PrimaryContactSettingsPage from "../../../components/acsselfservice/v2/feature/shiftmanagement/primary/Settings";
import SecondaryContactSettingsPage from "../../../components/acsselfservice/v2/feature/shiftmanagement/secondary/Settings";
import EscalationContactSettingsPage from "../../../components/acsselfservice/v2/feature/shiftmanagement/escalation/Settings";
import NotificationSettingsPage from "../../../components/acsselfservice/v2/feature/shiftmanagement/notification/Settings";
import ViewIconButton from "../../../components/acsselfservice/v2/feature/shiftmanagement/view/ViewIconButton";

import { motion, AnimatePresence } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    color: "red",
  },
  nextButton: {
    marginRight: theme.spacing(1),
    color: "green",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Select platform for notification",
    "Select shift window for notification",
    "Configure primary call",
    "Configure secondary call",
    "Configure escalation call",
    "Configure other notification option",
  ];
}

export function StepperFormV2(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = getSteps();

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Platform next={handleNext}></Platform>;
      case 1:
        return (
          <CallingWindowPicker
            back={handleBack}
            next={handleNext}
          ></CallingWindowPicker>
        );
      case 2:
        return (
          <PrimaryContactSettingsPage back={handleBack} next={handleNext} />
        );
      case 3:
        return (
          <SecondaryContactSettingsPage back={handleBack} next={handleNext} />
        );
      case 4:
        return (
          <EscalationContactSettingsPage back={handleBack} next={handleNext} />
        );
      case 5:
        return (
          <NotificationSettingsPage back={handleBack} reset={handleReset} />
        );
      default:
        return "Welcome end";
    }
  };

  const handleNext = () => {
    const { issecondaryenabled, isescalationenabled, isprimaryenabled } = props;
    const add =
      activeStep === 2 && (!issecondaryenabled || isprimaryenabled)
        ? 3
        : activeStep === 3 && !isescalationenabled
        ? 2
        : 1;
    setActiveStep(activeStep + add);
  };

  const handleBack = () => {
    const { issecondaryenabled, isescalationenabled, isprimaryenabled } = props;
    const sub =
      activeStep === 5 && (!issecondaryenabled || isprimaryenabled)
        ? 3
        : activeStep === 5 && !isescalationenabled
        ? 2
        : 1;
    setActiveStep(activeStep - sub);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSkip = () => {
    setActiveStep(activeStep + 1);
  };

  const validate = (activeStep) => {
    const {
      isform1valid,
      isform2valid,
      isform3valid,
      isform4valid,
      isform5valid,
      isform6valid,
    } = props;
    switch (activeStep) {
      case 0:
        return isform1valid;
      case 1:
        return isform2valid;
      case 2:
        return isform3valid;
      case 3:
        return isform4valid;
      case 4:
        return isform5valid;
      case 5:
        return isform6valid;
      default:
        return false;
    }
  };
  // console.log("Width :: ", props);
  return (
    
    <>
      {activeStep !== 0 && <ViewIconButton />}

      <div className={classes.root}>
        {(isWidthUp('sm', props.width)) ? (
        // <Hidden only={["xs", "sm"]} implementation='css'>
        <>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <AnimatePresence className={classes.instructions}>
                  {getStepContent(activeStep)}
                </AnimatePresence>
              </div>
            )}
          </div>
          </>
        // </Hidden>
        ) : (
        // <Hidden only={["md", "lg"]} implementation='css'>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <AnimatePresence className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </AnimatePresence>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        // </Hidden>
        )}
      </div>
    </>
  );
}

const mapStatsToProps = (state) => {
  return {
    isprimaryenabled: state.shmgmt.isprimaryenabled,
    issecondaryenabled: state.shmgmt.issecondaryenabled,
    isescalationenabled: state.shmgmt.isescalationenabled,
    isform1valid: state.shmgmt.isform1valid,
    isform2valid: state.shmgmt.isform2valid,
    isform3valid: state.shmgmt.isform3valid,
    isform4valid: state.shmgmt.isform4valid,
    isform5valid: state.shmgmt.isform5valid,
    isform6valid: state.shmgmt.isform6valid,
  };
};

export default withWidth()(connect(mapStatsToProps, null)(StepperFormV2));

// export default withStyles(styles)(StepperFormV2);
// export default StepperFormV2;
