import { ADD_USERS_AND_ROLES_FAILED, ADD_USERS_AND_ROLES_SUCCESS, FETCH_USERS_AND_ROLE_FAILED, FETCH_USERS_AND_ROLE_SUCCESS, REQUEST_ADD_USERS_AND_ROLES ,REQUEST_UPDATE_USERS_AND_ROLES, REQUEST_USERS_AND_ROLES, UPDATE_USERS_AND_ROLES_FAILED, UPDATE_USERS_AND_ROLES_SUCCESS } from './types.js'

const initialState = {
    loading: false,
    users_and_role: [],
    error: false,
    notificationType: '',
    message: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case REQUEST_USERS_AND_ROLES:
            return {
                ...state,
                loading: true
            }
        case REQUEST_ADD_USERS_AND_ROLES:
            return {
                ...state,
                loading: true
            }
        case REQUEST_UPDATE_USERS_AND_ROLES:
            return {
                ...state,
                loading: true
            }
        case FETCH_USERS_AND_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                users_and_role: action.payload,
                error: false,
                message: ''
            }
        case FETCH_USERS_AND_ROLE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                message: action.payload.message
            }
        case ADD_USERS_AND_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                users_and_role: action.payload.roles,
                error: false,
                message: action.payload.message
            }
        default:
            return { ...state }
    }
}

export default reducer;