import { AUTHENTICATE_FAILED, AUTHENTICATE_REQUEST, AUTHENTICATE_SUCCESS, LOGOUT_FAILED, LOGOUT_REQUEST, LOGOUT_SUCCESS } from './types';

/** ACTIONS */
const authenticateRequest = () => {
    return {
        type: AUTHENTICATE_REQUEST
    }
}

const authenticateSuccess = (tokenObj) => {
    return {
        type: AUTHENTICATE_SUCCESS,
        payload: tokenObj
    }
}

const authenticateFailed = () => {
    return {
        type: AUTHENTICATE_FAILED
    }
}

const logoutRequest = () => {
    return {
        type: LOGOUT_REQUEST,
    }
}

const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
}

const logoutFailed = () => {
    return {
        type: LOGOUT_FAILED,
    }
}

export { authenticateSuccess, authenticateRequest, authenticateFailed, logoutRequest, logoutSuccess, logoutFailed };