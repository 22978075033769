import React from "react";

function IOSAppClassification() {

    return (
        <>
            {/* <iframe className="datastudio-iframe" title="iosappclassification" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/69233778-53b7-46a9-94ee-8795963b3da4/page/azjBB" frameBorder={0} allowFullScreen></iframe> */}
            <iframe className="datastudio-iframe" title="iosappclassification" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/57109a94-f5f7-4673-94b4-80b944b0cec3/page/azjBB" frameBorder={0} allowFullScreen></iframe>
        </>
    );
}

export default IOSAppClassification;