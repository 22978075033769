import debounce from "lodash/debounce";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form, withFormik } from "formik";
import * as Yup from "yup";

import {
  Input,
  Button,
  TextField,
  FormGroup,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Grid,
} from "@material-ui/core";

import {
  createOperator,
  updateMember,
} from "../../../../../../services/shiftmanagementrbac";
import { CLEAR_FILTER } from "../../../../../../redux/developerconnectportal/android/types";

const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: (props) =>
    Yup.object().shape({
      email: Yup.string()
        .matches(/(@ingka.com|@ingka.ikea.com|@inter.ikea.com)/, {
          message: "An email address should be a part of INGKA or INTER domain.",
          excludeEmptyString: true,
        })
        .email("Invalid email address")
        .required("Email is required!")
        .notOneOf(
          props.mode === "add"
            ? _.map(props.operators, (operator) => operator.email)
            : _.remove(
                _.map(props.operators, (operator) => operator.email),
                (o) => o !== props.email
              ),
          "You are already a member."
        ),
      role: Yup.string()
        .required("Role is required!")
        .oneOf(
          ["operator", "administrator"],
          "Please select a valid role (administrator/operator)."
        ),
    }),
  mapPropsToValues: (props) => {
    // console.log("Formik :: ", props)
    // console.log(" modified ", props.mode === 'add' ? _.map(props.operators, (operator) => operator.email) : _.remove(_.map(props.operators, operator => operator.email), o=> o === props.email))
    return {
      email: props.email,
      role: props.role,
    };
  },
  handleSubmit: (values, { setSubmitting, props }) => {
    const emails =
      props.mode === "add"
        ? _.map(props.operators, (operator) => operator.email)
        : _.remove(
            _.map(props.operators, (operator) => operator.email),
            (o) => o !== props.email
          );
    if (!emails.includes(values.email)) {
      const payload = {
        ...values,
      };
      const value = {
        operator: {
          email: values.email,
          platform: props.platform,
          group: props.agroup,
          role: values.role,
        },
        requester: props.user.email,
      };
      const action =
        props.operators.length === 0 && props.shifts.length === 0
          ? "add"
          : "extend";
      if (props.mode === "edit") {
        // console.log(props.id, value)
        props.updateMember(
          props.user.email,
          props.id,
          value.operator,
          props.platform,
          props.agroup,
          props.token
        );
      } else {
        props.createOperator(value, action, props.token);
      }
    } else {
      alert("You are already added in operator list.");
    }
  },
  displayName: "Operator Management Console",
});

const handleFormReset = (handleReset) => {
  // store.dispatch(resetMessage());
  handleReset();
};

const Settings = (props) => {
  const {
    values,
    touched,
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    isSubmitting,
    issubmitted,
  } = props;
  const isAdminOrSuperUser = _.filter(props.myroles, (role) => {
    // console.log(props.stateObject.assignmentGroup);
    return (
      ["superuser", "administrator"].includes(role.role) &&
      (role.group === props.agroup || role.group === "all")
    );
  }).length;
  const isOperator = _.filter(props.myroles, (role) => {
    // console.log(props.stateObject.assignmentGroup);
    return (
      ["operator"].includes(role.role) &&
      (role.group === props.agroup || role.group === "all")
    );
  }).length;
  return (
    <div>
      <h5>Member Information</h5>

      <Form onSubmit={handleSubmit}>
        <FormGroup row={true}>
          <Grid
            container
            direction={"row"}
            spacing={5}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <TextField
                name="email"
                label="Email"
                type="email"
                placeholder="Enter an email address."
                style={{ minWidth: 200 }}
                errors={errors.email}
                value={values.email}
                touched={`${touched.email}`}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email}
                error={Object.keys(errors).includes("email") && touched.email}
                // validateField={validateField}
              />
            </Grid>
            <Grid item>
              <FormControl
                style={{ minWidth: 150, maxWidth: 300 }}
                error={Object.keys(errors).includes("role") && touched.role}
              >
                <InputLabel id="role-select-helper-label">Role</InputLabel>
                <Select
                  labelId="role-select-helper-label"
                  id="role-select-helper-label"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="administrator">Administrator</MenuItem>
                  <MenuItem value="operator">Operator</MenuItem>
                </Select>
                <FormHelperText>{errors.role}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                type="button"
                className="outline"
                onClick={() => handleFormReset(handleReset)}
                disabled={!dirty || issubmitted}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={
                  issubmitted ||
                  (isAdminOrSuperUser === 0 &&
                    isOperator > 0 &&
                    props.shifts.length === 0)
                }
              >
                {props.mode === "add" ? "Save" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  agroup: state.shmgmt.assignmentGroup,
  platform: state.shmgmt.selectedPlatformItem,
  myroles: state.shrbac.roles,
  mode: state.shrbac.mode,
  operator: state.shrbac.operator,
  operators: state.shrbac.operators,
  issubmitted: state.shrbac.isSubmitting,
  user: state.usr.user,
  token: state.au.token,
  shifts: state.shmgmt.shifts,
  id: state.shrbac.id,
  email: state.shrbac.email,
  role: state.shrbac.role,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createOperator: (operator, add, token) => {
      dispatch(createOperator(operator, add, token));
    },
    updateMember: (requester, id, user, platform, group, token) => {
      dispatch(updateMember(requester, id, user, platform, group, token));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formikEnhancer(Settings));
