import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { DashboardSharp } from '@material-ui/icons';

import DateFnsUtils from "@date-io/moment";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

// import redux - state - actions
import {
  updateShiftStartTime,
  updateShiftEndTime,
  updateFormValidation,
} from "../../../redux/shiftmanagement/actions";

import { enqueueSnackbar as enqueueSnackbarAction } from "../../../redux/notification/actions";

// import modules from submodules.
import ShiftManagementTable from "./feature/shiftmanagement/table/ShiftManagmentTable";

import "./CallingWindowPicker.css";
import { Button } from "@material-ui/core";

class CallingWindowPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }
  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const formproperty = "isform2valid";
    const { startTime, endTime, shifts } = this.props;
    let status = false;
    if (["add"].includes(this.props.mode)) {
      _.forEach(shifts, (shift) => {
        const sstartTime = moment()
          .hours(shift.startTime.split(":")[0])
          .minutes(shift.startTime.split(":")[1])
          .seconds(0);
        const sendTime = moment()
          .hours(shift.endTime.split(":")[0])
          .minutes(shift.endTime.split(":")[1])
          .seconds(0);
        // const condition1 = (startTime.isSameOrAfter(sstartTime) && endTime.isSameOrAfter(sendTime))
        const condition1 =
          sstartTime.isSameOrBefore(startTime) &&
          startTime.isSameOrBefore(sendTime);
        const condition2 =
          sstartTime.isSameOrBefore(endTime) &&
          endTime.isSameOrBefore(sendTime);
        const condition3 =
          sstartTime.isSameOrAfter(startTime) &&
          endTime.isSameOrAfter(sendTime);
        if (condition1 || condition2 || condition3) {
          status = true;
        }
      });
    }
    if (status) {
      this.props.enqueueSnackbar({
        message: "This timing is already a part of registered shifts.",
        options: {
          preventDuplicate: true,
          key: "shiftalreadyselected",
          variant: "default",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      });
    }
    const valid = startTime < endTime && !status;
    if (valid) {
      this.props.enqueueSnackbar({
        message: "The selected timing is available.",
        options: {
          preventDuplicate: true,
          key: "shiftnotselected",
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      });
    }
    this.props.updateFormValidation({ name: formproperty, value: valid });
  };

  handleShiftStartTime = (time) => {
    if (this.props.endTime > time) {
      this.props.updateShiftStartTime(time);
    } else {
      alert("shift start time should be below end time");
    }
  };

  handleShiftEndTime = (time) => {
    if (this.props.startTime < time) {
      this.props.updateShiftEndTime(time);
    } else {
      alert("shift end time should be above start time");
    }
  };

  render() {
    this.validate();
    return (
      <>
        <motion.div
          style={{ minHeight: "55vh" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <div
            className="calling-window-picker-container"
            style={{ color: "gray" }}
          >
            <h3>Choose calling window</h3>
          </div>
          <div className="calling-window-picker-container">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <>
                <TimePicker
                  required
                  margin="normal"
                  id="time-picker"
                  label="Start Time"
                  name="starttime"
                  autoOk
                  ampm={false}
                  value={this.props.startTime}
                  onChange={this.handleShiftStartTime}
                  disabled={["edit"].includes(this.props.mode)}
                  keyboardbuttonprops={{
                    "aria-label": "change time",
                  }}
                />
                &nbsp;
                <TimePicker
                  required
                  margin="normal"
                  id="time-picker"
                  label="End Time"
                  name="endtime"
                  autoOk
                  ampm={false}
                  value={this.props.endTime}
                  onChange={this.handleShiftEndTime}
                  disabled={["edit"].includes(this.props.mode)}
                  keyboardbuttonprops={{
                    "aria-label": "change time",
                  }}
                />
              </>
            </MuiPickersUtilsProvider>
          </div>
          <Container>
            <ShiftManagementTable />
          </Container>
        </motion.div>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ padding: "0px 10px" }}
            spacing={2}
          >
            <Button
              startIcon={<NavigateBeforeOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.back();
              }}
            >
              Previous
            </Button>
            <Button
              disabled={!this.props.isform2valid}
              endIcon={<NavigateNextOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.next();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStatsToProps = (state) => {
  return {
    platform: state.shmgmt.selectedPlatformItem,
    startTime: state.shmgmt.startTime,
    endTime: state.shmgmt.endTime,
    shifts: state.shmgmt.shifts,
    isform2valid: state.shmgmt.isform2valid,
    mode: state.shmgmt.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShiftStartTime: (time) => {
      dispatch(updateShiftStartTime(time));
    },
    updateShiftEndTime: (time) => {
      dispatch(updateShiftEndTime(time));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload));
    },
    enqueueSnackbar: (...args) => {
      dispatch(enqueueSnackbarAction(...args));
    },
  };
};

export default connect(
  mapStatsToProps,
  mapDispatchToProps
)(CallingWindowPicker);
