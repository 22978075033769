import {
    CLEAR_SELECTED_CONTACT_FAILED,
    CLEAR_SELECTED_CONTACT_REQUEST,
    CLEAR_SELECTED_CONTACT_SUCCESS,
    UPDATE_SELECTED_CONTACT_FAILED,
    UPDATE_SELECTED_CONTACT_REQUEST,
    UPDATE_SELECTED_CONTACT_SUCCESS
} from './types';

const initialState = {
    selectedContact: {

    }
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case CLEAR_SELECTED_CONTACT_FAILED:
            return {
                ...state,
                selectedContact: {}
            }
        case CLEAR_SELECTED_CONTACT_REQUEST:
            return {
                ...state,
                selectedContact: {}
            }
        case CLEAR_SELECTED_CONTACT_SUCCESS:
            return {
                ...state,
                selectedContact: {}
            }
        case UPDATE_SELECTED_CONTACT_FAILED:
            return {
                ...state,
                selectedContact: {}
            }
        case UPDATE_SELECTED_CONTACT_REQUEST:
            return {
                ...state,
                selectedContact: {}
            }
        case UPDATE_SELECTED_CONTACT_SUCCESS:
            return {
                ...state,
                selectedContact: action.payload
            }
        default:
            return {...state}
    }
}

export default reducer;