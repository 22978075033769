import {
    AUTHORIZATION_FAILED,
    AUTHORIZATION_SUCCESS,
    AUTHORIZATION_REQUEST
} from './types';

const initialState = {
    loading: false,
    roles: [],
    error: false,
    errorMessage: ''
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case AUTHORIZATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: ''
            }
        case AUTHORIZATION_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                loading: false,
                error: false,
                errorMessage: ''
            }
        case AUTHORIZATION_FAILED:
            return {
                ...state,
                roles: [],
                loading: false,
                error: true,
                errorMessage: action.payload
            }
        default:
            return state
    }
}

export default reducer;