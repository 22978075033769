import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import { connect } from 'react-redux';

/** import actions from reducers */
import {  authenticateSuccess, logoutSuccess } from '../../../redux/authentication/actions';
import { userFetchSuccess } from '../../../redux/user/actions';

// import './Navbar.css';
import './CustomNavBar.css';


class CustomNavBar extends Component {
  constructor(props) {
    super(props);
  }

  loginSuccessHandler = (successResponse) => {
    const { profileObj, tokenObj } = successResponse;
    this.props.updateUserProfile(profileObj);
    this.props.loginSuccessHandler(tokenObj);
  }

  loginFailureHandler = (failureResponse) => {
    alert("You are not authenticated successfully. Please try again later.");
  }

  logoutHandler = () => {
    this.props.logoutSuccessHandler();
    this.props.history.push('/');
  }

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          size="small"
          expand="lg"
          bg="primary"
          variant="dark"
          style={{ opacity: 0.9, zIndex:100 }}
        >
          <Navbar.Brand onClick={() => {
            this.props.history.push('/')
          }}>
            <img
              src="https://www.ikea.com/se/sv/static/ikea-logo.f88b07ceb5a8c356b7a0fdcc9a563d63.svg"
              // src={iObserveLogo}
              alt="IKEA"
              // style={{width: '250px', height: '70px'}}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {this.props.isAuthenticated === true ? (
                <>
                  <NavDropdown
                    title="Web Traffic Report"
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/web/traffic2");
                      }}
                    >
                      Web Traffic Prediction Report
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/web/traffic/24h");
                      }}
                    >
                      Web Traffic Prediction Report (24hrs)
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="IKEA App"
                    id="collasible-nav-dropdown"
                    // disabled={true}
                  >
                    {/* menu 1 start */}
  
                    {/* <NavDropdown.Item onSelect={() => {
                      this.props.history.push('/pages/android/developerconnect')
                    }}>
                    </NavDropdown.Item> */}
                    <NavDropdown
                      style={{color: 'black', textAlign: 'center'}}
                      drop="right"
                      key="drop2"
                      title="Android"
                      id="collasible-nav-dropdown-automatedcallingsystem"
                      renderMenuOnMount={true}
                      // disabled={true}
                    >
                      
                      <NavDropdown.Item
                        onSelect={() => {
                          this.props.history.push("/report/android/response");
                        }}
                      >
                        Review Response
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onSelect={() => {
                          this.props.history.push("/report/android/classification");
                        }}
                      >
                       Negative Review Classification
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onSelect={() => {
                          this.props.history.push("/pages/nreviews/android/connectportal");
                        }}
                      >
                       Developer Connect
                      </NavDropdown.Item>
                    </NavDropdown>
                    
                    {/* menu 1 end */}
                    <NavDropdown.Divider />
                   {/* menu 2 start */}
  
                   {/* <NavDropdown.Item onSelect={() => {
                      this.props.history.push('/pages/ios/developerconnect')
                    }}>
                    </NavDropdown.Item> */}
                    <NavDropdown
                      // disabled={true}
                      style={{color: 'black', textAlign: 'center'}}
                      drop="right"
                      key="drop3"
                      title="iOS"
                      id="collasible-nav-dropdown-automatedcallingsystem"
                      renderMenuOnMount={true}
                    >
                      
                      <NavDropdown.Item
                        onSelect={() => {
                          this.props.history.push("/report/ios/response");
                        }}
                      >
                        Review Response
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onSelect={() => {
                          this.props.history.push("/report/ios/classification");
                        }}
                      >
                       Negative Review Classification
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        // disabled={true}
                        onSelect={() => {
                          this.props.history.push("/pages/nreviews/ios/connectportal");
                        }}
                      >
                       Developer Connect
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Menu 2 end */}
                    {/* <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/android/response");
                      }}
                    >
                      Android Review Response
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/android/classification");
                      }}
                    >
                      Android Classification
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/ios/response");
                      }}
                    >
                      iOS Review Response
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/ios/classification");
                      }}
                    >
                      iOS Classification
                    </NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Database Report"
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/web/databaseoutage");
                      }}
                    >
                      Database Outage Report
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/web/databaseloginanomaly");
                      }}
                    >
                      Database Login Anomaly Report
                    </NavDropdown.Item>
                  </NavDropdown>
  
                  <NavDropdown
                    title="Automated Calling &amp; Notification"
                    id="collasible-nav-dropdown"
                    // disabled={true}
                  >
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/pages/acs/selfservice/v4.1/onboarding");
                      }}
                    >
                      ACS - Self Onboarding (version 4.1)
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/acs/audit");
                      }}
                    >
                      Automated Calling Report
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      disabled={true}
                      onSelect={() => {
                        this.props.history.push("/report/acn/status/idesk");
                      }}
                    >
                      Automated Team Report
                    </NavDropdown.Item> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Reports"
                    id="collasible-nav-dropdown"
                    // disabled={true}
                  >
                    <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/okr");
                      }}
                    >
                      OKR Dashboard
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      onSelect={() => {
                        this.props.history.push("/report/acs/audit");
                      }}
                    >
                      Automated Calling Report
                    </NavDropdown.Item> */}
                    {/* <NavDropdown.Item
                      disabled={true}
                      onSelect={() => {
                        this.props.history.push("/report/acn/status/idesk");
                      }}
                    >
                      Automated Team Report
                    </NavDropdown.Item> */}
                  </NavDropdown>
                </>
              ) : null}
            </Nav>
            <Nav>
              {this.props.isAuthenticated === false ? (
                <GoogleLogin
                  clientId="431715193621-k7ot3jju439fdpdbtbl4rhqum1cb8mj4.apps.googleusercontent.com"
                  buttonText="Login"
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      style={{color: 'white'}}
                    >
                      Login
                    </Button>
                  )}
                  onSuccess={this.loginSuccessHandler}
                  onFailure={this.loginFailureHandler}
                  cookiePolicy={"single_host_origin"}
                />
              ) : (
                <Button style={{color: 'white'}} onClick={this.logoutHandler}>Logout</Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    )
  }
}

const mapStatsToProps = state => {
  return {
    isAuthenticated: state.au.authenticated,
    token: state.au.token
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loginSuccessHandler: (tokenObject) => {
      dispatch(authenticateSuccess(tokenObject))
    },
    updateUserProfile:(user) => {
      dispatch(userFetchSuccess(user))
    },
    loginFailureHandler: () => {

    },
    logoutSuccessHandler: () => {
      dispatch(logoutSuccess())
    },
    logoutFailureHandler: () => {

    }
  }
}

export default connect(mapStatsToProps, mapDispatchToProps)(withRouter(CustomNavBar));
