import React, { Component } from "react";
import { connect } from "react-redux";

import RBACTable from "./features/RBACTable";
import RBACForm from "./features/RBACForm";

import { getMyRoles } from '../../../services/shiftmanagementrbac';

export class Settings extends Component {
  render() {
    return (
      <>
        <RBACForm></RBACForm>
        <RBACTable></RBACTable>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    user: state.usr.user,
    token: state.au.token
})

const mapDispatchToProps = (dispatch) => {
  return {
    getMyRoles: (email, token) => {
      dispatch(getMyRoles(email, token));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)