import axios from "axios";
import moment from "moment";

/** actions for rbac system */
import {
  loadRequestUserPermissions,
  loadFailedUserPermissions,
  loadSuccessUserPermissions,
  createRequestUserPermission,
  createSuccessUserPermission,
  createFailedUserPermission,
  loadRequestOperators,
  loadSuccessOperators,
  loadFailedOperators,
  resetEditMode
} from "../redux/shiftmanagementrbac/actions";

/** actions for notification system */
import { enqueueSnackbar as enqueueSnackbarAction } from "../redux/notification/actions";

const API_BASE_URL = "https://predictions.oi.ingka.com";
// const API_BASE_URL = "http://localhost:8080";

const createOperator = (operator, action, token) => {
  const createUrl =
    action === "add"
      ? `${API_BASE_URL}/api/v1/acs/management/access/member`
      : `${API_BASE_URL}/api/v1/acs/management/access/operator`;
  const { requester } = operator;
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    dispatch(createRequestUserPermission());
    axios
      .post(createUrl, operator, {
        headers: {
          "content-type": "application/json",
          "x-access-token": token
        },
      })
      .then((response) => {
        dispatch(createSuccessUserPermission());
        const { status, data } = response;
        if (status === 200) {
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: status === 200 ? "success" : "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
          dispatch(getMyRoles(requester, token));
          dispatch(
            getUsersAndRoles(
              operator.operator.platform,
              operator.operator.group,
              requester,
              token
            )
          );
        } else {
          dispatch(createFailedUserPermission());
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: status === 200 ? "success" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        }
      })
      .catch((error) => {
        dispatch(createFailedUserPermission());
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

const deleteOperatorById = (id, platform, group, requester) => {
  return (dispatch) => {
    const deleteUrl = `${API_BASE_URL}/api/v1/acs/management/access/operator/${id}`;
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    axios
      .delete(deleteUrl, { params: { requester: requester } })
      .then((response) => {
        // console.log(response);
        dispatch(getOperators(platform, group, requester));
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

const getOperators = (platform, group, requester) => {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    enqueueSnackbar({
      message: "loading operators. This might take few second.",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    });
    dispatch(loadRequestOperators());
    axios({
      url: `${API_BASE_URL}/api/v1/acs/management/access/operators/platform/${platform}/group/${group}`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
      params: { requester: requester },
    })
      .then((response) => {
        const { data } = response;
        dispatch(loadSuccessOperators(data.operators));
      })
      .catch((error) => {
        dispatch(loadFailedOperators());
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 404) {
            dispatch(loadSuccessOperators([]));
          }
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

const getMyRoles = (email, token) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  return (dispatch) => {
    axios({
      url: `${API_BASE_URL}/api/v1/acs/management/access/role/mine`,
      method: "GET",
      headers: { "Content-Type": "application/json", "x-access-token": token },
      params: { requester: email },
    })
      .then((response) => {
        const { data } = response;
        // console.log(response);
        dispatch(loadSuccessUserPermissions(data.roles));
      })
      .catch((error) => {
        // console.log(error);
      });
  };
};

const getUsersAndRoles = (platform, group, requester, token) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  const url = `${API_BASE_URL}/api/v1/acs/management/access/role/user/platform/${platform}/group/${group}/list`;
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    enqueueSnackbar({
      message: "loading operators. This might take few second.",
      options: {
        key: 'loadinguserrole',
        variant: "default",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    });
    dispatch(loadRequestOperators());
    axios({
      url: url,
      method: "GET",
      headers: { "Content-Type": "application/json", "x-access-token": token },
      params: { requester: requester },
    })
      .then((response) => {
        const { data } = response;
        dispatch(loadSuccessOperators(data.members));
      })
      .catch((error) => {
        dispatch(loadFailedOperators());
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 404) {
            dispatch(loadSuccessOperators([]));
          }
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

const deleteMemberById = (id, platform, group, requester, token) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  return (dispatch) => {
    const deleteUrl = `${API_BASE_URL}/api/v1/acs/management/access/role/user/${id}/delete`;
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    axios
      .delete(deleteUrl, { params: { requester: requester }, ...headers })
      .then((response) => {
        enqueueSnackbar({
          message: response.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        });
        dispatch(getMyRoles(requester, token));
        dispatch(getUsersAndRoles(platform, group, requester, token));
      })
      .catch((error) => {
        if (error.response) {
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

const updateMember = (requester, id, user, platform, group, token) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  // console.log(requester, id, user, platform, group)
  return (dispatch) => {
    const updateUrl = `${API_BASE_URL}/api/v1/acs/management/access/role/user/${id}/update`;
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    axios
      .put(updateUrl, { user: {...user}},{ params: { requester: requester }, ...headers })
      .then((response) => {
        enqueueSnackbar({
          message: response.data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        });
        dispatch(resetEditMode());
        dispatch(getUsersAndRoles(platform, group, requester, token));
        dispatch(getMyRoles(requester, token));
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          enqueueSnackbar({
            message: error.response.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: error.response.status === 404 ? "info" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error', error.message);
        }
      });
  };
};

export {
  getMyRoles,
  getOperators,
  createOperator,
  deleteOperatorById,
  getUsersAndRoles,
  deleteMemberById,
  updateMember
};
