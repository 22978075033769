import React from 'react'
import './BasicFooter.css'

function getYear() {
    return new Date().getFullYear();
}

function BasicFooter() {
    return (
        <div className="basic__footer">
            <p>Copyright © {getYear()} Operation Intelligence (IKEA)</p>
        </div>
    )
}

export default BasicFooter;
