import React from "react";

function AndroidAppResponse () {
    return (
        <>
            <iframe className="datastudio-iframe" title="androidappresponse" style={{ height: '98vh', width: '98vw', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/e18debe7-b9c6-4669-9ea8-d59c13cda694/page/azjBB" frameBorder="0" allowFullScreen></iframe>
        </>
    );
}

export default AndroidAppResponse;