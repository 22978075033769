import React, { useState, useEffect } from "react";
import {NativeSelect, FormControl} from '@material-ui/core'

const RangePicker = ({handlePeriodChange, range}) => {
    return (
        <FormControl style={{
            width: '30%',
            marginBottom: '30px !important'
        }}>
            <NativeSelect defaultValue={range} onChange={(e) => handlePeriodChange(e.target.value)}>
                {/* <option value='hour=1'>1 Hour</option> */}
                <option value='hour=24'>24 Hour</option>
                <option value='day=7'>7 Days</option>
                <option value='day=30'>30 Days</option>
                <option value='week=12'>Last Quarter</option>
            </NativeSelect>
        </FormControl>
    )
}

export default RangePicker