import React, { Component, createRef } from "react";
import { connect } from "react-redux";
// import { reset_contact } from "../_reducer/authenticator.reducer";
import ContactImg from "../icons/user.png";
import CallImg from "../icons/contact.png";
import PersonImg from "../icons/person.png";

// import Form from "react-bootstrap/Form"; 
// import Col from "react-bootstrap/Col";
// import InputGroup from "react-bootstrap/InputGroup";
// import FormControl from "react-bootstrap/FormControl";
// import Button from "react-bootstrap/Button";
// import Toast from "react-bootstrap/Toast";

import { Form, Col, InputGroup, FormControl, Button, Toast } from "react-bootstrap";

import firebase from "../firebase";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.Formref = createRef();
    this.formIsValid = this.formIsValid.bind(this);


    this.state = {
      SupportGrpName: "",
      PrimaryNumber: "",
      SecondaryNumber: "",
      Escalation: "",
      LastModifiedBy: "",
      isUpdate: false,
      show1: false,
      show2: false,
      show3: false,
    };
  }


  

  componentDidMount() {
    this.setState({
      LastModifiedBy: this.props.user.email,
    });
    this.setState({
      ...this.props.selectedContact,
    });
    // console.log("ContactForm", this.props)
    const db = firebase.firestore();
    db.collection("Contacts")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        //console.log(data);

        this.setState({
          SupportGrpName: this.state.SupportGrpName,
          PrimaryNumber: this.state.PrimaryNumber,
          SecondaryNumber: this.state.SecondaryNumber,
          Escalation: this.state.Escalation,
          // LastModifiedBy: this.state.LastModifiedBy,
          Records: data,
        });
      });
      // console.log(this.state);

  }

  changeState = (state) => {
    // console.log(state)
    this.setState({
      ...state,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  formIsValid = () => {
    if (
      (this.state.PrimaryNumber.length === 11 ||
        this.state.PrimaryNumber.length === 12) &&
      /^-?\d+$/.test(this.state.PrimaryNumber) &&
      (this.state.SecondaryNumber.length === 11 ||
        this.state.SecondaryNumber.length === 12) &&
      /^-?\d+$/.test(this.state.SecondaryNumber) &&
      (this.state.Escalation.length === 11 ||
        this.state.Escalation.length === 12) &&
      /^-?\d+$/.test(this.state.Escalation)
    ) {
      return true;
    } else {
      alert("Phone number is invalid");
      return false;
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    // console.log(this.props.selectedContact.isUpdate);
    if (this.formIsValid()) {
      var userRef = null;
      //console.log("Inside handleFormSubmit");
      const db = firebase.firestore();
      db.settings({});
      if (this.props.selectedContact.isUpdate === false) {
        // userRef = db.collection("Contacts").add({

        //     SupportGrpName: this.state.SupportGrpName,
        //     PrimaryNumber: this.state.PrimaryNumber,
        //     SecondaryNumber: this.state.SecondaryNumber,
        //     Escalation: this.state.Escalation,
        //     LastModifiedBy: this.state.LastModifiedBy

        // });
        alert("The save option is disabled.");
      } else {
          // console.log(this.state);
        userRef = db.collection("Contacts").doc(this.props.selectedContact.docKey).set({
          SupportGrpName: this.props.selectedContact.SupportGrpName,
          PrimaryNumber: this.state.PrimaryNumber,
          SecondaryNumber: this.state.SecondaryNumber,
          Escalation: this.state.Escalation,
          LastModifiedBy: this.state.LastModifiedBy,
        });
      }

      e.target.reset();
      this.setState({
        SupportGrpName: " ",
        PrimaryNumber: " ",
        SecondaryNumber: " ",
        Escalation: " ",
        // LastModifiedBy: "",
        isUpdate: false,
      });

      this.props.clearSelectedContact();

      this.props.reload();
      if (userRef !== null) {
        alert("Record has been submitted successfully");
      } else {
        alert("Record was not submitted");
      }
    }
  };
  render() {
    return (
      <>
      <Form
        ref={this.Formref}
        autoComplete="off"
        onSubmit={this.handleFormSubmit}
      >
        <Form.Row>
          <Form.Group as={Col} md="7">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <img
                    alt="basic-addon1"
                    src={ContactImg}
                    height="20"
                    width="20"
                  ></img>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly={this.props.selectedContact.isUpdate}
                placeholder="Support Group Name"
                type="text"
                name="SupportGrpName"
                value={this.props.selectedContact.SupportGrpName}
                //onChange={handleInputChange}
                onChange={this.handleInputChange}
                required
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon2">
                  <img
                    alt="callimage"
                    src={CallImg}
                    height="20"
                    width="20"
                  ></img>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Primary Contact"
                type="text"
                name="PrimaryNumber"
                onChange={this.handleInputChange}
                onFocus={() =>
                    this.setState({
                      show1: true,
                    })
                  }
                required
              />
              <Toast
                onClose={() =>
                  this.setState({
                    show1: false,
                  })
                }
                show={this.state.show1}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  <strong className="mr-auto">Primary Contact</strong>
                  <small>example</small>
                </Toast.Header>
                <Toast.Body>
                  Kindly enter the phone number with country code. ex :
                  919900274241
                </Toast.Body>
              </Toast>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">
                  <img
                    alt="secondary_number"
                    src={CallImg}
                    height="20"
                    width="20"
                  ></img>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Secondary Contact"
                type="text"
                name="SecondaryNumber"
                onChange={this.handleInputChange}
                onFocus={() =>
                  this.setState({
                    show2: true,
                  })
                }
                required
              />
              <Toast
                onClose={() =>
                  this.setState({
                    show2: false,
                  })
                }
                show={this.state.show2}
                delay={5000}
                autohide
              >
                <Toast.Header>
                  {/* <img alt="secondary_contact" src="holder.js/20x20?text=%20" className="rounded mr-2" /> */}
                  <strong className="mr-auto">Secondary Contact</strong>
                  <small>example</small>
                </Toast.Header>
                <Toast.Body>
                  Kindly enter the phone number with country code. ex :
                  919900274241
                </Toast.Body>
              </Toast>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon4">
                  <img
                    alt="escalation_contact"
                    src={CallImg}
                    height="20"
                    width="20"
                  ></img>
                </InputGroup.Text>
              </InputGroup.Prepend>
              
              <FormControl
                placeholder="Escalation Contact"
                type="text"
                name="Escalation"
                onChange={this.handleInputChange}
                onFocus={() => this.setState({
                    show3: true
                })}
                required
            />
            <Toast onClose={() => this.setState({
                show3: false
            })} show={this.state.show3} delay={5000} autohide>
                <Toast.Header>
                  
                    <strong className="mr-auto">Escalation Contact</strong>
                    <small>example</small>
                </Toast.Header>
                <Toast.Body>Kindly enter the phone number with country code. ex : 919900274241</Toast.Body>
            </Toast>
             
              
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon6">
                  <img
                    alt="person_image"
                    src={PersonImg}
                    height="20"
                    width="20"
                  ></img>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly={true}
                placeholder="Last Modified By"
                type="text"
                name="LastModifiedBy"
                value={this.state.LastModifiedBy}
                onChange={this.handleInputChange}
                required
              />
            </InputGroup>

            <Button
              className="btn btn-primary btn-large centerButton"
              type="submit"
              disabled={!(Object.keys(this.props.selectedContact).length > 0)}
            >
              {this.props.selectedContact.isUpdate === false
                ? "Save"
                : "Update"}
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
</>
);
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    // isLoggedIn: state.counter.authenticator.isLoggedIn,
    user: state.usr.user,
    // token: state.au.token,
    selectedContact: state.acsssp.selectedContact
  };
};

const mapDispatchToProps = (dispatch) => {
  // return {
  //   clearSelectedContact: () => {
  //     // dispatch(reset_contact());
  //   },
  // };
};

export default connect(mapStateToProps, null)(ContactForm);
