import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";

import _ from 'lodash';

const CustomTooltip = ({ active, payload }) => {
  // console.log( payload);
  if (active && payload) {
    const {
      payload: { GroupName, CallType, callscount },
    } = payload[0];
    return (
      <div
        className='chart-custom-tooltip'
        style={{
          border: "1px solid #000",
          backgroundColor: "#fff",
          padding: "20px 20px 0",
          fontSize: "12px",
        }}
      >
        <p className='desc'>Group Name: {`${GroupName}`} </p>
        <p className='desc'>Call Type: {`${CallType}`} </p>
        <p className='desc'>Call Count: {`${callscount}`} </p>
      </div>
    );
  }

  return null;
};

const PseChart = ({ data }) => {
  // console.log("props", data);

  if (data === 'undefined' || data === null) {
    return <div>Error</div>
  }

  if (Object.keys(data).length === 0) {
    return <div>Loading</div>;
  }

  

  const { Durationwisecallscount } = data;

  //   const {GroupName, callscount} = Groupwisecallscount;
  return (
    // <ResponsiveContainer  aspect={3.0 / 2.0} width='100%' height='1000px'>
    <BarChart
      width={600}
      height={400}
      data={_.orderBy(Durationwisecallscount, ['Duration'])}
      style={{
        marginTop: "50px",
      }}
      barCategoryGap={"15%"}

    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='Duration' >
        <Label angle={270} position='left' style={{ textAnchor: 'middle' }} />
      </XAxis>
      <YAxis />
      {/* <Tooltip content={<CustomTooltip />} /> */}
      <Tooltip  />
      <Legend verticalAlign='top' height={40} />
      <Bar dataKey='PrimarySPOC' stackId="a" fill='#0058A3' />
      <Bar dataKey='SecondarySPOC' stackId="a" fill='#CA5008' />
      <Bar dataKey='EscalationSPOC' stackId="a" fill='#FFA524' />
    </BarChart>
    // </ResponsiveContainer>
  );
};

export default PseChart;
