import axios from "axios";
import moment from "moment";
/** actions for service */
import {
  loadShiftRequest,
  loadShiftSuccess,
  loadShiftFailed,
  deleteShiftRequest,
  deleteShiftSuccess,
  deleteShiftFailed,
  createShiftRequest,
  createShiftSuccess,
  createShiftFailed,
  updateShiftRequest,
  updateShiftFailed,
  updateShiftSuccess,
  updateShiftStateWithDefaultShiftSchema,
  updateFormSubmitStatus,
  setJiraProjects,
  setIdeskAssignmentGroups
} from "../redux/shiftmanagement/actions";

import { enqueueSnackbar as enqueueSnackbarAction } from "../redux/notification/actions";

const defaultShiftStateObject = {
  isform2valid: false,
  isform3valid: false,
  isform4valid: false,
  isform5valid: false,
  isform6valid: false,
  mode: "add",
  id: "",
  startTime: moment().hours(0).minutes(0).seconds(0),
  endTime: moment().hours(23).minutes(59).seconds(0),
  isenabledview: false,
  issecondaryenabled: false,
  isescalationenabled: false,
  isapnenabled: false,
  isrpnenabled: false,
  isasnenabled: false,
  isrsnenabled: false,
  isaismsenabled: false,
  isaiaudioenabled:false,
  isopsbridgeenabled: false,
  primarycontactnumber: "",
  alternateprimarycontactnumber: "",
  secondarycontactnumber: "",
  alternatesecondarycontactnumber: "",
  escalationcontactnumber: "",
  intervalp2s: 0,
  intervals2e: 0,
  intervalp2ap: 0,
  intervals2as: 0,
  intervalp2rp: 0,
  intervals2rs: 0,
  issmsenabled: false,
  sms_numbers: [],
  smsfor: [],
  isteamsenabled: false,
  teamsWebhookUrl: "",
  teamsfor: [],
  isslackenabled: false,
  slackWebhookUrl: "",
  slackfor: [],
  iswatsappenabled: false,
  whatsapp_numbers: [],
  whatsappfor: [],
};

const deleteShiftRecord = (id, tool, agroup, jitype, token, mode) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    dispatch(deleteShiftRequest());
    const deleteUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/${id}`;
    axios
      .delete(deleteUrl, headers)
      .then((response) => {
        dispatch(deleteShiftSuccess());
        enqueueSnackbar({
          message: "shift is deleted",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
        });
        if (["edit"].includes(mode)) {
          dispatch(
            updateShiftStateWithDefaultShiftSchema(defaultShiftStateObject)
          );
        }
        dispatch(loadShiftRequest());
        let loadUrl = "";
        if (["jira"].includes(tool)) {
          loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}/type/${jitype}`;
        } else {
          loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}`;
        }
        axios
          .get(loadUrl, headers)
          .then(({ status, data }) => {
            dispatch(loadShiftSuccess(data.shifts));
          })
          .catch((error) => {
            dispatch(loadShiftFailed(""));
          });
      })
      .catch((error) => [dispatch(deleteShiftFailed())]);
  };
};

const listShiftRecord = (tool, agroup, jitype, token) => {
  const headers = {
    headers: {
      "x-access-token": token,
    },
  };
  return (dispatch) => {
    dispatch(loadShiftRequest());
    let loadUrl = "";
    if (["jira"].includes(tool)) {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}/type/${jitype}`;
    } else {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}`;
    }

    axios
      .get(loadUrl, headers)
      .then(({ status, data }) => {
        dispatch(loadShiftSuccess(data.shifts));
      })
      .catch((error) => {
        dispatch(loadShiftFailed(""));
      });
  };
};

const createShiftRecord = (tool, agroup, jitype, token, object) => {
  const headers = {
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
  };
  const json = JSON.stringify({ document: object });
  return (dispatch) => {
    dispatch(updateFormSubmitStatus(true));
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const createUrl =
      "https://predictions.oi.ingka.com/api/v1/acs/management/shift";
    let loadUrl = "";
    if (["jira"].includes(tool)) {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}/type/${jitype}`;
    } else {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}`;
    }
    dispatch(createShiftRequest());
    enqueueSnackbar({
      message: "creating a shift... please wait for few minute...",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    });
    axios
      .post(createUrl, json, headers)
      .then((response) => {
        // console.log(response);
        dispatch(updateFormSubmitStatus(false));
        const { status, data } = response;
        if (status === 201) {
          dispatch(createShiftSuccess());
          dispatch(
            updateShiftStateWithDefaultShiftSchema(defaultShiftStateObject)
          );
          enqueueSnackbar({
            message: "shift is created",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            },
          });
          /** load data again once action is success */
          dispatch(loadShiftRequest());
          axios
            .get(loadUrl, headers)
            .then(({ status, data }) => {
              dispatch(loadShiftSuccess(data.shifts));
            })
            .catch((error) => {
              dispatch(loadShiftFailed(""));
            });
          /** load data again once action is success */
        } else {
          dispatch(createShiftFailed());
          enqueueSnackbar({
            message: "shift creation is failed.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            },
          });
        }
      })
      .catch((error) => {
        dispatch(updateFormSubmitStatus(false));
        dispatch(createShiftFailed());
        enqueueSnackbar({
          message: "We are not able to create a shift.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
        });
      });
  };
};

const updateShiftRecord = (tool, agroup, jitype, token, id, object) => {
  // console.log('Object :: ', object);
  const headers = {
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
  };

  const json = JSON.stringify({ document: object });

  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const updateUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/${id}`;
    let loadUrl = "";
    if (["jira"].includes(tool)) {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}/type/${jitype}`;
    } else {
      loadUrl = `https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/${tool}/agroup/${agroup}`;
    }

    dispatch(updateShiftRequest());
    enqueueSnackbar({
      message: "updating your shift... please wait for few minute...",
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    });
    dispatch(updateFormSubmitStatus(true));
    axios
      .put(updateUrl, json, headers)
      .then((response) => {
        // console.log(response);
        dispatch(updateFormSubmitStatus(false));
        const { status, data } = response;
        if (status === 200) {
          dispatch(updateShiftSuccess());
          dispatch(
            updateShiftStateWithDefaultShiftSchema(defaultShiftStateObject)
          );
          enqueueSnackbar({
            message: "shift is updated.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            },
          });
          /** load data again once action is success */
          dispatch(loadShiftRequest());
          axios
            .get(loadUrl, headers)
            .then(({ status, data }) => {
              dispatch(loadShiftSuccess(data.shifts));
            })
            .catch((error) => {
              dispatch(loadShiftFailed(""));
            });
          /** load data again once action is success */
        } else {
          dispatch(updateShiftFailed());
          enqueueSnackbar({
            message: "The shift is not updated.",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            },
          });
        }
      })
      .catch((error) => {
        dispatch(updateFormSubmitStatus(false));
        dispatch(updateShiftFailed());
        enqueueSnackbar({
          message: "We are not able to update your shift.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
        });
      });
  };
};

const listJiraProject = (token) => {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    // const url = "https://jira.digital.ingka.com/rest/api/2/project";
    // axios
    //   .get(url, {
    //     // Axios looks for the `auth` option, and, if it is set, formats a
    //     // basic auth header for you automatically.
    //     auth: {
    //       username: "",
    //       password: "",
    //     },
    //   })
    //   .then(({ data }) => {
    //     const jiraProjects = data.map((project) => {
    //       return {
    //         name: project.name,
    //         id: project.id,
    //         key: project.key,
    //       };
    //     });
    //     dispatch(setJiraProjects(jiraProjects));
    //   })
    //   .catch((error) => {
    //     dispatch(setJiraProjects([]));
    //   });
    const headers = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const url = "https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/jira/agroup"
    axios
      .get(
        url, headers
      )
      .then((response) => {
        const { data } = response;
        dispatch(setJiraProjects(data.projects));
      })
      .catch((error) => {
        dispatch(setJiraProjects([]));
      });
  };
};


const listIdeskAssignmentGroup = (token) => {
  return (dispatch) => {
    const enqueueSnackbar = (...args) =>
      dispatch(enqueueSnackbarAction(...args));
    const headers = {
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    };
    const url = "https://predictions.oi.ingka.com/api/v1/acs/management/shift/tool/idesk/agroup"
    axios
      .get(
        url, headers
      )
      .then((response) => {
        const { data } = response;
        dispatch(setIdeskAssignmentGroups(data.projects));
      })
      .catch((error) => {
        dispatch(setIdeskAssignmentGroups([]));
      });
  };
};



export {
  deleteShiftRecord,
  listShiftRecord,
  createShiftRecord,
  updateShiftRecord,
  listJiraProject,
  listIdeskAssignmentGroup
};
