import React, {Component, createRef} from 'react';
import {connect } from 'react-redux';
// import { update_contact } from '../_reducer/authenticator.reducer';

import EditImg from '../icons/edit.png';
import SearchImg from '../icons/search.png';

/** import form from components */
import ContactForm from './ContactForm';

import { FormControl, Row, Col, Jumbotron, Container, Table, Button, InputGroup } from 'react-bootstrap';

import firebase from '../firebase';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.ContactFormref = createRef();
        this.state = {
            SupportGrpName: '',
            PrimaryNumber: '',
            SecondaryNumber: '',
            Escalation: '',
            LastModifiedBy: '',   
            searchKeyWord: '',
            Records: []
        };
        this.reload = this.reload.bind(this);
    }
    searchOnchange = (event) => {
        this.setState({
            searchKeyWord: event.target.value
        })

    }
    reload(event) {

        const db = firebase.firestore();
        db.collection("Contacts")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));




                this.setState({
                    Records: data
                });
            });
    }
    componentDidMount() {
        this.setState({
            LastModifiedBy: this.props.user.email
        })

        const db = firebase.firestore();
        db.collection("Contacts")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));



                this.setState({
                    SupportGrpName: this.state.SupportGrpName,
                    PrimaryNumber: this.state.PrimaryNumber,
                    SecondaryNumber: this.state.SecondaryNumber,
                    Escalation: this.state.Escalation,
                    // LastModifiedBy: this.state.LastModifiedBy,
                    Records: data
                });
            });
    }
    renderTableData() {
        return this.state.Records.filter(record => record.SupportGrpName.includes(this.state.searchKeyWord)).map((record) => {
            var id = record.id;
            // const { SupportGrpName, PrimaryNumber, SecondaryNumber, Escalation, LastModifiedBy } = record;
            const { SupportGrpName } = record;
            return (
                <>
                <tr key={id}>
                    <td>{SupportGrpName}</td>
                    {/* <td>{PrimaryNumber}</td>
                    <td>{SecondaryNumber}</td>
                    <td>{Escalation}</td> */}
                    {/* <td>{LastModifiedBy}</td> */}
                    <td>
                        <Button onClick={() => {
                            // this.ContactFormref.current.changeState({
                            //     docKey: id,
                            //     SupportGrpName: record.SupportGrpName,
                            //     PrimaryNumber: record.PrimaryNumber,
                            //     SecondaryNumber: record.SecondaryNumber,
                            //     Escalation: record.Escalation,
                            //     // LastModifiedBy: record.LastModifiedBy,
                            //     isUpdate: true
                            // });

                            this.props.updateCurrentContact(
                                {
                                    docKey: id,
                                SupportGrpName: record.SupportGrpName,
                                PrimaryNumber: record.PrimaryNumber,
                                SecondaryNumber: record.SecondaryNumber,
                                Escalation: record.Escalation,
                                // LastModifiedBy: record.LastModifiedBy,
                                isUpdate: true
                                
                                })

                        }} variant="outline-secondary" type="button">
                            <img alt="editicon" src={EditImg} height="20" width="20" />
                        </Button>
                        {/* <Button onClick={(event) => {
                            const db = firebase.firestore();
                            db.collection("Contacts").doc(id).delete().then(function () {
                                alert("Document successfully deleted!");
                            }).catch(function (error) {
                                alert("Error removing document: ", error);
                            });
                            this.reload(event);
                        }
                        } variant="outline-secondary" type="submit">
                            <img alt="deleteicon" src={DeleteImg} height="20" width="20" />
                        </Button> */}


                    </td>
                </tr>
                </>
            )
        })
    }
    render() {
        if ((this.props.user.email.includes('@ingka.com') || this.props.user.email.includes('@ingka.ikea.com')) && this.props.token_type === 'Bearer' && this.props.access_token.length > 100 && this.props.scope.includes('https://www.googleapis.com/auth/userinfo.email')) {
        return (
            <>
                <Jumbotron fluid>
                    <Container>
                        <h1>Automated Calling System Duty Phone Register</h1>
                    </Container>
                </Jumbotron>
                <Container>

                    <Row>

                        {/* https://datastudio.google.com/embed/reporting/0058e04a-2912-481e-b25c-cbf3d8cf4cc5/page/ZD3iB */}
                        <div style={{ height: '550px', width: '850px' }}>
                        <iframe className="datastudio-iframe" title="automatedcallingsystem-contact-view" style={{ height: '540px', width: '1100px', border: '0', backgroundColor: 'white' }} src="https://datastudio.google.com/embed/reporting/0058e04a-2912-481e-b25c-cbf3d8cf4cc5/page/ZD3iB" frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </Row>
                    <Row>
                        <Col>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="basic-addon1"><img alt="searchicon" src={SearchImg} height="20" width="20" /></InputGroup.Text>
                                </InputGroup.Prepend>

                                <FormControl
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    onChange={this.searchOnchange}
                                />
                            </InputGroup>

                        </Col>
                    </Row>


                    <Row>
                        

                        <Col xs={8} md={8}>


                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                <th>Support GroupName</th>
                                {/* <th>Primary Contact</th>
                                <th>Secondary Contact</th>
                                <th>Escalation Contact</th> */}
                                {/* <th>Last Modified By</th> */}
                                <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.renderTableData()}
                                </tbody>

                            </Table>


                        </Col>
                        {/* <Col ><ContactForm ref={this.ContactFormref} reload={this.reload} /></Col> */}
                        <Col ><ContactForm reload={this.reload} /></Col>
                    </Row>
                </Container>
            </>
        )
                            } else {
                                return (
                                    <>
                                    <Container>
                                        You dont have required access to ACS Self Service Page.
                                    </Container>

                                    </>
                                )
                            }
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.usr.user,
      access_token: state.au.token,
      scope: state.au.scope,
      token_type: state.au.token_type,
      selectedContact: state.acsssp.selectedContact
    };
  }


const mapDispatchToProps = (dispatch) => {
    // return {
    //     updateCurrentContact: (data) => {  }
    // }
}
  
  
export default connect(mapStateToProps, null)(Contact);