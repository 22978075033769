import {
    AUTHORIZATION_FAILED,
    AUTHORIZATION_SUCCESS,
    AUTHORIZATION_REQUEST
} from './types';

const authorizationRequest = () => {
    return {
        type: AUTHORIZATION_REQUEST
    }
}

const authorizationSuccess = (payload) => {
    return {
        type: AUTHORIZATION_SUCCESS,
        payload: payload
    }
}

const authorizationFailed = (errorMessage) => {
    return {
        type: AUTHORIZATION_FAILED,
        payload: errorMessage
    }
}

export { authorizationRequest, authorizationSuccess, authorizationFailed }