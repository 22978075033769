import React, { Component } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

/** material ui components */
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from "@material-ui/icons";
import Container from "@material-ui/core/Container";
import MuiPhoneNumber from "material-ui-phone-number";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

/** import actions */
import {
  updateContact,
  updateFeaturesEnabled,
  updateInterval,
  updateFormValidation,
} from "../../../../../../redux/shiftmanagement/actions";

/** import css */
import "./Settings.css";
import { Button, Grid } from "@material-ui/core";

export class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      onlyAudio: true
    }
  }

  componentDidMount(){
    this.setState((prevState) => {
      return {
        ...prevState,
        onlyAudio: this.props.isprimaryenabled
      }
    })
  }

  validate = () => {
    const formproperty = "isform3valid";
    const {
      primarycontactnumber,
      alternateprimarycontactnumber,
      isapnenabled,
      isrpnenabled,
      issecondaryenabled,
      isprimaryenabled,
      intervalp2s,
      intervalp2rp,
      intervalp2ap,
    } = this.props;

    const pc1 = _.size(_.trim(primarycontactnumber)) > 8;
    const pc2 = _.size(_.trim(alternateprimarycontactnumber)) > 8;
    // only primary
    const condition1 =
      !isapnenabled && !isrpnenabled && !issecondaryenabled && pc1;
    // only primary and retry
    const condition2 =
      !issecondaryenabled &&
      !isapnenabled &&
      isrpnenabled &&
      intervalp2rp > 1 &&
      pc1;
    // only primary and alternate
    const condition3 =
      isapnenabled &&
      intervalp2ap > 1 &&
      pc2 &&
      !isrpnenabled &&
      !issecondaryenabled &&
      pc1;
    // only primary, retry and alternate
    const condition4 =
      !issecondaryenabled &&
      isapnenabled &&
      intervalp2ap > intervalp2rp &&
      pc2 &&
      isrpnenabled &&
      intervalp2rp > 1 &&
      pc1;
    // all enabled primary, retry, alternate and secondary
    const condition5 =
      issecondaryenabled &&
      intervalp2s > intervalp2ap &&
      isapnenabled &&
      intervalp2ap > intervalp2rp &&
      pc2 &&
      isrpnenabled &&
      intervalp2rp > 1 &&
      pc1;
    // only primary and secondary
    const condition6 =
      issecondaryenabled &&
      intervalp2s > 1 &&
      !isapnenabled &&
      !isrpnenabled &&
      pc1;
    // only primary, alternate and secondary
    const condition7 =
      pc1 &&
      !isrpnenabled &&
      isapnenabled &&
      intervalp2ap > 1 &&
      pc2 &&
      issecondaryenabled &&
      intervalp2s > intervalp2ap;
    // only primary, retry and secondary
    const condition8 =
      pc1 &&
      isrpnenabled &&
      intervalp2rp > 1 &&
      !isapnenabled &&
      issecondaryenabled &&
      intervalp2s > intervalp2rp;
    const valid =
      condition1 ||
      condition2 ||
      condition3 ||
      condition4 ||
      condition5 ||
      condition6 ||
      condition7 ||
      condition8 ||
      isprimaryenabled;
    this.props.updateFormValidation({ name: formproperty, value: valid });

    if (!issecondaryenabled || isprimaryenabled) {
      this.props.updateFormValidation({ name: "isform4valid", value: true });
      this.props.updateFormValidation({ name: "isform5valid", value: true });
    }
  };

  componentDidMount() {
    this.validate();
  }

  onChangeCheckState = (event) => {
    const { name, checked } = event.target;
    if (name === "isprimaryenabled") {
      this.setState((prevState) => {
        return {
          ...prevState,
          onlyAudio: !prevState.onlyAudio
        }
      })
    }
    if (name === "issecondaryenabled") {
      if (!checked) {
        this.props.updateFormValidation({ name: "isform4valid", value: true });
        this.props.updateFormValidation({ name: "isform5valid", value: true });
      } else {
        this.props.updateFormValidation({ name: "isform4valid", value: false });
        this.props.updateFormValidation({ name: "isform5valid", value: false });
      }
    }
    this.props.updateFeaturesEnabled(name, checked);
  };

  onUpdateContact = (name, value) => {
    // value = value.match(/[0-9]/g);
    this.props.updateContact(name, value);
  };

  onUpdateInterval = (event) => {
    const { name, value } = event.target;
    this.props.updateInterval(name, parseInt(value));
  };

  render() {
    const variants = {
      show: {
        opacity: 1,
        transition: {
          delayChildren: 0.2,
        },
      },
      hide: { opacity: 0, display: "none" },
    };

    this.validate();
    return (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container className="container" maxWidth={false}>
            <div className="flex-container">
              <div className="item">
                <span style={{ color: "gray" }}>
                  Primary contact number <sup>*</sup>
                </span>
                <MuiPhoneNumber
                  required
                  style={{
                    width: "300px",
                  }}
                  disabled={this.props.isprimaryenabled}
                  name="primarycontactnumber"
                  value={this.props.primarycontactnumber}
                  onChange={(value) => {
                    this.onUpdateContact("primarycontactnumber", value);
                  }}
                  defaultCountry="se"
                  countryCodeEditable={true}
                />
              </div>

              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isrpnenabled}
                      name="isrpnenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      disabled={this.props.isprimaryenabled}
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable retry option"
                  labelPlacement="end"
                />
              </div>
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isapnenabled}
                      name="isapnenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      disabled={this.props.isprimaryenabled}
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable alternate number"
                  labelPlacement="end"
                />
              </div>
            </div>
            <AnimatePresence>
              {this.props.isrpnenabled && (
                <motion.div
                  className="flex-container"
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "tween",
                    duration: 1,
                    delay: 0.2,
                  }}
                  exit={{
                    x: 300,
                    opacity: 0,
                  }}
                >
                  <FormControl>
                    <InputLabel
                      required={this.props.isrpnenabled}
                      htmlFor="intervalp2rp-id"
                    >
                      Choose retry interval:
                    </InputLabel>
                    <Select
                      native
                      style={{
                        width: "300px",
                      }}
                      value={this.props.intervalp2rp}
                      onChange={this.onUpdateInterval}
                      inputProps={{
                        name: "intervalp2rp",
                        id: "intervalp2rp-id",
                      }}
                      disabled={this.props.isprimaryenabled}
                    >
                      <option aria-label="None" value="" />
                      {_.range(
                        2,
                        this.props.isapnenabled
                          ? this.props.intervalp2ap
                          : this.props.issecondaryenabled
                          ? this.props.intervalp2s
                          : 30
                      ).map((value) => {
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {this.props.isapnenabled && (
                <motion.div
                  className="flex-container"
                  initial={{ x: -300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    type: "tween",
                    duration: 1,
                    delay: 0.2,
                  }}
                  exit={{
                    x: 300,
                    opacity: 0,
                  }}
                >
                  <div className="item">
                    <span
                      required={this.props.isapnenabled}
                      style={{ color: "gray" }}
                    >
                      Alternate primary contact number
                    </span>
                    <MuiPhoneNumber
                      style={{
                        width: "300px",
                      }}
                      name="alternateprimarycontactnumber"
                      disabled={this.props.isprimaryenabled}
                      value={this.props.alternateprimarycontactnumber}
                      onChange={(value) => {
                        this.onUpdateContact(
                          "alternateprimarycontactnumber",
                          value
                        );
                      }}
                      defaultCountry="se"
                      countryCodeEditable={true}
                    />
                  </div>
                  <div className="item">
                    <FormControl>
                      <InputLabel
                        required={this.props.isapnenabled}
                        htmlFor="intervalp2ap-id"
                      >
                        Choose interval for alternate number:
                      </InputLabel>
                      <Select
                        native
                        style={{
                          width: "300px",
                        }}
                        value={this.props.intervalp2ap}
                        onChange={this.onUpdateInterval}
                        disabled={this.props.isprimaryenabled}
                        inputProps={{
                          name: "intervalp2ap",
                          id: "intervalp2ap-id",
                        }}
                      >
                        <option aria-label="None" value="" />
                        {_.range(
                          this.props.isrpnenabled
                            ? this.props.intervalp2rp + 1
                            : 0,
                          this.props.issecondaryenabled
                            ? this.props.intervalp2s
                            : 30
                        ).map((value) => {
                          return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="flex-container">
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.issecondaryenabled}
                      name="issecondaryenabled"
                      onChange={this.onChangeCheckState}
                      disabled={this.props.isprimaryenabled}
                      size="medium"
                      color="primary"
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable secondary contact"
                  labelPlacement="end"
                />
              </div>
              <motion.div
                className="item"
                variants={variants}
                animate={this.props.issecondaryenabled ? "show" : "hide"}
                initial={{ opacity: 0 }}
              >
                <FormControl>
                  <InputLabel
                    required={this.props.issecondaryenabled}
                    htmlFor="intervalp2s-id"
                  >
                    Choose interval for secondary number:
                  </InputLabel>
                  <Select
                    native
                    style={{
                      width: "300px",
                    }}
                    value={this.props.intervalp2s}
                    onChange={this.onUpdateInterval}
                    disabled={this.props.isprimaryenabled}
                    inputProps={{
                      name: "intervalp2s",
                      id: "intervalp2s-id",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {_.range(
                      this.props.isapnenabled
                        ? this.props.intervalp2ap + 1
                        : this.props.isrpnenabled
                        ? this.props.intervalp2rp + 1
                        : 0,
                      30
                    ).map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </motion.div>
            </div>
            <div className="flex-container">
              <div className="item">
                <FormControlLabel
                  style={{
                    width: "300px",
                    color: "gray",
                  }}
                  control={
                    <Checkbox
                      checked={this.props.isprimaryenabled}
                      name="isprimaryenabled"
                      onChange={this.onChangeCheckState}
                      size="medium"
                      color="primary"
                      inputProps={{ "aria-label": "checkbox with medium size" }}
                    />
                  }
                  label="Enable non-audio notification"
                  labelPlacement="end"
                />
              </div>
            </div>
          </Container>
        </motion.div>
        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ padding: "0px 10px" }}
            spacing={2}
          >
            <Button
              startIcon={<NavigateBeforeOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.back();
              }}
            >
              Previous
            </Button>
            <Button
              disabled={!this.props.isform3valid}
              endIcon={<NavigateNextOutlined />}
              variant="outlined"
              onClick={() => {
                this.props.next();
              }}
            >
              Continue
            </Button>
          </Grid>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    primarycontactnumber: state.shmgmt.primarycontactnumber,
    alternateprimarycontactnumber: state.shmgmt.alternateprimarycontactnumber,
    isapnenabled: state.shmgmt.isapnenabled,
    isrpnenabled: state.shmgmt.isrpnenabled,
    intervalp2rp: state.shmgmt.intervalp2rp,
    intervalp2ap: state.shmgmt.intervalp2ap,
    intervalp2s: state.shmgmt.intervalp2s,
    issecondaryenabled: state.shmgmt.issecondaryenabled,
    isprimaryenabled: state.shmgmt.isprimaryenabled,
    isform3valid: state.shmgmt.isform3valid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateContact: (name, value) => {
      dispatch(updateContact(name, value));
    },
    updateFeaturesEnabled: (name, value) => {
      dispatch(updateFeaturesEnabled(name, value));
    },
    updateInterval: (name, value) => {
      dispatch(updateInterval(name, value));
    },
    updateFormValidation: (payload) => {
      dispatch(updateFormValidation(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
