import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import axios from 'axios';
import _ from 'lodash';
import actions from "../../redux/developerconnectportal/ios/actions";
import { authorizationRequest, authorizationSuccess, authorizationFailed } from '../../redux/authorization/actions';
import DateTimeRangePicker from "../../components/developerconnect/ios/DateTimeRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import "./IOSConnectPortal.css";
import DeveloperConnectBody from "../../components/developerconnect/ios/DeveloperConnectBody";
import ClassificationTagSelector from "../../components/developerconnect/ios/ClassificationTagSelector";
import AppVersionCodeSelector from "../../components/developerconnect/ios/AppVersionCodeSelector";
import CountriesSelector from "../../components/developerconnect/ios/CountriesSelector";
import Reply from "../../components/developerconnect/ios/modal/Reply";

class AndroidConnectPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName: 'ios_developer_connect'
    }
  }

  componentDidMount() {
    this.props.getRoleRequest();
    // const url = `http://developerconnect-uipath-support-api.westeurope.cloudapp.azure.com/api/v1/roles`;
    const url = `https://developerconnectproxyservice.azurewebsites.net/api/v1/roles`;
    // const url = `http://localhost:8080/api/v1/roles`;
    axios.get(url, {
      headers: {
        'x-access-token': this.props.token
      }, 
      params: {
          email: this.props.usr.email || '',
          page: this.state.pageName
      },
  }).then(response => {
    // console.log(response)
      const { roles } = response.data;
      this.props.getRoleSuccess(roles);
    }).catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        // console.log(error.response)
      } else if (error.request) {
        // client never received a response, or request never left
        // console.log(error.request)
      } else {
        // anything else
      }
    })
  }

  render() {
    const uroles = _.map(_.filter(this.props.roles, o => o.page === this.state.pageName), 'role');
    return (
      <div>
        <header>
          <div className="__flex_header_container">
            <div className="__flex_item">
              <div style={{ paddingLeft: "10px" }}>
                <sup style={{ top: "0.5em", color: "lightgreen" }}>iOS</sup>
                <h5>Developer Connect Portal</h5>
              </div>
            </div>
            { (_.map(this.props.roles, 'role')).includes('admin') ? <div className="__flex_item" style={{ fontSize: 'small'}}>
              <button style={{
                  color: "gray",
                  background: "transparent",
                  borderColor: "transparent",
                }}
                onClick={ () => {
                this.props.history.push('/pages/nreviews/ios/connectportal/settings/rbac');
              }}><FontAwesomeIcon icon={faCog} style={{ color: "red" }}></FontAwesomeIcon>&nbsp;Settings</button>
            </div> : null }
          </div>
          <div className="__flex_container">
            <div className="__flex_item __datetime_range_picker">
              <DateTimeRangePicker />
            </div>
            <div className="__flex_item __selector __version_select __dropdown_index" style={{zIndex:2}}>
              <ClassificationTagSelector />
            </div>
            <div className="__flex_item __selector __tag_select __dropdown_index" style={{zIndex:2}}>
              <AppVersionCodeSelector />
            </div>
            <div className="__flex_item __selector __country_select __dropdown_index" style={{zIndex:2}}>
              <CountriesSelector />
            </div>
            <div className="__flex_item">
              <button
                style={{
                  color: "gray",
                  background: "transparent",
                  borderColor: "transparent",
                }}
                onClick={this.props.clearFilters}
              >
                <FontAwesomeIcon icon={faRedoAlt} style={{ color: "gray" }} />{" "}
                Clear Filter
              </button>
            </div>
            <div className="__flex_item">
            { (uroles.includes('admin') || uroles.includes('developer'))  ? <Reply all srr={this.props.srr} /> : null }
            </div>
          </div>
        </header>
        <DeveloperConnectBody key="androidDeveloperConnectBody"></DeveloperConnectBody>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewsPerPage: state.iosdcp.reviewsPerPage,
  reviews: state.iosdcp.reviews,
  activePageNumber: state.iosdcp.paginator.activePage,
  srr: state.iosdcp.selectedReviewsForReply,
  token: state.au.token,
  usr: state.usr.user,
  roles: state.at.roles
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters: () => {
      dispatch(
        actions.clearFilter({
          startDate: moment().subtract(1, "months").unix(),
          endDate: moment().unix(),
        })
      );
    },
    getRoleRequest: () => {
      dispatch(
        authorizationRequest
      )
    },
    getRoleSuccess: (roles) => {
      dispatch(
        authorizationSuccess(roles)
      )
    },
    getRoleFailed: (message) => {
      dispatch(
        authorizationFailed(message)
      )
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AndroidConnectPortal));
