import { USER_FETCH_FAILED, USER_FETCH_REQUEST, USER_FETCH_SUCCESS} from './types';

const userFetchRequest = () => {
    return {
        type: USER_FETCH_REQUEST
    }
}

const userFetchSuccess = (user) => {
    return {
        type: USER_FETCH_SUCCESS,
        payload: user
    }
}

const userFetchFailed = (error) => {
    return {
        type: USER_FETCH_FAILED,
        payload: error
    }
}

export { userFetchRequest, userFetchSuccess, userFetchFailed };