import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";

import {
  deleteMemberById,
  deleteOperatorById,
  getOperators,
  getUsersAndRoles,
} from "../../../../../../services/shiftmanagementrbac";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { setEditMode } from "../../../../../../redux/shiftmanagementrbac/actions";

const columns = [
  { id: "email", label: `Member's email address` },
  { id: "role", label: `Member's role` },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export function OperatorTable(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // useEffect(() => {
  //   props.getUsersAndRoles(props.platform, props.agroup, props.user.email);
  // }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {_.filter(props.operators, o => o.email !== props.user.email).length === 0 ? ( */}
            {props.operators.length === 0 ? (
              <TableRow hover tabIndex={-1}>
                <TableCell align="center" colSpan={3}>
                  {" "}
                  Operator does not exist.
                </TableCell>
              </TableRow>
            ) : (
              // _.filter(props.operators, o => o.email !== props.user.email)
              props.operators
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key={`${row.id}-member`} align="left">
                        {row.email}{" "}
                        {props.user.email === row.email ? `(myself)` : null}
                      </TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell key={`${row.id}-action`} align="right">
                        <Tooltip
                          title={
                            row.email === props.user.email
                              ? "You can`t edit your permission."
                              : "edit"
                          }
                        >
                          <span>
                            <IconButton
                              disabled={row.email === props.user.email}
                              aria-label="edit"
                              style={
                                row.email === props.user.email
                                  ? { color: "lightgray" }
                                  : { color: "black" }
                              }
                              onClick={() => {
                                props.setEditMode(row.id, row.email, row.role);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={
                            row.email === props.user.email
                              ? "You can`t delete your permission."
                              : "delete"
                          }
                        >
                          <span>
                            <IconButton
                              disabled={row.email === props.user.email}
                              aria-label="delete"
                              style={
                                row.email === props.user.email
                                  ? { color: "lightgray" }
                                  : { color: "red" }
                              }
                              onClick={() => {
                                props.deleteMemberById(
                                  row.id,
                                  props.platform,
                                  props.agroup,
                                  props.user.email,
                                  props.token
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  agroup: state.shmgmt.assignmentGroup,
  platform: state.shmgmt.selectedPlatformItem,
  myroles: state.shrbac.roles,
  operators: state.shrbac.operators,
  user: state.usr.user,
  token: state.au.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getOperators: (platform, group, requester) => {
      dispatch(getOperators(platform, group, requester));
    },
    deleteOperatorById: (id, platform, group, requester) => {
      dispatch(deleteOperatorById(id, platform, group, requester));
    },
    deleteMemberById: (id, platform, group, requester, token) => {
      dispatch(deleteMemberById(id, platform, group, requester, token));
    },
    getUsersAndRoles: (platform, group, requester, token) => {
      dispatch(getUsersAndRoles(platform, group, requester, token));
    },
    setEditMode: (id, email, role) => {
      dispatch(setEditMode(id, email, role));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorTable);
