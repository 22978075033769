
import React, { useRef, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import actions from '../../../redux/developerconnectportal/ios/actions';
import { UNSET_CLEAR_APPVERSION_FLAG } from '../../../redux/developerconnectportal/ios/types';
import Select from "react-select";
import _ from 'lodash';

const AppVersionCodeSelector = (props) => {
    const observed = useRef(null);
    const flag = useSelector(state => state.iosdcp.clearAppVersions);
    const dispatcher = useDispatch();
    const appVersionCodes = _.map(props.reviews, review => review.AppVersion);
    const versionCodes = _.sortBy(_.remove(_.uniq([].concat.apply([],appVersionCodes)), null)).reverse();
    const options = versionCodes.map((versionCode) => { 
      return  { value: versionCode, label: _.replace(versionCode, 'Version ','') }
    })

    useEffect(() => {
        if(flag) {
            observed.current.select.clearValue()
            dispatcher({type: UNSET_CLEAR_APPVERSION_FLAG })
        }
      }, [observed, flag]);

    return (
      <div>
        <Select
          ref={observed}
          isMulti
          placeholder="App Version Name"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.setSelectedAppVersionCode}
        />
      </div>
    );
}

const mapStateToProps = (state) => ({
  reviewsPerPage: state.iosdcp.reviewsPerPage,
  reviews: state.iosdcp.reviews,
  activePageNumber: state.iosdcp.paginator.activePage,
  clear: state.iosdcp.clearAppVersions
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedAppVersionCode: (selectedAppVersionCode) => {
      
      dispatch(actions.setSelectedAppVersionCode(selectedAppVersionCode===null ? [] : selectedAppVersionCode))
    },
    unsetClear: () => {
        dispatch(actions.unsetClearAppVersionFlag)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersionCodeSelector);
