import React from 'react'

function DatabaseOutageReport() {
    return (
        <div>
            <iframe className="datastudio-iframe-databaseoutagereport" title="databaseoutagereport" style={{ height: '98vh', width: '98vw', border: '0' }} src="https://datastudio.google.com/embed/reporting/21390677-576b-48c7-89dc-8277404442db/page/azjBB" frameBorder={0} allowFullScreen></iframe>
        </div>
    )
}

export default DatabaseOutageReport;
