import React, { useRef, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import actions from '../../redux/developerconnectportal/android/actions';
import { UNSET_CLEAR_TAG_FLAG } from '../../redux/developerconnectportal/android/types';
import Select from "react-select";
import _ from 'lodash';

const ClassificationTagSelector = (props) => {
  const observed = useRef(null);
  const flag = useSelector(state => state.android.clearTags);
  const dispatcher = useDispatch();
    const classificationTags = _.map(props.reviews, review => review.classificationTag);
    const tags = _.uniq([].concat.apply([],classificationTags));
    const options = tags.map((tag) => { 
      return  { value: tag, label: tag }
    })

    useEffect(() => {
      if(flag) {
          observed.current.select.clearValue()
          dispatcher({type: UNSET_CLEAR_TAG_FLAG })
      }
    }, [observed, flag]);
    return (
      <div>
        <Select
        ref={observed}
          isMulti
          placeholder="Classification Tag"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.setSelectedClassificationTag}
        />
      </div>
    );
}

const mapStateToProps = (state) => ({
  reviewsPerPage: state.android.reviewsPerPage,
  reviews: state.android.reviews,
  activePageNumber: state.android.paginator.activePage,
  clear: state.android.clearAppVersions
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedClassificationTag: (selectedTags) => {
      
      dispatch(actions.setSelectedClassificationTag(selectedTags===null ? [] : selectedTags))
    },
    unsetClear: () => {
      dispatch(actions.unsetClearClassificationTagFlag)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationTagSelector);
