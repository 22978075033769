import React from "react";
import { connect } from "react-redux";
import PseChart from "./charts/PseChart";
import PseCallStatusChart from "./charts/PseCallStatusChart";
import CriticalincidentChart from "./charts/CriticalincidentChart";
import RangePicker from "./components/RangePicker";
import {
  fetch_psecallscount,
  fetch_psecallstatus,
  fetch_criticalincident,
  fetch_meantimeassign,
  fetch_meantimeresolve,
} from "./api";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

// import MyResponsiveSunburst from './charts/PseCallStatusPie'
import Cards from "./components/Cards";

/*to-do
Use axios.all to call all endpoints at once
setup default props and loader on top of it, rethink state structure
rewrite cards component and remove it from critical incident chart
review and merge critical incident and psechart into one bar chart component
move styles to css file
remove console.log and unused imports
 */

class Psecallscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      range: "day=7",
      data: {},
      data2: {},
      data3: {},
      data4: {},
      data5: {},
    };
  }

  async componentDidMount() {
    const fetchedData = await fetch_psecallscount("week=1", this.props.group);
    const fetchedData2 = await fetch_psecallstatus("week=1", this.props.group);
    const fetchedData3 = await fetch_criticalincident(
      "week=1",
      this.props.group
    );
    const fetchedData4 = await fetch_meantimeassign("day=7", this.props.group);
    const fetchedData5 = await fetch_meantimeresolve("day=7", this.props.group);

    // console.log("fetchedData", fetchedData);
    // console.log("fetchData3", fetchedData3);

    this.setState({
      range: "day=7",
      data: fetchedData,
      data2: fetchedData2,
      data3: fetchedData3,
      data4: fetchedData4,
      data5: fetchedData5,
    });
  }

  handlePeriodChange = async (period) => {
    try {
      // console.log(period);

      this.setState({
        range: period,
        data: {},
        data2: {},
        data3: {},
        data4: {},
        data5: {},
      });

      const fetchedData = await fetch_psecallscount(period, this.props.group);
      const fetchedData2 = await fetch_psecallstatus(period, this.props.group);
      const fetchedData3 = await fetch_criticalincident(
        period,
        this.props.group
      );
      const fetchedData4 = await fetch_meantimeassign(period, this.props.group);
      const fetchedData5 = await fetch_meantimeresolve(
        period,
        this.props.group
      );

      this.setState({
        range: period,
        data: fetchedData,
        data2: fetchedData2,
        data3: fetchedData3,
        data4: fetchedData4,
        data5: fetchedData5,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    // console.log("Render => ", this.state);

    if (
      Object.keys(this.state.data).length === 0 ||
      Object.keys(this.state.data2).length === 0 ||
      Object.keys(this.state.data3).length === 0 ||
      Object.keys(this.state.data4).length === 0 ||
      Object.keys(this.state.data5).length === 0
    ) {
      return (
        <div
          style={{
            minHeight: "90vh",
            padding: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress size={200} />
        </div>
      );
    }

    const { data, data2, data3, data4, data5 } = this.state;
    // console.log(data4,data5)
    return (
      <div>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-around"
          alignItems="baseline"
          style={{ margin: "0", width: "98%" }}
        >
          <Grid
            item
            xs
            style={{
              margin: "10px",
            }}
          >
            {this.props.group}
          </Grid>
          <Grid
            item
            xs
            style={{
              margin: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <RangePicker
              handlePeriodChange={this.handlePeriodChange}
              range={this.state.range}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="baseline"
          style={{ margin: "0", width: "98%" }}
        >
          <Cards data={data} />
        </Grid>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ margin: "0", width: "98%" }}
        >
          <Grid
            item
            xs
            style={{
              margin: "10px",
            }}
          >
            <PseChart data={data} />
          </Grid>
          <Grid
            item
            xs
            style={{
              margin: "10px",
            }}
          >
            <PseCallStatusChart data={data2} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ margin: "0", width: "98%" }}
        >
          <CriticalincidentChart
            data={data3}
            meantimeassign={data4}
            meantimeresolve={data5}
          />
        </Grid>
      </div>

      /** <div
        style={{
          minHeight: "90vh",
          padding: "30px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "80%" }}>
          <Grid container spacing={3} justify='center' style={{ margin: "0" }}>
            <Grid
              item
              xs
              style={{
                margin: "10px",
              }}
            >
              {this.props.group}
            </Grid>
            <Grid
              item
              xs
              style={{
                margin: "10px",
                float: "right"
              }}
            >
              <RangePicker handlePeriodChange={this.handlePeriodChange} range={this.state.range}/>
            </Grid>
          </Grid>
        </div>
        <div style={{ width: "80%", marginTop: "50px" }}>
          <Cards data={data} />
        </div>
        <div style={{ width: "80%", marginTop: "50px" }}>
          <Grid container spacing={3} justify='center' style={{ margin: "0" }}>
            <Grid
              item
              xs
              style={{
                margin: "10px",
              }}
            >
              <PseChart data={data} />
            </Grid>
            <Grid
              item
              xs
              style={{
                margin: "10px",
              }}
            >
              <PseCallStatusChart data={data2} />
            </Grid>
          </Grid>
        </div>
        <div>
          <CriticalincidentChart data={data3} meantimeassign={data4} meantimeresolve={data5} /> 
        </div>
      </div>
    */
    );
  }
}

const mapStatsToProps = (state) => {
  return {
    platform: state.shmgmt.selectedPlatformItem,
  };
};

export default connect(mapStatsToProps, null)(Psecallscount);
