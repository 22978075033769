import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function Loader(props) {
  return (
    <div>
      <Spinner {...props} role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}
